@color-user-active:				darken(@color-user, 10%);
@color-user-border-active:		darken(@color-user, 12%);

.nav-pills .nav-link.active,
.nav-pills .nav-link.active:focus,
.nav-pills .nav-link.active:hover,
.nav-pills .nav-item.open .nav-link,
.nav-pills .nav-item.open .nav-link:focus,
.nav-pills .nav-item.open .nav-link:hover {
	background-color: @dark;
}
a {
	color: @color-user;
	&:hover,
	&:active {
		color: @color-user-active;
	}
}
.btn-primary,
.btn-primary:hover,
.nav a,
.nav-pills .nav-link.active,
.nav-pills .nav-link.active:focus,
.nav-pills .nav-link.active:hover,
.nav-pills .nav-item.open .nav-link,
.nav-pills .nav-item.open .nav-link:focus,
.nav-pills .nav-item.open .nav-link:hover {
	color: @text-color !important;
}
.page-item.active .page-link,
.page-item.active .page-link:focus,
.page-item.active .page-link:hover {
	color: #fff !important;
	background-color: @gray-darker !important;
	border-color: @gray-darker !important;
}
.card {
	background-color: lighten(@gray-base, 4%);
	border-color: @gray-darker;
}
.text-primary {
	color: @color-user !important;
}
.btn {
	outline: 0 !important;
}
.btn-primary {
	background-color: @dark;
	border-color: @color-user;
	&:hover,
	&:focus,
	&:active,
	&:active:hover,
	&:active:focus,
  &:disabled:hover,
  &:disabled:focus {
		background-color: @color-user-active;
		border-color: @color-user-border-active;
	}
}
.btn-primary-outline {
	border-color: @color-user;
	color: @color-user;
	&:hover,
	&:focus,
	&:active,
	&:active:focus {
		background-color: @dark;
		border-color: @color-user;
		color: @text-color;
	}
}
.label-primary {
	background-color: @color-user;
}
.nav-pills .nav-link.active, .nav-pills .nav-link.active:focus, .nav-pills .nav-link.active:hover, .nav-pills .nav-item.open .nav-link, .nav-pills .nav-item.open .nav-link:focus, .nav-pills .nav-item.open .nav-link:hover {
	background-color: @dark;
	&:hover {
		background-color: @color-user-active;
	}
}
.page-item.active .page-link,
.page-item.active .page-link:focus,
.page-item.active .page-link:hover {
	background-color: @color-user;
	border-color: @color-user;
}
.page-link {
	color: @text-color !important;
	background-color: @body-bg;
	border-color: @body-bg;
	&:hover,
	&:focus {
		background-color: @gray-dark;
		border-color: @gray-dark;
	}
}
.navbar {
	box-shadow: none;
	background-color: rgba(22, 22, 22, 0.75);
	padding: 0.5rem 1rem;
	border-bottom-color: rgba(255, 255, 255, 0.15);
	li {
		padding: 0;
	}
}
.nav {
	padding: 0;
	background: transparent;
}
.navbar-light {
	.navbar-brand,
	.navbar-nav .nav-link,
	.navbar-nav .nav-link.active {
		color: @text-color;
	}
}
.navbar-nav .nav-item + .nav-item {
	margin-left: 0;
}
hr {
	border-color: rgba(255, 255, 255, 0.3);
}
.tab-pane {
	padding: 0;
}
.form-group {
	margin-bottom: 1rem !important;
}
@media (min-width: 544px) {
	.form-inline .form-group {
		margin-bottom: 0 !important;
	}
}
.input-group-addon {
	background-color: @gray-darker;
	color: @text-color;
}
.input-group-btn button {
	margin: 0;
}
.form-control,
.input-group-addon {
	border-color: @gray;
}
button {
	margin: 0;
}
.modal {
	background-color: rgba(0, 0, 0, 0.5);
}
.modal-body {
	margin: 0;
	overflow: visible;
	max-height: none;
}
