@sidemenu-width:    300px;

.ef-content {
  .container {
    width: 100%;
    position: relative;
  }
  .ef-loader-bg {
    position: fixed;
    left: @sidemenu-width;
  }
}

.ef-brackets-dashboard-content {
  //padding: 30px 15px;
  & > .container.content {
    margin-left: 0;
  }
  .row {
    //margin-left: -15px;
    //margin-right: -15px;
  }
}

@color-user-active:        darken(@color-user, 10%);
@color-user-border-active: darken(@color-user, 12%);

.ef-brackets-dashboard-content {
  /* Adjusting brackets style */
  .ef-brackets-container .match-controls-container .number {
    background-color: lighten(@gray-base, 4%);
  }
  #ef-match-dialog {
    h4 {
      margin-bottom: 10px;
    }
    .btn:not(.btn-primary) {
      background-color: @gray;
    }
  }
}

.modal-backdrop {
  z-index: 9;
}
