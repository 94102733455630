/*
 * LESS for responsiveness in various parts of the site
 */

@top-submenu-checkpoint: 870px;
@top-submenu-toggler-size: 40px;
.ef-top-title {
  margin-right: @top-submenu-toggler-size + 16px;
  position: relative;
  //z-index: -1;
}
.ef-top-submenu {
  padding-top: 0;
  padding-right: 0;
  opacity: 0.99;
  z-index: 99;
  @media screen and (max-width: @top-submenu-checkpoint) {
    position: absolute;
    top: 80px;
    margin-right: 25px;
    visibility: hidden;
    opacity: 0;
    z-index: 9999;
    -webkit-transition: visibility 0.3s, opacity 0.3s;
    -moz-transition: visibility 0.3s, opacity 0.3s;
    transition: visibility 0.3s, opacity 0.3s;
    &.open {
      visibility: visible;
      opacity: 1;
    }
    .nav {
      border: 1px solid rgba(255, 255, 255, 0.25);
    }
    li.nav-item {
      display: block;
      float: none;
      margin-left: 0 !important;
      .nav-link {
        min-width: 200px;
        border-radius: 0 !important;
        &:not(:hover):not(.active) {
          background: transparent !important;
        }
      }
      .dropdown-menu {
        border: 1px solid rgba(255, 255, 255, 0.25);
      }
    }
  }
}

.ef-top-submenu-toggle {
  display: none;
  width: @top-submenu-toggler-size;
  height: @top-submenu-toggler-size;
  margin: 25px 15px 25px 0;
  position: relative;
  @media screen and (max-width: @top-submenu-checkpoint) {
    display: block !important;
  }
  span {
    display: block;
    position: absolute;
    width: @top-submenu-toggler-size;
    height: 4px;
    background: @color-user;
    left: 0;
    border-radius: 3px;
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    transform-origin: center center;
    -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
    -moz-transition: -moz-transform 0.3s, opacity 0.3s;
    transition: transform 0.3s, opacity 0.3s;
    &:first-child {
      top: 5px;
    }
    &:last-child {
      bottom: 5px;
    }
    &:not(:first-child):not(:last-child) {
      top: 50%;
      margin-top: -2px;
    }
  }
  &[aria-expanded="true"] {
    span {
      &:first-child {
        transform: rotate(45deg) translate3d(9px, 9px, 0);
      }
      &:not(:first-child):not(:last-child) {
        opacity: 0;
      }
      &:last-child {
        transform: rotate(-45deg) translate3d(9px, -10px, 0);
      }
    }
  }
}

// Challenger events, Tournaments search bar
.ef-search-bar {
  .row > * {
    margin-bottom: 10px;
  }
}

// Tournament create, settings URL input group
.tournament-url-input-group {
  @media screen and (max-width: 700px) {
    margin-bottom: 45px;
    input[type="text"] {
      border-top-right-radius: 4px;
      border-top-bottom-radius: 4px;
    }
    .input-group-btn {
      display: block;
      position: absolute;
      left: 0;
      top: 110%;
      width: 100%;
      text-align: center;
      .btn {
        border-radius: 4px;
        width: 100%;
      }
    }
  }
}
