#participants-content {
  .players-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: relative;
    font: inherit;
    li {
      background: inherit;
      color: inherit;
      font: inherit;
      border: 0;
      margin: 0;
      height: 50px;
      &.dragged {
        position: absolute;
        z-index: 10;
      }
      .card-block {
        padding: 7px 15px;
      }
    }
    .panel {
      margin-bottom: 10px;
      .panel-body {
        padding: 8px 15px;
      }
    }
    .playername {
      display: block;
      margin-right: 100px;
    }
    .player-edit-ctrls {
      float: right;
      max-width: 90px;
    }
  }
}

#discussion-content {
  @avatar-size: 75px;
  #prototype {
    display: none;
  }
  .ef-comment {
    min-height: @avatar-size;
    position: relative;
    .ef-comment-avatar {
      position: absolute;
      width: @avatar-size;
      height: @avatar-size;
      left: 0;
      top: 0;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
    .ef-comment-body {
      margin-left: @avatar-size + 25px;
    }
    .ef-comment-titlebar {
      width: 100%;
    }
  }
}

#settings-content {
  .ef-settings-adv-options {
    margin-bottom: 10px;
  }
  [transition] {
    display: none;
  }
}
