@import 'https://fonts.googleapis.com/css?family=Righteous';
.site_bg {
  background: url('/img/bgs/blue.jpg') top center no-repeat fixed;
}
/* Variables
---------------------------------*/
/* Redefined
---------------------------------*/
html,
body {
  overflow-x: hidden;
}
::selection {
  background: #11a5e4;
}
::-moz-selection {
  background: #11a5e4;
}
a:link,
a:visited {
  color: #11a5e4;
}
a:active,
a:hover {
  color: rgba(17, 165, 228, 0.7);
}
.form-group {
  margin-bottom: 25px;
}
.form-group h5 {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  padding: 10px;
  font-family: 'Lato', Calibri, Arial, sans-serif;
  font-size: 16px;
  font-weight: bold;
}
.form-section {
  padding: 0 20px 20px 20px;
}
.col-xs-15 {
  width: 20%;
}
input[type=range] {
  -webkit-appearance: none;
  margin: 18px 0;
  width: 100%;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 1px 1px 1px #000, 0px 0px 1px #0d0d0d;
  background: #11a5e4;
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 1px 1px 1px #000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: #fff;
  -webkit-appearance: none;
  margin-top: -17px;
  position: relative;
  cursor: pointer;
  width: 35px;
  height: 35px;
  line-height: 140px;
  background: #25292C;
  text-align: center;
  border-radius: 999px;
  color: #9b9b9b;
  text-shadow: -1px -1px 0px rgba(255, 255, 255, 0.8), 1px 1px 2px #000000;
  font-size: 16px;
  font-family: sans-serif;
  box-shadow: inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset 3px 15px 45px rgba(255, 255, 255, 0.1), inset -1px -1px 2px rgba(0, 0, 0, 0.5), inset -3px -15px 45px rgba(0, 0, 0, 0.2), 1px 5px 30px -4px #000000;
  -webkit-transition: 0.1s ease-out;
  -moz-transition: 0.1s ease-out;
  -o-transition: 0.1s ease-out;
  transition: 0.1s ease-out;
  content: "&times;";
}
input[type=range]::-webkit-slider-thumb:before {
  content: "";
  background: #1A1A1A;
  width: 170px;
  height: 170px;
  position: absolute;
  top: -15px;
  left: -15px;
  z-index: -1;
  border-radius: 999px;
  box-shadow: inset -1px -1px 2px rgba(255, 255, 255, 0.3), inset -5px -15px 40px rgba(255, 255, 255, 0.1), inset 1px 1px 2px rgba(0, 0, 0, 0.5), inset 5px 15px 40px rgba(0, 0, 0, 0.2), -2px -40px 50px -20px rgba(255, 255, 255, 0.1), 2px 35px 50px -10px rgba(0, 0, 0, 0.4), 0px 0px 25px 8px #3c3c3c;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #11a5e4;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 1px 1px 1px #000, 0px 0px 1px #0d0d0d;
  background: #11a5e4;
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}
input[type=range]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: #fff;
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: #11a5e4;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000, 0px 0px 1px #0d0d0d;
}
input[type=range]::-ms-fill-upper {
  background: #11a5e4;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000, 0px 0px 1px #0d0d0d;
}
input[type=range]::-ms-thumb {
  box-shadow: 1px 1px 1px #000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: #fff;
  cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
  background: #11a5e4;
}
input[type=range]:focus::-ms-fill-upper {
  background: #11a5e4;
}
.typed-cursor {
  opacity: 1;
  -webkit-animation: blink 0.7s infinite;
  -moz-animation: blink 0.7s infinite;
  animation: blink 0.7s infinite;
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.magictime {
  -webkit-animation-duration: .5s;
  -moz-animation-duration: .5s;
  -o-animation-duration: .5s;
  animation-duration: .5s;
}
.nanobar {
  width: 100%;
  height: 4px;
  z-index: 9999;
  top: 0px;
}
.bar {
  width: 0;
  height: 100%;
  transition: height .3s;
  background: #11a5e4;
}
/* Break Points
------------- */
/* Fonts
---------------------------------*/
@font-face {
  font-family: 'Intro';
  src: url('/fonts/Intro.ttf.woff') format('woff'), url('/fonts/Intro.ttf.svg#Intro') format('svg'), url('/fonts/Intro.ttf.eot'), url('/fonts/Intro.ttf.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}
.deco-font {
  font-family: 'Intro', sans-serif;
  font-weight: 800;
}
.sup-font {
  font-family: 'Press Start 2P', cursive;
}
h1,
h2,
h3,
h4,
h5 {
  font-family: 'Intro', sans-serif;
  font-weight: 800;
}
.base-font {
  font-family: 'Lato', Calibri, Arial, sans-serif;
}
.enormo {
  font-family: 'Intro', sans-serif;
  font-weight: 800;
  font-size: 10em;
}
/* Scrollbars
---------------------------------*/
::-webkit-scrollbar {
  height: 1px;
  width: 4px;
}
::-webkit-scrollbar-button {
  height: 0px;
  width: 0px;
}
::-webkit-scrollbar-thumb {
  background: #38b9f0;
  border: 0px none #11a5e4;
}
::-webkit-scrollbar-thumb:hover {
  background: #38b9f0;
}
::-webkit-scrollbar-thumb:active {
  background: #000;
}
::-webkit-scrollbar-track {
  background: #1a1a1a;
  border: 0px none #38b9f0;
}
::-webkit-scrollbar-track:hover {
  background: rgba(56, 185, 240, 0.05);
}
::-webkit-scrollbar-track:active {
  background: #38b9f0;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
/* Text
---------------------------------*/
body {
  font-family: 'Lato', Calibri, Arial, sans-serif;
  font-weight: 300;
  background: #000;
  color: #fff;
}
/* Animation
---------------------------------*/
.transition-cubic {
  transition: all 400ms cubic-bezier(0.765, 0.225, 0, 0.845);
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes animatedBackground {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 100% 0;
  }
}
@-webkit-keyframes glow {
  from {
    box-shadow: 0 0 0px rgba(255, 255, 255, 0.4);
  }
  to {
    box-shadow: 0 0 30px #11a5e4;
  }
}
.glow-box {
  animation: glow 1.0s infinite alternate;
}
/* Styles
---------------------------------*/
.inner-shadow {
  box-shadow: inset 0px 5px 15px -6px rgba(0, 0, 0, 0.75);
}
.hexagon {
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}
.visible {
  opacity: 1;
}
/* Vue Transitions
---------------------------------*/
.friendRequests-transition {
  transition: all 400ms cubic-bezier(0.765, 0.225, 0, 0.845);
  opacity: 1;
  height: 100px;
  overflow: hidden;
}
.friendRequests-enter,
.friendRequests-leave {
  opacity: 0;
  height: 0;
}
.friends-transition {
  transition: all 400ms cubic-bezier(0.765, 0.225, 0, 0.845);
  opacity: 1;
  min-height: 200px;
  overflow: hidden;
}
.friends-enter,
.friends-leave {
  opacity: 0;
  height: 0;
}
.gen-transition {
  transition: all 400ms cubic-bezier(0.765, 0.225, 0, 0.845);
  opacity: 1;
}
.gen-enter,
.gen-leave {
  opacity: 0;
}
.preloader,
.notice-entered {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.7);
  padding-top: 20vh;
  text-align: center;
  opacity: 0;
  transition: all .3s ease;
  pointer-events: none;
}
.preloader img,
.notice-entered img {
  width: 384px;
  height: 216px;
  margin: 0 auto;
}
/* Steam Id
---------------------------------*/
.steam-id {
  text-align: center;
}
/* Joyride Redefined
---------------------------------*/
.joyride-tip-guide .joyride-next-tip {
  background: #11a5e4;
  border: none;
}
.joyride-tip-guide .joyride-next-tip:hover {
  border: none;
}
/* Acticity Alerts
---------------------------------*/
.activity-alert {
  position: fixed;
  top: -500px;
  left: 70px;
  border: 2px solid #11a5e4;
  padding: 10px;
  background: #1e1e1e;
  z-index: 99999;
  -webkit-box-shadow: 0px 0px 29px -1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 29px -1px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 29px -1px rgba(0, 0, 0, 0.75);
}
.activity-alert h3 {
  font-size: 16px;
  font-family: 'Lato', Calibri, Arial, sans-serif;
  padding: 0;
  margin: 0;
}
.activity-alert p {
  padding: 0;
  margin: 0;
}
.activity-alert:before {
  content: ' ';
  position: absolute;
  top: 20px;
  left: -20px;
  width: 20px;
  height: 20px;
  background: #11a5e4;
  -webkit-clip-path: polygon(100% 0, 0 50%, 100% 100%);
  clip-path: polygon(100% 0, 0 50%, 100% 100%);
}
/* Leaderboards
---------------------------------*/
.leader {
  color: #fff;
  margin: 10px 0;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.2);
}
.leader h4 {
  font-size: 24px;
  font-weight: 900;
  margin: 0 0 10px 0;
  padding: 0;
}
.leader h4 span {
  font-size: 11px;
}
.leader .leader_rank {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 50px;
}
.leader:hover {
  background: #000;
}
.ldr-profile-image {
  border-radius: 4px;
  overflow: hidden;
  width: 75px;
  height: 75px;
  border: 2px solid #1e1e1e;
  margin: 10px;
  display: inline-block;
  background: #000;
}
.ldr-profile-image img {
  height: 75px;
}
/* Leaderboard Top
---------------------------------*/
.leaderboard-top {
  clear: both;
  background: #1e1e1e;
  border-radius: 0 0 4px 4px;
  border: 1px solid rgba(30, 30, 30, 0.4);
  position: relative;
}
.leaderboard-top h3 {
  font-family: 'Lato', Calibri, Arial, sans-serif;
  font-weight: normal;
  font-size: 14px;
  opacity: .5;
  padding: 20px 25px 0 25px;
}
.leaderboard-top h4 {
  background: #11a5e4;
  padding: 5px 25px;
}
.leaderboard-top h4 span {
  font-size: 12px;
  font-family: 'Lato', Calibri, Arial, sans-serif;
}
.leaderboard-top h5 {
  text-align: center;
  margin-bottom: 20px;
  font-family: 'Lato', Calibri, Arial, sans-serif;
}
.leaderboard-top p {
  padding: 5px 25px;
}
.leaderboard-top .leaderboard-top-rank {
  position: absolute;
  top: 38px;
  right: 11px;
  width: 40px;
}
.leaderboard-top .leaderboard-top-img {
  width: 100px;
  height: 100px;
  margin: 25px;
  float: right;
}
.leaderboard-top .leaderboard-top-img img {
  width: 100%;
}
/* Gold Packages
---------------------------------*/
.jp-card .jp-card-front,
.jp-card .jp-card-back {
  background: #000;
}
.gold-package {
  background: #000;
  border: 1px solid #11a5e4;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 25px;
  position: relative;
}
.gold-package .price {
  border-radius: 0 0 0 4px;
  background: #11a5e4;
  padding: 5px;
  position: absolute;
  top: 0;
  right: 0;
}
.gold-package:hover {
  background: #11a5e4;
  cursor: pointer;
}
.gold-package.gold-selected {
  background: #11a5e4;
}
.total {
  border-bottom: 1px solid #11a5e4;
  padding: 20px;
}
.modal-dialog {
  margin: 100px auto;
}
/* Promos
---------------------------------*/
.promo {
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  width: 100%;
  min-height: 170px;
  height: 100%;
}
.promo .promo-img-main {
  width: 100%;
  height: 100%;
  z-index: -1;
}
@media screen and (max-width: 768px) {
  .promo .promo-img-main {
    width: 150%;
    margin-left: -20%;
  }
}
.promo .promo-img-main img {
  width: 100%;
}
.promo .btn-go {
  background: url('/img/go.png') top left;
  background-size: 100%;
  height: 100px;
  width: 100px;
  padding: 51px 14px 11px 59px;
  font-family: 'Intro', sans-serif;
  font-weight: 800;
  position: absolute;
  z-index: 99;
  bottom: 0;
  right: -100px;
}
.promo .subtitle {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 11px;
  font-weight: 100;
  background: #000;
  padding: 4px 6px;
  border-radius: 4px;
  z-index: 2;
}
.promo .normal-item {
  padding: 20px;
}
.promo .normal-item h2 {
  font-size: 16px;
  margin: 0;
}
.promo .normal-item h3 {
  font-size: 12px;
  font-weight: 100;
  font-family: 'Lato', Calibri, Arial, sans-serif;
  margin: 0;
}
.promo .cover {
  display: block;
  margin: 0;
  padding: 30px;
  box-sizing: border-box;
  text-decoration: none;
  color: #fff;
  text-shadow: 0px 0px 2px #000;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #000000 100%);
  position: absolute;
  bottom: 0;
  z-index: 2;
  width: 100%;
}
.promo .cover .img-game-promo {
  max-width: 200px;
  display: block;
}
.promo .cover h2 {
  font-size: 24px;
  background: #11a5e4;
  padding: 5px;
  margin: 0;
  display: inline-block;
  clear: both;
}
.promo .cover h3 {
  font-size: 18px;
  font-weight: 100;
  font-family: 'Lato', Calibri, Arial, sans-serif;
  background: #000;
  margin: 0;
  padding: 5px;
  display: inline-block;
  clear: both;
}
@media screen and (max-width: 768px) {
  .promo .cover h2 {
    font-size: 14px;
    background: #11a5e4;
    padding: 5px;
    display: inline-block;
    clear: both;
  }
  .promo .cover h3 {
    font-size: 12px;
    font-weight: 100;
    font-family: 'Lato', Calibri, Arial, sans-serif;
    background: #000;
    margin: 0;
    padding: 5px;
    display: inline-block;
    clear: both;
  }
  .promo .cover p {
    font-size: 11px;
    display: none;
  }
  .promo .cover .subtitle {
    display: none;
  }
}
/* Rank
---------------------------------*/
progress[value]::-webkit-progress-bar {
  background-image: linear-gradient(to right, #4c4c4c 0%, #4c4c4c 100%);
  border-radius: 2px;
}
progress[value]::-webkit-progress-value {
  background-image: -webkit-linear-gradient(-45deg, transparent 33%, rgba(0, 0, 0, 0.1) 33%, rgba(0, 0, 0, 0.1) 66%, transparent 66%), -webkit-linear-gradient(top, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), -webkit-linear-gradient(left, #11a5e4, #e45011);
  border-radius: 2px;
  background-size: 1px 20px, 100% 100%, 100% 100%;
}
progress:not([value]) {
  background: #000;
}
progress[value] {
  appearance: none;
  border: none;
  width: 100%;
  height: 10px;
  border-radius: 3px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5) inset;
  background-image: none;
  background-color: #11a5e4;
  background: #11a5e4;
  color: #11a5e4;
}
#user_rank {
  text-align: center;
}
#user_rank img {
  width: 125px;
  display: block;
  margin: 0 auto;
}
.rank {
  opacity: 0;
  filter: grayscale(100%);
  transition: all 400ms cubic-bezier(0.765, 0.225, 0, 0.845);
  text-align: center;
  display: none;
}
.rank-done {
  display: block;
  opacity: .3;
  filter: grayscale(0);
}
#current-rank {
  transition: all 400ms cubic-bezier(0.765, 0.225, 0, 0.845);
  display: block;
  opacity: 1;
  filter: grayscale(0%);
}
.dropdown-rank {
  padding: 10px;
  color: #fff;
}
/* Challenger
---------------------------------*/
.slidetwo-container {
  /* end .slideTwo */
}
.slidetwo-container .slideTwo {
  width: 100%;
  height: 30px;
  background: #333;
  margin: 5px auto;
  position: relative;
  border-radius: 50px;
  box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.5), 0px 1px 0px rgba(255, 255, 255, 0.2);
}
.slidetwo-container .slideTwo:after {
  content: '';
  position: absolute;
  top: 14px;
  left: 14px;
  height: 2px;
  width: 52px;
  background: #11a5e4;
  border-radius: 50px;
  box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.5), 0px 1px 0px rgba(255, 255, 255, 0.2);
}
.slidetwo-container .slideTwo label {
  display: block;
  width: 140px;
  height: 22px;
  cursor: pointer;
  position: absolute;
  top: 6px;
  z-index: 1;
  font-family: 'Intro', sans-serif;
  font-weight: 800;
  left: 4px;
  font-size: 11px;
  font-family: 'Lato', Calibri, Arial, sans-serif;
  padding: 2px 0 0 23px;
  background: #11a5e4;
  border-radius: 50px;
  transition: all 0.4s ease;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
  background: linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
}
.slidetwo-container .slideTwo label:after {
  content: '';
  width: 10px;
  height: 10px;
  position: absolute;
  top: 6px;
  left: 6px;
  background: #333;
  border-radius: 50px;
  box-shadow: inset 0px 1px 1px #000000, 0px 1px 0px rgba(255, 255, 255, 0.9);
}
.slidetwo-container .slideTwo input[type=checkbox] {
  visibility: hidden;
}
.slidetwo-container .slideTwo input[type=checkbox]:checked + label {
  left: 54px;
}
.slidetwo-container .slideTwo input[type=checkbox]:checked + label:after {
  background: $activeColor;
  /*activeColor*/
}
.clg-heading h1 {
  font-size: 40px;
}
.clg-heading h1 span {
  font-size: 12px;
}
@media screen and (max-width: 768px) {
  .clg-heading {
    padding: 10px;
    margin: 20px 0 0 0;
    text-align: center;
  }
  .clg-heading h1 {
    font-size: 24px;
  }
  .clg-heading .clg-game {
    display: block;
    float: none;
    position: absolute;
    top: -50px;
    left: 0;
  }
  .clg-heading .detail {
    font-size: 12px;
  }
  .clg-heading .addthis_inline_share_toolbox {
    display: none;
  }
}
.select-platform {
  display: none;
  position: absolute;
  bottom: -10px;
  left: 15px;
  width: calc(100% - 30px);
  border-radius: 0 0 4px 4px;
  z-index: 1;
}
.select-platform.form-control {
  border: 1px solid #11a5e4;
}
.unselected {
  opacity: .3;
}
.clg-info {
  padding: 10px 0 0 0;
}
.clg-info strong {
  font-size: .7em;
  display: block;
  opacity: .8;
}
.clg-info h4 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  font-weight: 300;
}
.clg-info h4 span {
  font-size: .5em;
  margin-left: 10px;
  font-weight: 100;
  color: #11a5e4;
}
@media screen and (max-width: 768px) {
  .clg-info h4 {
    border: none;
    margin: 0 0 10px 0;
  }
}
#clg-filter {
  height: auto;
  background: #000;
  text-align: justify;
  text-justify: distribute-all-lines;
}
#clg-filter .filter-item {
  width: auto;
  height: 40px;
  padding: 0 12%;
  vertical-align: top;
  display: inline-block;
  *display: inline;
  zoom: 1;
  color: yellow;
  border: 1px solid #fff;
}
#clg-filter .pusher {
  width: 100%;
  display: inline-block;
  font-size: 0;
  line-height: 0;
}
.clg-promo {
  text-align: center;
  height: 200px;
  margin: -20px 0;
}
.clg-promo h2 {
  font-size: 1.5em;
  padding: 50px 0 20px 0;
  font-family: 'Lato', Calibri, Arial, sans-serif;
}
.prev-step {
  border: 1px solid rgba(255, 255, 255, 0.2);
  margin-top: 5px;
  cursor: pointer;
  background-color: none;
  border-radius: 20px;
  padding: 2px 14px;
  font-size: 12px;
  transition: all 400ms cubic-bezier(0.765, 0.225, 0, 0.845);
}
.prev-step:hover {
  background-color: #11a5e4;
}
.ef-big-select {
  border: 1px solid #11a5e4;
  padding: 25px;
  transition: all 400ms cubic-bezier(0.765, 0.225, 0, 0.845);
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 4px;
  text-align: center;
}
.ef-big-select img {
  width: 100%;
}
.ef-big-select:hover {
  background: #11a5e4;
}
.tourn-list {
  align-items: center;
  justify-content: center;
  border: 1px solid #040404;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  margin: 10px 0 0 0;
  position: relative;
  overflow: hidden;
}
.tourn-list p {
  opacity: .7;
}
.tourn-list h4 span {
  font-size: 16px;
  font-weight: 100;
}
.tourn-list .thumb {
  padding: 30px 0 0 0;
}
.tourn-list .btn-efantz {
  margin: 0;
}
ul.prize-distribution {
  list-style: none;
  padding: 0;
  width: 100%;
}
ul.prize-distribution li {
  border-radius: 4px;
  width: 100%;
  margin: 0 10px 10px 0;
  font-size: 12px;
}
.clg-list {
  align-items: center;
  justify-content: center;
  border: 1px solid #040404;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  margin: 10px 0 0 0;
  position: relative;
  overflow: hidden;
  padding: 10px;
}
.clg-list p {
  opacity: .6;
}
.clg-list .thumb {
  padding: 30px 0 0 0;
}
.clg-list .btn-efantz {
  margin: 0;
}
.clg-list:hover {
  border: 1px solid #000000;
}
@media screen and (max-width: 768px) {
  .clg-list {
    text-align: center;
  }
  .clg-list h4 span {
    display: block;
    margin: 0;
    padding: 0;
  }
  .clg-list .btn-efantz-list {
    display: block;
    float: none;
  }
}
.clg-username {
  position: absolute;
  z-index: 2;
  display: block;
  bottom: 0px;
  left: 0;
  padding: 5px;
  background: #11a5e4;
  font-size: 12px;
}
.clg-username:before {
  content: ' ';
  position: absolute;
  top: 0;
  right: -24px;
  width: 25px;
  height: 24px;
  -webkit-clip-path: polygon(71% 0, 0 0, 0 100%);
  clip-path: polygon(71% 0, 0 0, 0 100%);
  background: #11a5e4;
}
.clg-profile-image {
  border-radius: 4px;
  overflow: hidden;
  height: 100px;
  background: #000;
  left: 0;
  top: 0;
}
.clg-profile-image.pos-absolute {
  position: absolute;
}
.clg-profile-image img {
  height: 100%;
}
@media screen and (max-width: 768px) {
  .clg-profile-image {
    background: none;
    height: 150px;
    width: 150px;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 50%;
  }
  .clg-profile-image img {
    width: 100%;
    height: auto;
  }
}
.tourn-profile-image {
  border-radius: 4px;
  overflow: hidden;
  max-height: 108px;
  width: 100%;
  background: #000;
  margin-left: -20px;
}
.tourn-profile-image img {
  width: 100%;
}
@media screen and (max-width: 768px) {
  .tourn-profile-image {
    max-height: none;
    margin: 10px 0 0 0;
  }
}
.clg-logo img {
  width: 80%;
  margin-top: -20px;
}
@media screen and (max-width: 768px) {
  .clg-logo {
    display: none;
  }
}
.clg-logo-mobile {
  display: none;
}
@media screen and (max-width: 768px) {
  .clg-logo-mobile {
    display: block;
    text-align: center;
  }
  .clg-logo-mobile img {
    width: 100px;
  }
}
.clg-event .event-details {
  font-weight: normal;
}
.clg-event .event-details .detail {
  font-weight: normal;
  font-size: 1em;
}
@media screen and (max-width: 768px) {
  .clg-event .event-details .detail {
    font-size: 14px;
    font-weight: normal;
  }
}
.clg-event .close-table {
  background-color: #f00;
  border-radius: 5px;
  border: solid 1px #fc3;
}
.clg-event .close-table h3 {
  padding: 3px 0;
  text-align: center;
  color: #000;
}
.clg-event .clg-game img {
  width: 200px;
}
@media screen and (max-width: 768px) {
  .clg-event .clg-game img {
    width: 100px;
  }
}
.clg-event .clg-players {
  position: relative;
}
.clg-event .clg-players .vs_vs {
  position: absolute;
  left: calc(50% - 37px);
  top: 100px;
  display: block;
  align-self: center;
  background: #fff;
  color: #000;
  width: 75px;
  height: 75px;
  z-index: 999;
  box-shadow: inset 0px 0px 40px -7px rgba(0, 0, 0, 0.75);
  font-family: 'Intro', sans-serif;
  font-weight: 800;
  font-size: 24px;
  padding: 20px 0 0 0;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}
@media screen and (max-width: 768px) {
  .clg-event .clg-players .vs_vs {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .clg-event .clg-players .vs_vs {
    box-shadow: none;
    clip-path: none;
    background: none;
    color: #fff;
  }
}
.clg-event .clg-players .thumb {
  width: 100%;
  height: 290px;
  background: #000;
  overflow: hidden;
  margin: 0 0 20px 0;
}
.clg-event .clg-players .thumb img {
  width: 100%;
}
@media screen and (max-width: 768px) {
  .clg-event .clg-players .thumb {
    height: 100px;
  }
}
.clg-event .clg-players .clg-player {
  border-bottom: 4px solid #11a5e4;
  background: #1e1e1e;
  box-shadow: inset 0px 0px 60px 0px rgba(0, 0, 0, 0.75);
  border-radius: 4px;
}
.clg-event .clg-players .clg-player .clg-rank {
  position: absolute;
  width: 50px;
  top: 10px;
  left: 20px;
}
.clg-event .clg-players .clg-player .clg-rank.rank-opponent {
  left: auto;
  right: 20px;
}
.clg-event .clg-players .clg-player img {
  border-radius: 4px;
  overflow: hidden;
}
.clg-event .clg-players .clg-player h2 {
  padding: 0 0 5px 0;
}
@media screen and (max-width: 768px) {
  .clg-event .clg-players .clg-player h2 {
    font-size: 16px;
    font-family: 'Lato', Calibri, Arial, sans-serif;
  }
}
.clg-event .clg-players .clg-player h3 {
  margin-bottom: 15px;
  font-size: 16px;
}
@media screen and (max-width: 768px) {
  .clg-event .clg-players .clg-player h3 {
    font-size: 12px;
    font-family: 'Lato', Calibri, Arial, sans-serif;
  }
}
.clg-event .clg-players .clg-player .player-details {
  text-align: left;
  padding: 0 10px;
}
@media screen and (max-width: 768px) {
  .clg-event .clg-players .clg-player .player-details {
    display: none;
  }
}
.clg-event .clg-players .clg-player .ready-status {
  width: 50%;
  margin: 0 auto;
  border-radius: 5px;
}
.clg-event .clg-players .clg-player .ready-status h3 {
  padding: 5px;
  text-align: center;
}
.clg-event .clg-players .clg-player .ready-status.ready {
  background-color: #fff;
}
.clg-event .clg-players .clg-player .ready-status.ready h3 {
  color: #000;
}
.clg-event .clg-players .clg-player .ready-status.not-ready {
  background-color: #fc3;
}
.clg-event .clg-players .clg-player .ready-status img {
  height: 25%;
  width: 25%;
}
.clg-event .clg-players .clg-entry .circle-box {
  display: inline-block;
  height: 60px;
  width: 60px;
  padding: 20px;
  margin: auto;
  position: relative;
  z-index: 1;
  text-align: center;
  font-weight: bold;
  border-radius: 50%;
  background-color: #000;
}
.clg-event .clg-players .clg-entry .circle-box h2 {
  margin: 0 -25px;
  font-size: 28px;
}
.clg-event .clg-players .clg-entry .entry-values .prize-money {
  font-size: 1.9em;
  color: #11a5e4;
}
.clg-event .clg-players .clg-entry .entry-values .entry-cost {
  color: #11a5e4;
}
.game {
  transition: all 400ms cubic-bezier(0.765, 0.225, 0, 0.845);
  cursor: pointer;
  background-color: rgba(4, 4, 4, 0.7);
  border-radius: 4px;
  margin-bottom: 20px;
  font-size: 10px;
  color: #fff;
  display: block;
  padding: 5px;
  text-align: center;
}
.game p {
  padding: 0 10px;
}
.game img {
  width: 100%;
}
.game:hover {
  background: #11a5e4;
}
.game .platform {
  border-top: 1px solid #11a5e4;
  background: #1e1e1e;
  padding: 5px;
}
.clg-create {
  text-align: center;
}
.clg-create .next-btn {
  width: 25%;
  margin: 0 auto;
  border-radius: 5px;
  background-color: #11a5e4;
}
.clg-create .next-btn h3 {
  padding: 5px;
  text-align: center;
}
.clg-create .submit-game-id {
  text-align: center;
}
.clg-create .submit-game-id h4 {
  color: #f00;
}
.clg-create .submit-game-id .id-submit {
  margin: 0 auto;
  width: 50%;
}
.clg-create .entry-fee {
  text-align: center;
}
.clg-create .entry-fee .entry-fee-submit {
  display: inline-block;
  margin: 0 auto;
  width: 30%;
}
.clg-create .match-rules h4 {
  font-size: 1.3em;
  margin-bottom: 15px;
}
.clg-create .match-rules .match-rounds {
  margin-left: 30%;
}
.clg-create .match-rules .match-minutes {
  margin-left: 30%;
  margin-top: 16px;
}
.clg-create .match-rules .match-minutes .dropdown-toggle {
  margin-left: 25px;
  border-radius: 3px;
}
.clg-create .match-rules .match-region {
  margin-right: 30%;
}
.clg-create .match-rules .match-region .dropdown-toggle {
  border-radius: 3px;
}
.clg-create .match-rules .match-map {
  margin-right: 30%;
}
.clg-create .match-rules .match-map .dropdown-toggle {
  border-radius: 3px;
}
.clg-create .match-rules .table-name {
  width: 100%;
}
.clg-create .match-rules .table-name h2 {
  text-align: center;
}
.clg-create .match-rules .table-name .name-submit {
  margin: 0 auto;
  width: 50%;
}
.clg-create .match-rules .custom-rules .custom-rules-submit {
  margin: 0 auto;
  width: 50%;
}
@media screen and (max-width: 992px) {
  .clg-create .match-rules .match-rounds {
    margin: 0 auto;
  }
  .clg-create .match-rules .match-minutes {
    margin: 0 auto;
  }
  .clg-create .match-rules .match-region {
    margin: 15px auto 0;
  }
  .clg-create .match-rules .match-map {
    margin: 0 auto;
  }
  .clg-create .match-rules .table-name .name-submit {
    width: 50%;
  }
  .clg-create .match-rules .custom-rules .custom-rules-submit {
    width: 50%;
  }
}
@media screen and (max-width: 768px) {
  .clg-create .submit-game-id .id-submit {
    width: 250px;
  }
  .clg-create .match-rules .match-rounds {
    margin: 0 auto;
  }
  .clg-create .match-rules .match-minutes {
    margin: 0 auto;
  }
  .clg-create .match-rules .match-region {
    margin: 15px auto 0;
  }
  .clg-create .match-rules .match-map {
    margin: 0 auto;
  }
  .clg-create .match-rules .table-name .name-submit {
    width: 250px;
  }
  .clg-create .match-rules .custom-rules .custom-rules-submit {
    width: 250px;
  }
}
@media screen and (max-width: 480px) {
  .clg-create .next-btn {
    width: 50%;
  }
}
/* Accessories / Helpers
---------------------------------*/
.img-responsive {
  width: 100%;
}
.selected {
  background: #11a5e4;
  border-radius: 4px;
}
.small {
  font-size: 12px;
  float: left;
}
.vertical-center {
  min-height: 100%;
  min-height: 80vh;
  display: flex;
  align-items: center;
}
.dropdown-divider {
  background-color: rgba(255, 255, 255, 0.1);
}
.hidden {
  display: none;
}
.visible-o {
  opacity: 1;
}
.visible {
  opacity: 1;
  display: block;
  display: flex;
}
.hr {
  height: 1px;
  clear: both;
  margin: 25px 0;
}
.hr-center {
  opacity: .2;
  height: 1px;
  clear: both;
  margin: 25px 0;
  background: -moz-linear-gradient(left, rgba(0, 0, 0, 0) 0%, #ffffff 50%, rgba(0, 0, 0, 0) 100%);
  background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0) 0%, #ffffff 50%, rgba(0, 0, 0, 0) 100%);
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, #ffffff 50%, rgba(0, 0, 0, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='@dark00', endColorstr='@dark00', GradientType=1);
}
.hr-left {
  height: 1px;
  clear: both;
  margin: 25px 0;
  margin: 5px 0;
  background: -moz-linear-gradient(left, #11a5e4 0%, rgba(0, 0, 240, 0) 100%);
  background: -webkit-linear-gradient(left, #11a5e4 0%, rgba(0, 0, 240, 0) 100%);
  background: linear-gradient(to right, #11a5e4 0%, rgba(0, 0, 240, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='@color-user', endColorstr='@darkf0', GradientType=1);
}
.videoWrapper {
  position: relative;
  padding-bottom: 54.1%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}
.preload * {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
}
/* Forms
---------------------------------*/
.form-wrap {
  padding: 0 25px;
}
.form-control {
  background: #1e1e1e;
  color: #fff;
}
label {
  color: #fff;
}
.datetimepicker.dropdown-menu {
  background-color: #000;
  border: 1px solid #11a5e4;
}
.datetimepicker.dropdown-menu:after {
  border-bottom: 6px solid #11a5e4;
}
.form-control[disabled] {
  background-color: #1e1e1e;
  color: #808080;
}
.form-control {
  background-color: #1e1e1e;
}
.hirez,
.arenanet,
.nexon,
.lol,
.origin,
.xblive,
.psn,
.battlenet,
.steam,
.twitch,
.hitbox,
.android,
.ios,
.youtube-gaming,
.azubu {
  background-size: contain !important;
  padding-right: 100px;
}
.ios {
  background: url('/img/logos/services/ios.jpg') top right no-repeat;
}
.android {
  background: url('/img/logos/services/androi.jpg') top right no-repeat;
}
.hirez {
  background: url('/img/logos/services/hires.jpg') top right no-repeat;
}
.arenanet {
  background: url('/img/logos/services/arenanet.jpg') top right no-repeat;
}
.nexon {
  background: url('/img/logos/services/nexon.jpg') top right no-repeat;
}
.lol {
  background: url('/img/logos/services/lol.jpg') top right no-repeat;
}
.origin {
  background: url('/img/logos/services/origin.jpg') top right no-repeat;
}
.xblive {
  background: url('/img/logos/services/xblive.jpg') top right no-repeat;
}
.psn {
  background: url('/img/logos/services/psn.jpg') top right no-repeat;
}
.battlenet {
  background: url('/img/logos/services/battlenet.jpg') top right no-repeat;
}
.steam {
  background: url('/img/logos/services/steam.jpg') top right no-repeat;
}
.twitch {
  background: url('/img/logos/services/twitch.jpg') top right no-repeat;
}
.hitbox {
  background: url('/img/logos/services/hitbox.jpg') top right no-repeat;
}
.youtube-gaming {
  background: url('/img/logos/services/youtube-gaming.jpg') top right no-repeat;
}
.azubu {
  background: url('/img/logos/services/azubu.jpg') top right no-repeat;
}
.rangeslider {
  background: #000;
}
.rangeslider .rangeslider__fill {
  background: #11a5e4;
}
.wager-box {
  text-align: left;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
  text-align: center;
}
.wager-box .your-wager {
  padding: 5px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.1);
}
@media screen and (max-width: 768px) {
  .wager-box .your-wager h2 {
    font-size: 16px;
  }
}
.wager-box .payout {
  padding: 5px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.1);
}
@media screen and (max-width: 768px) {
  .wager-box .payout h2 {
    font-size: 16px;
  }
}
@media screen and (max-width: 768px) {
  .wager-box {
    background: none;
  }
}
.wager-panel:before {
  clip-path: polygon(0 6%, 100% 0, 100% 93%, 0% 100%);
  content: " ";
  width: 30px;
  height: 300px;
  position: absolute;
  top: 99px;
  left: -15px;
}
/* Contests
---------------------------------*/
.contest .contest-img {
  width: 150px;
  height: 150px;
  border-radius: 4px;
  display: inline-block;
  overflow: hidden;
}
.contest .contest-img img {
  height: 100%;
}
.contest .contest-desc h3 {
  margin: 0 0 25px 0;
}
.contest .contest-desc h4 {
  font-weight: bold;
}
.contest .contest-desc ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.contest .contest-desc ul li {
  margin: 0;
  padding: 0;
}
/* Friend Requests
---------------------------------*/
.friend-img {
  width: 200px;
  height: 200px;
  border-radius: 4px;
  display: block;
  overflow: hidden;
  text-align: center;
  margin: 0 auto 20px auto;
  background: #000;
}
.friend-img img {
  width: 100%;
  margin: 0 auto;
}
.friend-request .contest-desc h3 {
  margin: 0 0 25px 0;
}
.friend-request .contest-desc ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.friend-request .contest-desc ul li {
  margin: 0;
  padding: 0;
}
/* Friend Requests
---------------------------------*/
.message .message-desc h3 {
  margin: 0 0 25px 0;
}
.message .message-desc ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.message .message-desc ul li {
  margin: 0;
  padding: 0;
}
/* Tabs
---------------------------------*/
.nav-tabs {
  border-color: #11a5e4;
}
.nav-tabs > li > a {
  color: #fff;
}
.nav-tabs > li > a:hover {
  background: #11a5e4;
  color: #fff;
  border-color: #11a5e4;
}
.nav-tabs > li > a:focus {
  background: #11a5e4;
  color: #fff;
  border-color: #11a5e4;
}
.tab-pane {
  padding: 25px;
}
/* Containers
---------------------------------*/
.ef-body {
  transition: all 400ms cubic-bezier(0.765, 0.225, 0, 0.845);
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 10px 0 10px;
  position: relative;
}
@media screen and (max-width: 1200px) {
  .ef-body {
    margin-top: 0;
  }
}
.ef-body.chat-open {
  padding: 0 240px 0 10px;
}
@media screen and (max-width: 1199px) {
  .ef-body.chat-open {
    padding-right: 0;
  }
}
.ef-interior {
  padding: 50px;
  background: rgba(30, 30, 30, 0.4);
  border-top: 4px solid rgba(255, 255, 255, 0.1);
}
.ef-grid {
  min-height: 100vh;
  position: relative;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 71%, rgba(0, 0, 0, 0.8) 83%);
  padding: 0 0 20px 0;
}
.ef-grid-inner {
  position: relative;
  z-index: 10;
}
#ef-container {
  transition: all 400ms cubic-bezier(0.765, 0.225, 0, 0.845);
  position: relative;
  z-index: 10;
  margin-left: 60px;
  background: url('/img/bgs/blue.jpg') top center no-repeat fixed;
  min-width: 190px;
  min-height: 100vh;
  /* submenu
  ----------------*/
  /* Main Menu
  ---------------------------------*/
  /* Profile Colors
  ----------------------------------*/
  /* user Menu
  ----------------
  .user-menu {
    float: right;
    .profile_icon {
      float: left;
      width: 26px;
      height: 26px;
      margin: 0 10px 0 0;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
    >ul {
      >li {
        padding: 0;
        margin: 0;
        >a {
          height: @main-menu-height;
          padding: 16px 10px;
          //border-left: 1px solid fade(@contrast-dark, 10%);
          border-radius: 0;
          font-size: 13px;
          color: fade(@contrast-dark, 70%) !important;
          &:hover {
            color: @contrast-dark !important;
          }
          &:last-child {
            //border-right: 1px solid fade(@contrast-dark, 10%);
          }
        }
      }
    }
  }
*/
  /* Main Menu
  ----------------*/
  /* Main Menu Open */
  /* Chat
  ---------------------------------*/
}
@media screen and (max-width: 768px) {
  #ef-container {
    margin-left: 0;
  }
}
#ef-container .navbar {
  position: relative;
  z-index: 99;
}
#ef-container .main-menu {
  clear: both;
  padding: 20px;
  position: relative;
  z-index: 9;
}
#ef-container .main-menu .mm-logo {
  transition: all 400ms cubic-bezier(0.765, 0.225, 0, 0.845);
}
#ef-container .main-menu .mm-logo:hover {
  -webkit-filter: drop-shadow(0 0 8px #f6fcfe);
  filter: drop-shadow(0 0 8px #f6fcfe);
}
#ef-container .main-menu .mm-logo img {
  width: 65px;
  margin-left: -22px;
  margin-top: -17px;
}
@media screen and (max-width: 768px) {
  #ef-container .main-menu .mm-logo {
    position: fixed;
    top: 0;
    left: calc(50vw - 35px);
    background: #11a5e4;
  }
  #ef-container .main-menu .mm-logo img {
    margin: 0;
  }
  #ef-container .main-menu .mm-logo::before {
    content: ' ';
    position: absolute;
    top: 0;
    right: -40px;
    height: 65px;
    width: 40px;
    background: #11a5e4;
    -webkit-clip-path: polygon(0 100%, 0 0, 100% 0);
    clip-path: polygon(0 100%, 0 0, 100% 0);
  }
  #ef-container .main-menu .mm-logo::after {
    content: ' ';
    position: absolute;
    top: 0;
    left: -39px;
    height: 65px;
    width: 40px;
    background: #11a5e4;
    -webkit-clip-path: polygon(100% 0, 0 0, 100% 100%);
    clip-path: polygon(100% 0, 0 0, 100% 100%);
  }
}
#ef-container .main-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  #ef-container .main-menu ul {
    display: block;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.9);
    text-align: center;
    position: fixed;
    left: 0;
    top: -100vh;
    padding: 60px 0 0 0;
    z-index: 999;
  }
}
@media screen and (max-width: 768px) {
  #ef-container .main-menu ul li {
    padding: 20px 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    float: none;
    display: block;
  }
  #ef-container .main-menu ul li ul.nav {
    display: none;
  }
}
#ef-container .main-menu ul li a:not(.mm-logo) {
  transition: all .2s;
  transition-timing-function: ease-in-out;
  font-size: 14px;
  font-weight: normal;
  padding: 10px 20px;
  font-family: 'Intro', sans-serif;
  font-weight: 800;
  color: #fff;
}
#ef-container .main-menu ul li a:not(.mm-logo):hover,
#ef-container .main-menu ul li a:not(.mm-logo):active {
  text-decoration: none;
  background: rgba(0, 0, 0, 0.5);
}
#ef-container #nav-item-stacked {
  padding: 4px;
  display: block;
  padding: 12px 20px;
  text-decoration: none;
  font-size: 11px;
}
#ef-container #nav-item-stacked > span {
  display: block;
}
#ef-container #nav-item-stacked .ef-icon.ef-icon-inline {
  height: 12px;
}
#ef-container #menu-main {
  font-family: 'Intro', sans-serif;
  font-weight: 800;
  position: relative;
  z-index: 100;
  width: 100%;
  height: 50px;
  margin: 0 0 40px 0;
}
#ef-container #menu-main .nav .nav-item a.nav-link {
  height: 50px;
  font-family: 'Intro', sans-serif;
  font-weight: 800;
  font-weight: 700;
  font-size: 12px;
  color: #fff;
  transition: 0.2s ease-in-out;
  background: none;
  opacity: 1;
}
#ef-container #menu-main .nav .nav-item a.nav-link:hover {
  border-radius: 0 0 4px 4px;
  background: #11a5e4;
}
#ef-container #menu-main #mobile-menu {
  display: none;
  width: 30px;
  height: 18px;
  position: relative;
  float: left;
  margin: 18px 0 0 13px;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
}
#ef-container #menu-main #mobile-menu span {
  display: block;
  position: absolute;
  height: 4px;
  width: 100%;
  background: #11a5e4;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
#ef-container #menu-main #mobile-menu span:nth-child(1) {
  top: 0px;
}
#ef-container #menu-main #mobile-menu span:nth-child(2) {
  top: 7px;
}
#ef-container #menu-main #mobile-menu span:nth-child(3) {
  top: 14px;
}
#ef-container #menu-main #mobile-menu.open span:nth-child(1) {
  top: 7px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}
#ef-container #menu-main #mobile-menu.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}
#ef-container #menu-main #mobile-menu.open span:nth-child(3) {
  top: 7px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
@media screen and (max-width: 1200px) {
  #ef-container #menu-main {
    margin: 0 0 20px 0;
  }
  #ef-container #menu-main #mobile-menu {
    display: block;
  }
  #ef-container #menu-main .wrap-mobile-menu {
    position: absolute;
    top: -1200px;
    padding: 5px;
    background: #1e1e1e;
    left: 62px;
    z-index: 2;
  }
  #ef-container #menu-main .wrap-mobile-menu .nav {
    margin: 0;
  }
  #ef-container #menu-main .wrap-mobile-menu .nav-inline .nav-item {
    display: block;
    width: 100%;
    float: none;
  }
  #ef-container #menu-main .wrap-mobile-menu .nav-inline .nav-item .nav-link {
    border: none;
  }
}
@media screen and (max-width: 992px) {
}
@media screen and (max-width: 768px) {
}
@media screen and (max-width: 480px) {
}
#ef-container #menu-main .logo {
  float: left;
  background: #11a5e4;
  background: linear-gradient(to bottom, #11a5e4 0%, #0a6085 100%);
  overflow: hidden;
}
#ef-container #menu-main .logo img {
  height: 50px;
  padding: 4px 10px;
}
#ef-container #menu-main .dropdown-divider {
  height: 1px;
  background: rgba(255, 255, 255, 0.05);
}
#ef-container #menu-main .nav-item {
  border-radius: 4px;
}
#ef-container #menu-main .wrap-mobile-menu > ul > li {
  padding: 0;
  margin: 0;
}
#ef-container #menu-main .wrap-mobile-menu > ul > li > a {
  height: 53px;
  padding: 16px 10px;
  border-radius: 0;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.7) !important;
}
#ef-container #menu-main .wrap-mobile-menu > ul > li > a:hover {
  color: #fff !important;
}
#ef-container .menu-section span {
  font-size: 14px;
  background: #11a5e4;
  border-radius: 0;
  margin: 0;
  padding: 10px;
  display: block;
}
#ef-container .mobile-only {
  display: none;
}
@media screen and (max-width: 1200px) {
  #ef-container .mobile-only {
    display: block;
  }
}
#ef-container .mobile-only-xs {
  display: none;
}
@media screen and (max-width: 480px) {
  #ef-container .mobile-only-xs {
    display: block;
  }
}
#ef-container .mobile-no {
  display: block;
}
@media screen and (max-width: 1200px) {
  #ef-container .mobile-no {
    display: none;
  }
}
#ef-container .user-color {
  width: 20px;
  height: 20px;
  float: left;
  margin: 0 10px 10px 0;
  border: 1px solid #fff;
  cursor: pointer;
}
#ef-container .user-color.red {
  background-color: #ed1f24;
}
#ef-container .user-color.green {
  background-color: #00a500;
}
#ef-container .user-color.hunter {
  background-color: #1c7553;
}
#ef-container .user-color.blue {
  background-color: #11a5e4;
}
#ef-container .user-color.purple {
  background-color: #552392;
}
#ef-container .user-color.orange {
  background-color: #ff5500;
}
#ef-container .user-color.turquoise {
  background-color: #76dfda;
}
#ef-container .user-color.slate {
  background-color: #515151;
}
#ef-container .user-color.pink {
  background-color: #ff5cf7;
}
#ef-container .user-color.military {
  background-color: #373F2E;
}
#ef-container .user-color.badlands {
  background-color: #BF5D38;
}
#ef-container .user-color.corporate {
  background-color: #091541;
}
#ef-container .user-color.salmon {
  background-color: #D54A4C;
}
#ef-container .user-color.notthebees {
  background-color: #ff9900;
}
#ef-container .user-color.crimson {
  background-color: #4F130F;
}
#ef-container .user-color.verde {
  background-color: #43AD16;
}
#ef-container .user-color.moss {
  background-color: #333300;
}
#ef-container .user-color.seafoam {
  background-color: #059e8f;
}
#ef-container .user-color.fusion {
  background-color: #a90a5e;
}
#ef-container .ef-menu-main {
  position: relative;
  z-index: 11;
  width: 230px;
  height: 100vh;
  position: fixed;
  top: 0;
  transition: all 400ms cubic-bezier(0.765, 0.225, 0, 0.845);
}
#ef-container .ef-menu-main.left {
  left: -230px;
}
#ef-container .ef-menu-outer {
  position: fixed;
  left: 0;
  width: 60px;
  height: 100vh;
  background: #11a5e4;
  z-index: 99;
  transition: all 400ms cubic-bezier(0.765, 0.225, 0, 0.845);
}
@media screen and (max-width: 768px) {
  #ef-container .ef-menu-outer {
    width: 100vw;
    bottom: 0;
    top: auto;
    height: 60px;
  }
  #ef-container .ef-menu-outer ul {
    display: flex;
    width: 100%;
  }
  #ef-container .ef-menu-outer ul li {
    float: left;
    flex: 10;
    align-self: center;
  }
}
#ef-container .ef-menu-outer .ef-logo {
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  transition: all 400ms cubic-bezier(0.765, 0.225, 0, 0.845);
  padding: 12px 5px 0 5px;
  width: 50px;
  height: 50px;
  display: block;
  text-align: center;
  margin: 15px auto 15px auto;
}
#ef-container .ef-menu-outer .ef-logo img {
  width: 100%;
  margin-top: -5px;
}
#ef-container .ef-menu-outer .ef-logo:hover {
  background: #0a6085;
}
#ef-container .ef-menu-outer ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
#ef-container .ef-menu-outer ul li {
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}
#ef-container .ef-menu-outer ul li :nth-child(1) {
  animation: fadein .2s;
}
#ef-container .ef-menu-outer ul li :nth-child(2) {
  animation: fadein .4s;
}
#ef-container .ef-menu-outer ul li :nth-child(3) {
  animation: fadein .6s;
}
#ef-container .ef-menu-outer ul li :nth-child(4) {
  animation: fadein .8s;
}
#ef-container .ef-menu-outer ul li :nth-child(5) {
  animation: fadein 1s;
}
#ef-container .ef-menu-outer ul li a {
  display: block;
  padding: 5px;
  font-size: 20px;
  transition: all 400ms cubic-bezier(0.765, 0.225, 0, 0.845);
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  color: rgba(255, 255, 255, 0.9);
  text-shadow: 0px 0px 2px rgba(255, 255, 255, 0.07);
  box-shadow: inset 0px 5px 15px -6px rgba(0, 0, 0, 0.75);
}
#ef-container .ef-menu-outer ul li a img {
  width: 100%;
}
@media screen and (max-width: 768px) {
  #ef-container .ef-menu-outer ul li a {
    font-size: auto;
  }
  #ef-container .ef-menu-outer ul li a > img {
    max-height: 57px;
    width: auto;
  }
}
#ef-container .ef-menu-outer ul li a:hover {
  color: rgba(255, 255, 255, 0.9);
  text-shadow: 0 0 10px #ffffff;
}
#ef-container .ef-menu-outer ul li a:hover img {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  opacity: 1;
}
#ef-container .ef-menu-outer ul li a.ef-menu-active {
  transition: all 400ms cubic-bezier(0.765, 0.225, 0, 0.845);
  padding: 2px;
}
#ef-container .ef-menu-outer ul li a.ef-menu-active img {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  opacity: 1;
}
#ef-container .ef-menu-inner {
  width: 230px;
  height: 100vh;
  position: absolute;
  display: none;
  left: 0;
  background: #1e1e1e;
  text-align: left;
  overflow-x: hidden;
  overflow-y: scroll;
  border-right: 4px solid #11a5e4;
  transition: all 400ms cubic-bezier(0.765, 0.225, 0, 0.845);
}
#ef-container .ef-menu-inner h2 {
  font-size: 16px;
  padding: 5px;
  position: absolute;
  top: 0;
  width: 230px;
  cursor: pointer;
}
#ef-container .ef-menu-inner .nav-inline {
  padding-right: 10px;
}
#ef-container .ef-menu-inner .sidebar-sub-menu {
  margin: 0;
  text-align: right;
}
#ef-container .ef-menu-inner .sidebar-sub-menu .nav {
  background: none;
  padding: 0;
  margin: 0;
  border-radius: 0;
  border-bottom: 1px solid #11a5e4;
}
#ef-container .ef-menu-inner .sidebar-sub-menu .nav .nav-link {
  border-left: 1px solid #11a5e4;
  padding: 2px 6px;
  margin: 0;
}
#ef-container .ef-menu-inner .logo-lrg img {
  width: 100%;
  padding: 25px;
}
#ef-container .ef-menu-inner ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
#ef-container .ef-menu-inner ul li {
  text-align: left;
}
#ef-container .ef-menu-inner ul li a {
  display: block;
  padding: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  color: #fff;
  text-decoration: none;
  font-family: 'Intro', sans-serif;
  font-weight: 800;
}
#ef-container .ef-menu-inner ul li a:hover {
  background: #11a5e4;
}
@media screen and (min-width: 1199px + 1) {
}
#ef-container.ef-toggled .ef-menu-main {
  left: 60px;
}
@media screen and (max-width: 768px) {
  #ef-container.ef-toggled .ef-menu-main {
    left: 0;
  }
}
#ef-container.ef-toggled .ef-menu-outer {
  border-width: 0px;
  box-shadow: 0px 0px 60px -16px rgba(0, 0, 0, 0.75);
}
#ef-container #chat-cont {
  transition: all 400ms cubic-bezier(0.765, 0.225, 0, 0.845);
  position: fixed;
  right: -230px;
  top: 0;
  z-index: 12;
  height: 100vh;
  background: #1e1e1e;
  border-left: 2px solid #11a5e4;
  width: 230px;
}
#ef-container #chat-cont .user-block {
  height: 271px;
  overflow: hidden;
}
#ef-container #chat-cont .user-block .user-profile .user-logout {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 11px;
  color: #fff;
}
#ef-container #chat-cont .user-block .user-profile .user-image {
  text-align: center;
  padding: 40px 40px 20px 40px;
}
#ef-container #chat-cont .user-block .user-profile .user-image .user-edit {
  position: absolute;
  top: 211px;
  left: 8px;
  color: #fff;
}
#ef-container #chat-cont .user-block .user-profile .user-image .user-pic {
  display: block;
  border-radius: 4px;
  transition: all 400ms cubic-bezier(0.765, 0.225, 0, 0.845);
  border: 1px solid #000;
  height: 148px;
  overflow: hidden;
}
#ef-container #chat-cont .user-block .user-profile .user-image .user-pic img {
  width: 100%;
}
#ef-container #chat-cont .user-block .user-profile .user-image .user-pic:hover {
  border: 1px solid #fff;
}
#ef-container #chat-cont .user-block .user-profile .user-image .user-rank {
  width: 50px;
  position: absolute;
  top: 70px;
  right: 20px;
}
#ef-container #chat-cont .user-block .user-cashflow {
  text-align: center;
}
#ef-container #chat-cont .user-block .user-cashflow a {
  color: #fff;
  display: block;
  background: #000;
}
#ef-container #chat-cont .user-block .user-cashflow a:hover {
  background: #11a5e4;
}
#ef-container #chat-cont .user-block .user-cashflow a ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: inline-block;
}
#ef-container #chat-cont .user-block .user-cashflow a ul li {
  padding: 5px;
  margin: 0;
  font-size: 12px;
  float: left;
}
#ef-container #chat-cont .chat-close {
  transition: all 400ms cubic-bezier(0.765, 0.225, 0, 0.845);
  transform: rotate(0deg);
  position: absolute;
  width: 30px;
  height: 30px;
  overflow: hidden;
  left: -30px;
  top: 0;
  font-size: 16px;
  text-align: center;
  padding-top: 3px;
  background-color: rgba(17, 165, 228, 0.7);
  cursor: pointer;
}
#ef-container #chat-cont .chat-close:hover {
  background-color: #11a5e4;
}
#ef-container #chat-cont.chat-open {
  right: 0;
}
#ef-container #chat-cont.chat-open .chat-close {
  transform: rotate(180deg);
  position: absolute;
  left: 0;
  top: 0;
}
#ef-container #chat-cont .chat-heading {
  position: absolute;
  top: 271px;
}
#ef-container #chat-cont .chat-heading h4 {
  font-size: 14px;
  font-family: 'Intro', sans-serif;
  font-weight: 800;
  text-align: center;
  width: 230px;
  background: #1e1e1e;
  box-shadow: 0px 10px 41px -8px rgba(0, 0, 0, 0.75);
  padding: 10px;
  position: absolute;
  top: -1px;
}
#ef-container #chat-cont iframe {
  height: calc(100vh - 276px);
  padding: 5px;
}
/* Marketplace
---------------------------------*/
.market-promo {
  text-align: center;
  height: 300px;
  margin: -20px 0;
}
.market-promo h2 {
  font-size: 1.5em;
  padding: 200px 0 20px 0;
  font-family: 'Lato', Calibri, Arial, sans-serif;
}
.market-item {
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  padding: 10px 10px 0 10px;
  border-radius: 4px;
  box-sizing: border-box;
  margin: 0 0 20px 0;
}
.market-item .btn.btn-efantz {
  margin: 20px 0 0 0;
}
.market-item .market-amount {
  position: absolute;
  top: 20px;
  right: 30px;
  font-size: 11px;
  padding: 5px 10px;
  background: #11a5e4;
  color: #fff;
  border-radius: 20px;
}
.market-item .market-amount .ef-icon-inline {
  height: 10px;
}
.market-item .market-image {
  border-radius: 4px;
  overflow: hidden;
}
.market-item .market-image > img {
  width: 100%;
}
.market-item h4 {
  font-family: 'Lato', Calibri, Arial, sans-serif;
  font-size: 1em;
  font-weight: 100;
  margin: 20px 0 0 0;
}
.market-item p {
  font-size: .7em;
}
/* Challenger Table
-------------------------------*/
.clg-table {
  margin: 0 10px 5px;
  position: relative;
  border-top: solid 1px #11a5e4;
  padding: 25px;
  background-color: #1e1e1e;
  text-align: center;
}
.clg-table .table-name {
  margin-top: 10px;
}
.clg-table .table-prize h4 span {
  color: #8cff66;
}
.clg-table .table-prize h5 {
  margin-top: 5px;
}
.clg-table .table-prize h5 span {
  color: #fc3;
}
.clg-table .table-game img {
  width: 50%;
  margin-top: -25px;
}
.clg-table .table-game img.csgo {
  filter: invert(100%);
  width: 60%;
  margin: -30px 0 -10px;
}
.clg-table .table-opponent {
  margin: -6px 0 5px;
}
.clg-table .table-opponent span {
  color: #f00;
}
.clg-table .btn-efantz {
  margin: 10px 0 0 0;
}
.se {
  padding: 20px;
  margin: 30px 30px 0 30px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  position: relative;
  background: rgba(0, 0, 0, 0.2);
}
.se .event-live {
  background: #11a5e4;
  padding: 4px 10px;
  border-radius: 4px;
  font-size: 10px;
  z-index: 999;
  position: absolute;
  top: 20px;
  right: 20px;
}
.se .se_starts {
  background: rgba(0, 0, 0, 0.5);
  padding: 4px 10px;
  text-align: center;
  border-radius: 4px;
  font-size: 10px;
  margin-bottom: 5px;
}
.se .se_image {
  z-index: 0;
  width: 50px;
  float: left;
  border-radius: 4px;
  overflow: hidden;
  margin-right: 10px;
}
.se .se_image img {
  width: 100%;
}
@media screen and (max-width: 768px) {
  .se .se_image {
    display: none;
  }
}
.se .se_game_image {
  z-index: 0;
  float: left;
  border-radius: 4px;
  overflow: hidden;
  width: 89px;
  margin-right: 10px;
}
.se .se_game_image img {
  width: 100%;
}
@media screen and (max-width: 768px) {
  .se .se_game_image {
    position: absolute;
    left: 50%;
    margin: 0 0 0 -45px;
    top: -133px;
  }
}
.se .se_title {
  position: relative;
  z-index: 2;
  text-align: left;
}
@media screen and (max-width: 768px) {
  .se .se_title {
    text-align: center;
  }
}
.se .se_title h4 {
  font-size: 24px;
  margin: 0;
}
.se .se_title small {
  font-size: 11px;
  opacity: .6;
}
@media screen and (max-width: 768px) {
  .se .se_title .btn-efantz.pull-right {
    float: none;
    display: block;
  }
}
.se .se_game {
  float: left;
  width: 100px;
}
.se h5 {
  text-align: center;
  font-weight: normal;
  display: none;
}
.se .starts {
  text-align: center;
  position: relative;
  z-index: 3;
}
/* VS Lockup New
---------------------------------*/
.vs {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  border-radius: 4px;
  padding: 10px;
  margin: 0 27px 10px 27px;
}
.vs .vs_team {
  flex: 10;
  align-self: center;
}
.vs .vs_logo {
  height: 80px;
  flex: 1;
  align-self: center;
}
.vs .vs_icon img {
  width: 100px;
}
.vs .vs_vs {
  margin: 20px;
  display: block;
  align-self: center;
  background: #fff;
  color: #000;
  width: 40px;
  height: 50px;
  box-shadow: inset 0px 0px 40px -7px rgba(0, 0, 0, 0.75);
  font-family: 'Intro', sans-serif;
  font-weight: 800;
  padding: 13px 0 0 0;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}
@media screen and (max-width: 768px) {
  .vs .vs_vs {
    box-shadow: none;
    clip-path: none;
    background: none;
    color: #fff;
  }
}
.vs h5 {
  display: inline;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 900;
  font-family: 'Intro', sans-serif;
  font-weight: 800;
  text-transform: uppercase;
}
@media screen and (max-width: 768px) {
  .vs h5 {
    display: none;
  }
}
/* Trek Admin
---------------------------------*/
.trek {
  margin: 10px 0 0 0;
  padding: 0 0 25px 0;
  border: 1px solid rgba(255, 255, 255, 0.1);
}
.free-answer {
  margin: 10px 0 0 0;
  padding: 25px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}
.wager-heading {
  background: rgba(30, 30, 30, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 25px;
  margin: 0 -12px 25px -13px;
}
.wager-heading strong {
  font-size: 18px;
}
.delete {
  cursor: pointer;
  background: #f00;
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 2px 10px;
  border-radius: 4px;
}
/* Events
---------------------------------*/
.contests-caro {
  border-radius: 4px;
}
.contests-caro .prev {
  position: absolute;
  display: block;
  top: 45%;
  left: 0;
  font-size: 40px;
  color: #11a5e4;
  z-index: 999;
  cursor: pointer;
  box-shadow: 0px -9px 39px -13px rgba(0, 0, 0, 0.75);
}
.contests-caro .next {
  position: absolute;
  display: block;
  top: 45%;
  right: 0;
  font-size: 40px;
  color: #11a5e4;
  z-index: 999;
  cursor: pointer;
  box-shadow: 0px -9px 39px -13px rgba(0, 0, 0, 0.75);
}
.contests-caro .slick-disabled {
  opacity: .2;
  color: #fff;
  cursor: default;
}
.event-listing {
  background: #1e1e1e;
  text-align: center;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  min-height: 320px;
  padding: 10px;
  margin: 0 12.5px 25px 12.5px;
}
.event-listing .btn-efantz {
  position: absolute;
  bottom: -15px;
  left: 0;
  border-radius: 0 0 4px 4px;
}
.event-listing .thumb {
  border-radius: 4px 4px 0 0;
  overflow: hidden;
  height: 150px;
  background: #000;
}
.event-listing .thumb img {
  height: 100%;
  margin: 0 auto 20px auto;
}
.event-listing .btn-bot {
  position: absolute;
  bottom: 0;
}
.event-listing .desc {
  margin-top: 20px;
}
.event-listing .desc h4 {
  font-family: 'Lato', Calibri, Arial, sans-serif;
  font-size: 1.5em;
  font-weight: 100;
}
.opensSoon {
  padding: 60px;
  background: #000;
  text-shadow: 0px 0px 2px #000000;
}
@media screen and (max-width: 768px) {
  .opensSoon {
    padding: 30px;
  }
}
.opensSoon h1 {
  font-size: 1.6rem;
  font-weight: 100;
}
@media screen and (max-width: 768px) {
  .opensSoon h1 {
    font-size: 16px;
  }
}
.opensSoon h1,
.opensSoon h2,
.opensSoon p {
  position: relative;
  z-index: 2;
}
@media screen and (max-width: 768px) {
  .opensSoon h2 {
    font-size: 20px;
  }
}
.current-event-video-bg {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: .5;
}
.event {
  position: relative;
  border-bottom: 1px solid #11a5e4;
  overflow: hidden;
}
.event .event-features {
  font-size: 10px;
}
.event h4 span {
  font-family: 'Lato', Calibri, Arial, sans-serif;
  font-size: 14px;
}
.event .team-desc {
  margin-top: 20px;
}
@media screen and (max-width: 768px) {
  .event .team-desc {
    max-width: 150px;
  }
  .event .team-desc h5 {
    font-size: 14px;
  }
}
.event .event-cost {
  border: 2px solid rgba(255, 0, 0, 0.5);
  border-radius: 10px;
  padding: 2px;
  font-family: 'Lato', Calibri, Arial, sans-serif;
  font-size: 12px;
  display: inline-block;
  margin: -10px 0 0 10px;
}
.event .event-type {
  border: 2px solid rgba(255, 255, 0, 0.5);
  border-radius: 10px;
  padding: 2px;
  font-family: 'Lato', Calibri, Arial, sans-serif;
  font-size: 12px;
  display: inline-block;
  margin: 5px 0 0 10px;
}
.event .event-heading {
  border-bottom: 1px solid #11a5e4;
  box-shadow: inset 0px -10px 40px -17px #000000;
  padding: 25px 25px 0 25px;
  background: #1e1e1e;
}
.event .event-heading h4 {
  margin: 0;
}
@media screen and (max-width: 768px) {
  .event .event-heading h4 {
    font-size: 16px;
  }
}
.event .event-heading p {
  margin: 0;
  padding: 0 0 25px 0;
}
.event .event-heading .small-timer {
  font-size: 11px;
  opacity: .7;
  margin-bottom: 10px;
}
.event .event-game {
  position: absolute;
  right: 0;
  top: -20px;
}
.event .event-game img {
  max-width: 150px;
}
.event .thumb {
  border-radius: 4px;
  overflow: hidden;
  padding: 0 0 25px 0;
}
.event .thumb img {
  width: 100%;
}
.event .desc {
  padding: 25px 0 25px 0;
}
.event .quick-play {
  background: #f00;
}
.event .gold-event {
  box-shadow: inset -1px 12px 22px -14px #000;
  background: #1e1e1e;
  padding: 20px;
}
@media screen and (max-width: 768px) {
  .event .gold-event {
    text-align: center;
  }
}
.event .gold-event .payouts {
  font-size: 12px;
  margin-bottom: 2px;
  color: rgba(255, 255, 255, 0.9);
}
.event .gold-event .result {
  border-radius: 4px;
  background: #FF0000;
  font-weight: bold;
  color: #fff;
  padding: 8px;
  padding: 10px;
  display: inline-block;
}
.event .gold-event .entered {
  margin-top: 25px;
  padding: 10px;
  text-shadow: -1px 0px 2px rgba(0, 0, 0, 0.26);
  box-shadow: inset -5px -15px 22px -14px #1e1e1e;
  border-radius: 4px;
  transition: all 400ms cubic-bezier(0.765, 0.225, 0, 0.845);
  background: #00FF00;
  color: #1e1e1e;
}
.event .gold-event .container-fluid {
  width: 100%;
  margin-top: 10px;
}
.event .gold-event .subtrek {
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 10px 0 0 0;
  font-size: 16px;
}
.event .gold-event .sub-trek {
  min-height: 50px;
}
.event .gold-event .flyout {
  display: block;
}
.event .gold-event h5 {
  font-size: 26px;
  font-weight: 300;
  padding: 0;
  margin: 10px 0 0 0;
  font-family: 'Lato', Calibri, Arial, sans-serif;
}
@media screen and (max-width: 768px) {
  .event .gold-event h5 {
    font-size: 20px;
    padding: 10px;
    text-align: center;
  }
}
.event .gold-event .question {
  padding: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}
.event .gold-event .question p {
  padding: 25px;
  margin: 0;
}
.event .gold-event .trek-result {
  padding: 25px;
  border: 1px solid #383838;
  text-align: center;
  border-radius: 4px;
  margin: 25px;
}
.event .gold-event .btn-efantz {
  margin: 0;
}
.event .match {
  background: #1e1e1e;
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
}
.event .match .lockup {
  position: relative;
  z-index: 10;
  padding: 10px;
  background: url('/img/vs.png') center center no-repeat #1e1e1e;
}
@media screen and (max-width: 768px) {
}
.event .match .toggle-match {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  color: #fff;
  font-size: 22px;
  text-align: center;
}
.event .match .toggle-match i {
  transition: all 400ms cubic-bezier(0.765, 0.225, 0, 0.845);
  transform: rotate(0deg);
}
.event .match .toggle-match.match-toggled i {
  transform: rotate(180deg);
}
.event .match .vs-lockup {
  height: 100%;
}
.event .match .vs {
  background: #11a5e4;
  text-align: center;
  padding: 20px 20px 15px 20px;
  border-radius: 50%;
  display: inline-block;
  margin-top: 52px;
  font-family: 'Intro', sans-serif;
  font-weight: 800;
}
.event .match .thumb {
  position: absolute;
  z-index: 0;
  pointer-events: none;
  top: 0;
  right: 0;
  width: 100%;
  opacity: .2;
  -webkit-mask-image: linear-gradient(to bottom, #000000 39%, rgba(0, 0, 0, 0) 100%);
}
.event .match .thumb img {
  width: 100%;
}
.event .match .thumb-left {
  position: absolute;
  z-index: 0;
  pointer-events: none;
  top: 0;
  right: 0;
  width: 100%;
  opacity: .2;
  -webkit-mask-image: linear-gradient(to bottom, #000000 39%, rgba(0, 0, 0, 0) 100%);
  display: none;
  right: auto;
  left: 0;
}
.event .match .thumb-left img {
  width: 100%;
}
.event .match .team {
  margin: 0;
  vertical-align: middle;
}
.event .match .team .team-logo {
  overflow: hidden;
  display: inline-block;
  width: 100px;
  min-width: 100px;
  padding: 10px;
  margin: 10px auto;
  border-radius: 4px;
}
.event .match .team .team-logo img {
  width: 100%;
}
.remark-lockup {
  font-size: 14px;
  margin-bottom: 20px;
}
.remark-lockup img {
  height: 100px;
}
.remkark-team-img {
  width: 100%;
}
.player-remark-frame {
  padding: 25px;
  background: #000;
  border-radius: 10px;
}
.enter-remark {
  border: 1px solid #2b2b2b;
  cursor: pointer;
  background: #383838;
  border-radius: 4px;
  overflow: hidden;
  transition: all 400ms cubic-bezier(0.765, 0.225, 0, 0.845);
  margin: 0 0 30px 0;
}
.enter-remark h3 {
  font-size: 18px;
  padding: 10px;
  background: #11a5e4;
  padding: 25px;
  margin: 0;
}
@media screen and (max-width: 768px) {
  .enter-remark h3 {
    font-size: 14px;
    padding: 10px;
  }
}
.enter-remark:hover {
  background: #11a5e4;
}
.enter-remark .remark-image {
  padding: 25px;
  border-radius: 4px;
  overflow: hidden;
  width: 100%;
  height: 180px;
}
.enter-remark .remark-image img {
  height: 100%;
}
@media screen and (max-width: 768px) {
  .enter-remark .remark-image {
    padding: 10px;
    height: auto;
  }
  .enter-remark .remark-image img {
    height: 50px;
  }
}
.triangle {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  width: 20px;
  height: 20px;
  /* replacing with more cross-browser compatible solution
  clip-path: polygon(0 0, 0 100%, 100% 0);
  background: @color-user; */
  border-style: solid;
  border-width: 10px;
  border-color: #11a5e4 transparent transparent #11a5e4;
}
.triangle-left {
  position: absolute;
  top: 0;
  left: -20;
  z-index: 99;
  width: 20px;
  height: 20px;
  /* replacing with more cross-browser compatible solution
  clip-path: polygon(100% 0, 22% 50%, 100% 100%);
  background: @color-user; */
  border-style: solid;
  border-width: 10px 16px 10px 4px;
  border-color: transparent #11a5e4 transparent transparent;
}
/* Video
---------------------------------*/
/* Video
---------------------------------*/
.video-bg {
  position: fixed;
  z-index: -1;
  top: 0;
  opacity: .8;
  left: 0;
  width: 100vw;
  overflow: hidden;
}
.video-bg.video-bw {
  opacity: .1;
}
.video-bg.video-bw video {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-mask-image: -webkit-gradient(linear, left 70%, left bottom, from(#000000), to(rgba(0, 0, 0, 0)));
}
.video-accent {
  position: fixed;
  z-index: -1;
  top: 0;
  opacity: .8;
  right: 0;
  height: 200px;
}
/* Buttons / general
---------------------------------*/
button {
  border: none;
  padding: 0.6em 1.2em;
  background: #11a5e4;
  color: #fff;
  font-size: 1em;
  text-transform: uppercase;
  cursor: pointer;
  display: inline-block;
  margin: 3px 2px;
  border-radius: 2px;
}
button:hover {
  background: #0d83b5;
}
.alert.alert-efantz {
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.3);
  box-shadow: inset 0px -9px 39px -13px rgba(0, 0, 0, 0.75);
  background: #1e1e1e;
}
.alert.alert-efantz h6 {
  color: rgba(255, 255, 255, 0.6);
}
.alert.alert-efantz strong {
  font-size: 16px;
  color: #11a5e4;
}
.alert.alert-efantz-loud {
  border: 2px solid rgba(255, 0, 0, 0.1);
  color: #fff;
  background: #f00;
  box-shadow: inset 0px -9px 39px -13px rgba(0, 0, 0, 0.75);
}
.alert.alert-efantz-loud h6 {
  color: rgba(255, 255, 255, 0.6);
  color: #fff;
}
.alert.alert-efantz-loud strong {
  font-size: 16px;
  color: #11a5e4;
}
.alert.alert-efantz-good {
  border: 2px solid rgba(0, 255, 0, 0.1);
  color: #000;
  background: #0f0;
  box-shadow: inset 0px -9px 39px -13px rgba(0, 0, 0, 0.75);
}
.alert.alert-efantz-good h6 {
  color: rgba(255, 255, 255, 0.6);
  color: #000;
}
.alert.alert-efantz-good strong {
  font-size: 16px;
  color: #11a5e4;
}
.img-rounded {
  overflow: hidden;
  height: 80px;
  padding: 5px 20px;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #11a5e4;
  margin-bottom: 10px;
  margin-top: -30px;
}
.img-rounded img {
  height: 100%;
}
.btn {
  border-radius: none;
}
.btn.btn-default {
  font-size: 10px;
  padding: 0;
  margin: 0;
  color: #ccc;
}
.btn.btn-canel {
  font-family: 'Intro', sans-serif;
  font-weight: 800;
  text-shadow: -1px 0px 2px rgba(0, 0, 0, 0.26);
  border-radius: 4px;
  transition: all 400ms cubic-bezier(0.765, 0.225, 0, 0.845);
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
}
.btn.btn-canel.muted {
  background: rgba(0, 0, 0, 0.1);
}
.btn.btn-canel:hover {
  color: #fff;
  background: #0d83b5;
}
.btn.btn-efantz {
  font-family: 'Intro', sans-serif;
  font-weight: 800;
  text-shadow: -1px 0px 2px rgba(0, 0, 0, 0.26);
  border-radius: 4px;
  transition: all 400ms cubic-bezier(0.765, 0.225, 0, 0.845);
  background: #11a5e4;
  color: #fff;
}
.btn.btn-efantz.muted {
  background: rgba(0, 0, 0, 0.1);
}
.btn.btn-efantz:hover {
  color: #fff;
  box-shadow: inset -5px -15px 22px -14px #1e1e1e;
  background: #0d83b5;
}
.btn.btn-efantz-lrg {
  font-size: 1.3em;
  font-family: 'Intro', sans-serif;
  font-weight: 800;
}
.btn.btn-tiny {
  position: absolute;
  background: #11a5e4;
  color: #fff;
  border-radius: 20px;
  top: 20px;
  right: 15px;
  font-size: 10px;
  padding: 5px 10px;
  z-index: 10;
}
.btn.btn-efantz-list {
  margin: 33px 8px 10px 10px;
  background: #11a5e4;
  text-shadow: -1px 0px 2px rgba(0, 0, 0, 0.26);
  color: #fff;
  padding: 4px 20px;
  transition: all 400ms cubic-bezier(0.765, 0.225, 0, 0.845);
  font-family: 'Intro', sans-serif;
  font-weight: 800;
  font-size: 1em;
}
.btn.btn-efantz-list:hover {
  background: #0a6085;
}
/* Main Content
---------------------------------*/
.ef-main-content {
  position: relative;
}
/* Panels
---------------------------------*/
.panel {
  background: none;
  box-sizing: border-box;
}
.panel-ef {
  position: relative;
  z-index: 0;
  box-sizing: border-box;
}
.panel-ef .panel-heading {
  padding: 12.5px;
  background: rgba(30, 30, 30, 0.9);
  border-bottom: 1px solid rgba(30, 30, 30, 0.1);
}
.panel-ef .panel-heading .panel-logo {
  width: 55px;
  float: left;
  margin-top: -10px;
  margin-bottom: -10px;
  padding: 0 10px 0 0;
}
.panel-ef .panel-heading h3 {
  margin: 0;
  padding: 0;
  font-size: 20px;
}
.panel-ef .panel-heading h4 {
  font-size: 1em;
  padding: 0;
  margin: 0;
  font-weight: 100;
}
.panel-ef .panel-heading p {
  padding: 0;
  margin: 10px 0 0 7px;
}
.panel-ef .panel-body {
  background: #1e1e1e;
  padding: 20px;
  border-radius: 2px;
  box-sizing: border-box;
  border: 1px solid rgba(255, 255, 255, 0.1);
}
.panel-ef .panel-body.ef-search-bar {
  padding: 10px 10px 0 10px;
}
.panel-ef .panel-body h3 {
  font-size: 1.2em;
  margin: 0;
  padding: 0 0 15px 0;
  color: rgba(255, 255, 255, 0.9);
}
@media screen and (max-width: 768px) {
  .panel-ef .panel-body h3 {
    font-size: 16px;
  }
}
.panel-ef .panel-body h4 {
  font-size: 1.1em;
  margin: 0 0 0 0;
  padding: 0;
  color: rgba(255, 255, 255, 0.9);
}
/* subPanels
----------------*/
.subpanel .subpanel-heading {
  padding: 25px;
  background: rgba(30, 30, 30, 0.1);
  position: relative;
  z-index: 1;
}
.subpanel .subpanel-heading h4 {
  float: left;
  font-size: 1.6em;
}
.subpanel .subpanel-heading p {
  float: left;
  padding: 0;
  margin: 6px 0 0 7px;
}
.subpanel .subpanel-body {
  position: relative;
  padding: 40px 0 0 0;
  z-index: 1;
  overflow: hidden;
  box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  background: #000;
}
/* Modals
---------------------------------*/
.modal-header,
.modal-body,
.modal-footer {
  background: #1e1e1e;
}
.modal-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.modal-footer {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.modal-header {
  padding: 0;
}
.modal-mask {
  position: fixed;
  z-index: 995;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: table;
  transition: opacity .3s ease;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-container {
  max-width: 75vw;
  margin: 0px auto;
  padding: 20px;
  background-color: #1e1e1e;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all .3s ease;
  font-family: Helvetica, Arial, sans-serif;
}
@media screen and (max-width: 768px) {
  .modal-container {
    text-align: center;
    max-width: 100vw;
    height: 100vh;
  }
}
.modal-header h3 {
  margin-top: 0;
  color: #11a5e4;
}
.modal-ef-body {
  height: 40vh;
  overflow: hidden;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 10px;
}
@media screen and (max-width: 768px) {
  .modal-ef-body {
    height: 50vh;
  }
}
.modal-default-button {
  float: right;
}
/*
 * the following styles are auto-applied to elements with
 * v-transition="modal" when their visiblity is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */
.modal-enter,
.modal-leave {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
/* Secondary Nav
---------------------------------*/
.navbar {
  background: rgba(0, 0, 0, 0.7);
  box-shadow: inset 0px 20px 14px -10px rgba(0, 0, 0, 0.75);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  padding: 5px 10px 1px;
  font-family: 'Intro', sans-serif;
  font-weight: 800;
}
.navbar li {
  padding: 0 5px;
}
.navbar .active {
  border-bottom: 4px solid #11a5e4;
}
/* Footer
---------------------------------*/
footer {
  background: #111111;
  border-top: 2px solid #1e1e1e;
  padding: 25px;
  z-index: 100;
  min-height: 140px;
  box-sizing: border-box;
  margin: 0 0 -20px 0;
  color: rgba(255, 255, 255, 0.5);
  width: 100%;
}
footer h3 {
  font-size: 20px;
  border-bottom: 1px solid #666;
}
footer .nav-stacked {
  margin: 0;
  padding: 0;
}
footer .nav-stacked li {
  padding: 5px 0 5px 0;
}
footer .nav-stacked li a {
  text-decoration: none;
}
footer a {
  color: rgba(255, 255, 255, 0.15);
  font-size: .80em;
}
.nav-pills {
  background: rgba(0, 0, 0, 0.8);
}
.nav-pills .nav-link {
  transition: all 400ms cubic-bezier(0.765, 0.225, 0, 0.845);
  border-radius: 0;
}
/* Sectional Menus / Headings
---------------------------------*/
.section-menu {
  width: 100%;
  font-family: 'Intro', sans-serif;
  font-weight: 800;
}
.section-menu .nav-pills {
  background: #1e1e1e;
  box-shadow: inset 0px -8px 17px -6px rgba(0, 0, 0, 0.44);
  border-radius: 0;
  margin: 0;
}
.section-menu .nav-pills .nav-item a {
  border-radius: 0;
}
.section-menu .nav-pills .nav-item a img {
  max-width: 55px;
}
.section-menu .dropdown-divider {
  height: 1px;
  background: rgba(255, 255, 255, 0.05);
}
.section-menu .search {
  position: relative;
  margin-right: 20px;
}
.section-menu .search input {
  background: rgba(30, 30, 30, 0.5);
  border-radius: 4px;
  font-family: 'Lato', Calibri, Arial, sans-serif;
  color: #fff;
  border: none;
  padding: 4px 20px 4px 4px;
}
.section-menu .search .search-button {
  display: block;
  text-decoration: none;
}
.section-menu .search .search-button > span {
  color: #fff;
}
.section-menu .section-heading {
  position: relative;
}
@media screen and (max-width: 768px) {
  .section-menu .section-heading {
    display: none;
  }
}
.section-menu .section-heading h1 {
  font-size: 2.4em;
  text-shadow: 0px -1px 3px rgba(0, 0, 0, 0.44);
  padding-bottom: 0 0 25px 0;
}
.section-menu .section-heading p {
  font-family: 'Lato', Calibri, Arial, sans-serif;
}
.text-right {
  text-align: right;
}
.nav-link.link-efantz {
  font-family: 'Intro', sans-serif;
  font-weight: 800;
  text-shadow: -1px 0px 2px rgba(0, 0, 0, 0.26);
  box-shadow: inset -5px -15px 22px -14px #1e1e1e;
  border-radius: 0;
  transition: all 400ms cubic-bezier(0.765, 0.225, 0, 0.845);
  color: #fff;
}
.nav-link.link-efantz.active {
  background: rgba(0, 0, 0, 0.5);
}
.nav-link.link-efantz:hover {
  color: #fff;
  background: #0d83b5;
}
/* Navs
---------------------------------*/
.nav {
  position: relative;
  z-index: 2;
  padding: 5px 5px;
  background: rgba(30, 30, 30, 0.75);
  border-radius: 4px;
}
.nav .open > a {
  background: #11a5e4;
}
.nav .open > a:focus {
  background: #11a5e4;
}
.nav li a {
  font-family: 'Lato', Calibri, Arial, sans-serif;
  padding: 5px 15px;
}
.nav li a:hover {
  background: #11a5e4;
}
.nav li a:active {
  background: #11a5e4;
}
.nav li a:focus {
  background: #11a5e4;
}
.nav li .dropdown-menu {
  background: #1e1e1e;
}
.nav li .dropdown-menu a {
  display: block;
  text-decoration: none;
}
.nav .btn {
  padding: 5px;
  letter-spacing: auto;
}
.nav a {
  color: #fff;
}
/* Activities
---------------------------------*/
.activity-list {
  padding: 0 0 100px 0;
}
.activity-list .no-activities {
  padding: 20px;
  text-align: center;
}
.activity-recent {
  overflow: hidden;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}
.activity-recent h3 {
  font-size: 1em;
}
.activity-recent span {
  color: #808080;
}
.activity {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid #1e1e1e;
  padding: 2px;
  font-size: 14px;
  text-align: left;
  position: relative;
  overflow: hidden;
}
.activity .activity-action {
  position: relative;
  z-index: 10;
  box-shadow: inset 0px -9px 39px -13px rgba(0, 0, 0, 0.75);
  padding: 25px;
  color: #fff;
  display: block;
  text-align: center;
}
.activity .activity-action:hover {
  text-decoration: none;
  background: rgba(17, 165, 228, 0.7);
}
.activity .activity-action .activity-icon {
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #ccc;
  width: 64px;
  margin: 0 auto 10px auto;
}
.activity h5 {
  font-size: 11px;
  font-family: 'Lato', Calibri, Arial, sans-serif;
  color: rgba(255, 255, 255, 0.5);
}
.activity p {
  margin: 0;
  padding: 0;
}
.activity .activity-close {
  position: absolute;
  z-index: 12;
  top: 0;
  right: 0;
  color: #fff;
  padding: 0 6px;
  cursor: pointer;
}
.activity-count {
  color: #11a5e4;
  background: #fff;
  font-weight: bold;
  text-align: center;
  position: absolute;
  animation: fadein 2s;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  top: 4px;
  right: 4px;
  padding: 1px 0;
  font-size: 8px;
}
/* Dropzone
---------------------------------*/
.dropzone {
  border: 2px solid #11a5e4;
  background: #1e1e1e;
}
.dropzone .dz-preview.dz-image-preview {
  background: #1e1e1e;
}
/* Profile Headings
---------------------------------*/
.profile-games {
  display: block;
  padding-bottom: 25px;
}
.profile-games img {
  width: 100%;
}
.profile-image {
  border: 1px solid #1e1e1e;
  margin-right: 25px;
  border-radius: 4px;
  display: inline-block;
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: relative;
  background: #000;
}
.profile-image .btn-efantz {
  position: absolute;
  bottom: 0;
  font-weight: 100;
  font-size: 12px;
  left: 0;
  z-index: 1;
  border-radius: 0;
  padding: 0;
  margin: 0;
}
.profile-image img {
  width: 100%;
}
.nav {
  text-shadow: 1px 1px 1px #000000;
  padding: 25px;
}
.nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.nav ul li {
  float: left;
  padding: 0 25px 0 0;
}
.nav h1 {
  font-size: 4em;
}
.nav h2 {
  font-family: 'Lato', Calibri, Arial, sans-serif;
}
.nav p {
  font-size: 1.2em;
}
.gamertag {
  padding: 25px;
  text-align: center;
}
.gamertag h5 {
  font-weight: 400;
  font-size: 12px;
}
.gamertag img {
  overflow: hidden;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 10px;
}
#loader img {
  max-width: 384px;
  width: 100%;
}
/* Page Headings / Promos
---------------------------------*/
.page-img {
  height: 70px;
  float: left;
  margin: 0 10px 0 0;
}
.page-img-sm {
  height: 60px;
  float: left;
  margin: -10px 10px 0 0;
}
.page-heading {
  margin: 0 0 0 0;
  z-index: 0;
  position: relative;
  overflow: hidden;
}
.page-heading .col-md-12 {
  margin: 0;
  padding: 0;
}
.page-heading .now-showing {
  position: absolute;
  bottom: 0;
  right: 10px;
  font-size: 11px;
  opacity: .5;
}
.page-heading .page-heading-interior {
  box-sizing: border-box;
  padding: 20px 0;
}
.page-heading .page-heading-interior h1 {
  font-size: 2.5em;
  margin: 0;
  padding: 0;
}
.page-heading .page-heading-interior p {
  margin: 0;
  padding: 0;
}
.page-heading .page-heading-interior.home-interior {
  padding: 25px 25px 0 25px;
}
@media screen and (max-width: 1200px) {
  .page-heading .page-heading-interior {
    padding: 40px 0 20px 0;
  }
}
@media screen and (max-width: 768px) {
  .page-heading .page-heading-interior {
    text-align: center;
  }
  .page-heading .page-heading-interior h1 {
    display: block;
    font-size: 1.2em;
    padding: 0;
  }
  .page-heading .page-heading-interior p {
    margin: 0;
    padding: 0;
    font-size: .8em;
    max-width: 100%;
  }
  .page-heading .page-heading-interior .page-img {
    margin: 10px auto;
    float: none;
    display: block;
  }
}
.page-heading .page-heading-interior-full {
  box-sizing: border-box;
  padding-top: 40px;
}
.page-heading h1 {
  font-size: 3em;
}
.page-heading h2 {
  font-family: 'Lato', Calibri, Arial, sans-serif;
}
.page-heading p {
  font-size: 1em;
  padding: 0;
  margin: 0;
}
.page-heading .video-bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: auto;
  z-index: -1;
}
.profile-heading {
  text-shadow: 1px 1px 1px #000000;
  padding: 200px 25px 25px 25px;
}
.profile-heading ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.profile-heading ul li {
  float: left;
  padding: 0 25px 0 0;
}
.profile-heading h1 {
  font-size: 4em;
}
.profile-heading h2 {
  font-family: 'Lato', Calibri, Arial, sans-serif;
}
.profile-heading p {
  font-size: 1.2em;
}
.promo-img {
  padding-top: 25px;
  text-align: center;
}
.promo-img img {
  width: 100%;
}
/* Breadcrumbs
---------------------------------*/
.breadcrumbs {
  padding: 5px 0 5px 0;
  margin-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.breadcrumbs .breadcrumb {
  padding: 0;
  margin: 0;
  background: none;
}
.breadcrumbs .breadcrumb a {
  color: rgba(255, 255, 255, 0.5);
  font-size: .7em;
}
/* EFANTZ SVG Icons
---------------------------------*/
.ef-icon.ef-icon-small {
  width: 50px;
  margin: -10px 5px -10px -25px;
}
.ef-icon.ef-icon-content {
  width: 50px;
  margin-right: 5px;
}
.ef-icon.ef-icon-inline {
  height: 100%;
  height: 20px;
}
.ef-icon.ef-icon-activity {
  height: 100%;
  height: 20px;
  position: absolute;
  top: 15px;
  left: 97px;
}
.ef-icon.ef-icon-bg {
  height: 300px;
  position: absolute;
  bottom: -100px;
  right: -100px;
  z-index: 0;
}
.ef-icon.ef-icon-big {
  height: 40px;
}
.ef-icon.ef-icon-huge {
  width: 100%;
}
/* Home v2
---------------------------------*/
.ef-show .ef-slide {
  background: #1e1e1e;
  position: relative;
  box-shadow: inset 0px 0px 42px -6px rgba(0, 0, 0, 0.75);
  padding: 20px 20px 40px 20px;
  margin: 15px 0;
  border-radius: 4px;
  overflow: hidden;
}
.ef-show .ef-slide.no-base {
  padding: 20px 20px 0 20px;
}
.ef-show .ef-slide .see_all {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 10px 20px 10px 40px;
  color: #fff;
  font-size: 12px;
  -webkit-clip-path: polygon(39% 0, 100% 0, 100% 100%, 0% 100%);
  clip-path: polygon(39% 0, 100% 0, 100% 100%, 0% 100%);
  text-decoration: none;
  display: block;
  box-shadow: inset 0px 0px 42px -6px rgba(0, 0, 0, 0.75);
  transition: all 400ms cubic-bezier(0.765, 0.225, 0, 0.845);
}
.ef-show .ef-slide .see_all:hover {
  background: #11a5e4;
}
.ef-show .ef-slide .ef-slide-desc {
  box-sizing: border-box;
}
.ef-show .ef-slide .ef-slide-desc .ef-slide-section {
  position: absolute;
  top: 10px;
  right: 10px;
  opacity: .2;
  width: 120px;
  -webkit-filter: grayscale(100%);
  /* Chrome, Safari, Opera */
  filter: grayscale(100%);
}
.ef-show .ef-slide .ef-slide-desc .ef-slide-section img {
  width: 100%;
}
.ef-show .ef-slide .ef-slide-desc h1 {
  font-size: 3rem;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
  line-height: 3rem;
}
.ef-show .ef-slide .ef-slide-desc h2 {
  font-weight: 400;
  font-size: 22px;
  padding: 0;
  display: block;
}
.ef-show .ef-slide .ef-slide-desc h2 span {
  font-size: 14px;
  margin-left: 10px;
  font-weight: 100;
  display: inline-block;
  font-family: 'Lato', Calibri, Arial, sans-serif;
}
.ef-show .ef-slide .ef-slide-desc h3 {
  text-transform: uppercase;
}
.ef-show .ef-slide .ef-slide-desc p {
  padding: 0;
  margin: 0 0 20px 0;
  max-width: 700px;
  font-size: 14px;
  color: #fff;
}
.ef-show .ef-slide .ef-slide-desc .ef-slide-item {
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  color: #fff;
  margin: 0 0 20px 0;
  transition: all 400ms cubic-bezier(0.765, 0.225, 0, 0.845);
}
.ef-show .ef-slide .ef-slide-desc .ef-slide-item h4 {
  font-weight: 400;
  font-size: 16px;
  padding: 0;
  margin: 0;
}
.ef-show .ef-slide .ef-slide-desc .ef-slide-item h4 span {
  font-size: 12px;
  margin-left: 10px;
}
.ef-show .ef-slide .ef-slide-desc .ef-slide-item .ef-slide-item-img {
  height: 190px;
  overflow: hidden;
}
.ef-show .ef-slide .ef-slide-desc .ef-slide-item .ef-slide-item-img img {
  width: 100%;
}
.ef-show .ef-slide .ef-slide-desc .ef-slide-item .top-right {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.8);
}
.ef-show .ef-slide .ef-slide-desc .ef-slide-item .top-left {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 2;
}
.ef-show .ef-slide .ef-slide-desc .ef-slide-item .top-left img {
  width: 50px;
}
.ef-show .ef-slide .ef-slide-desc .ef-slide-item .bottom-left {
  position: absolute;
  bottom: 40px;
  left: 0;
  z-index: 2;
}
.ef-show .ef-slide .ef-slide-desc .ef-slide-item .bottom-left img {
  width: 75px;
}
.ef-show .ef-slide .ef-slide-desc .ef-slide-item .item-bottom {
  position: absolute;
  padding: 10px;
  bottom: 0px;
  left: 0px;
  width: 100%;
  background: #302e2e;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.4) 0%, #000000 100%);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.4) 0%, #000000 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4) 0%, #000000 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#302e2e', endColorstr='@dark', GradientType=0);
}
.ef-show .ef-slide .ef-slide-desc .ef-slide-item .item-bottom h4 {
  font-family: 'Lato', Calibri, Arial, sans-serif;
}
.ef-show .ef-slide .ef-slide-desc .ef-slide-item:hover .item-bottom {
  background: #000000;
  background: -moz-linear-gradient(top, #000000 0%, rgba(0, 0, 0, 0.4) 100%);
  background: -webkit-linear-gradient(top, #000000 0%, rgba(0, 0, 0, 0.4) 100%);
  background: linear-gradient(to bottom, #000000 0%, rgba(0, 0, 0, 0.4) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='@dark', endColorstr='#302e2e', GradientType=0);
}
/* Live Page
---------------------------------*/
.ef-page-live .row {
  padding: 0;
  margin: 0;
}
.ef-page-live .row [class*="col-"] {
  padding: 0;
}
.ef-page-live .row iframe#twitch-chat {
  width: 100%;
  height: 100vh;
}
/* EFANTZ SVG Icons
---------------------------------*/
.news-item {
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 4px 4px 0 0;
  background: #1e1e1e;
}
.news-item .posted-on {
  font-size: 14px;
  font-weight: normal;
  font-family: 'Lato', Calibri, Arial, sans-serif;
}
.news-item .news-image {
  height: 230px;
  overflow: hidden;
}
.news-item .news-image img {
  width: 100%;
  border-radius: 4px 4px 0 0;
}
.news-item .news-item-content {
  padding: 25px;
}
/* Mini-Profile
---------------------------------*/
.mini-profile {
  padding: 5px 0 0 0;
}
.mini-profile h5 {
  font-size: 14px;
  font-weight: bold;
  font-family: 'Lato', Calibri, Arial, sans-serif;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
.mini-profile p {
  font-size: 12px;
}
.mini-profile .icon-users {
  height: 32px;
  margin: 5px 5px 0 0;
}
/* Data Tables
---------------------------------*/
table.dataTable thead .sorting_desc:after {
  display: none;
  content: '';
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table th,
.table td {
  border-top: 1px solid rgba(255, 255, 255, 0.05);
}
.ef-data-table {
  padding: 0;
  border-radius: 4px;
  background: #1e1e1e;
  border: none;
}
.ef-data-table .table {
  padding: 0;
  margin: 0;
  background: linear-gradient(to right, #1A1A1A, black 100%);
}
.ef-data-table .table tr {
  color: #fff;
}
.ef-data-table .table tr:hover {
  background: #1e1e1e;
}
.ef-data-table .table tr th {
  border: none;
  background: #1e1e1e;
}
.ef-data-table .table tr td {
  background: none;
  border-bottom: 1px solid #1e1e1e;
  color: #fff;
}
.dataTables_wrapper .dataTables_paginate .paginate_button {
  padding: 0;
  margin: 0;
}
.dataTables_wrapper .dataTables_paginate a {
  background: #1e1e1e;
  color: #11a5e4;
  border: none;
}
.dataTables_wrapper .dataTables_paginate li.active a {
  color: #fff;
}
.dataTables_wrapper select,
.dataTables_wrapper input {
  background: #1e1e1e;
  border: 1px solid #11a5e4;
  border-radius: 4px;
}
div.dataTables_processing {
  background: url('/img/ajax-loader.gif') top center no-repeat rgba(30, 30, 30, 0.9) !important;
}
/* Responsive
---------------------------------*/
/* Players
---------------------*/
.player {
  height: 100px;
  position: relative;
  overflow: hidden;
  clear: both;
  margin-bottom: 1px;
  background-color: #000;
}
.player .thumb img {
  width: 100%;
  margin: 15px;
  border: 2px solid #fff;
}
.player .team-logo img {
  margin: 15px;
  padding: 5px;
  width: 50%;
}
.player .add-btn {
  width: -200px;
  height: 100px;
  background: #11a5e4;
  margin-left: 30px;
}
.player .add-btn h4 {
  display: inline-block;
  margin: 44px 0 0 14px ;
  font-size: 20px;
  position: relative;
  z-index: 1;
}
.player .add-btn:before {
  width: 200px;
  height: 100px;
  display: block;
  position: absolute;
  top: 0px;
  left: 30px;
  background-color: #11a5e4;
  content: '';
  -webkit-clip-path: polygon(20% 0%, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(20% 0%, 100% 0, 100% 100%, 0 100%);
  margin: 0 0 0 -30px;
}
.player .desc {
  padding-top: 15px;
}
.player .desc h4 {
  padding: 0;
  margin: 0;
  float: left;
}
.player .desc .position {
  margin: 0 0 0 10px;
  float: left;
  font-size: .5em;
  padding: 3px 10px;
}
.player .desc .position.adc {
  border: 1px solid #F00;
}
dl.stats {
  float: left;
  margin: 0 25px 25px 0;
  padding: 0;
}
dl.stats dt {
  margin: 0;
  padding: 0;
  font-weight: normal;
  text-align: center;
}
dl.stats dt .wins {
  color: #0f0;
}
dl.stats dt .assist {
  color: #ff0;
}
dl.stats dt .loss {
  color: #f00;
}
dl.stats dt:first-child {
  font-weight: bold;
}
.position-container {
  background: #000;
  padding: 25px;
}
.position-container .position {
  height: 100px;
  padding: 25px;
  margin: 5px;
  display: table;
  text-align: center;
  width: 100%;
}
.position-container .position.top {
  border: dotted 1px #c0f;
}
.position-container .position.jungle {
  border: dotted 1px #3c3;
}
.position-container .position.mid {
  border: dotted 1px #00f;
}
.position-container .position.adc {
  border: dotted 1px #f00;
}
.position-container .position.support {
  border: dotted 1px #fc3;
}
.position-container .position h3 {
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}
.position-container .position h3.top {
  color: #c0f;
}
.position-container .position h3.jungle {
  color: #3c3;
}
.position-container .position h3.mid {
  color: #00f;
}
.position-container .position h3.adc {
  color: #f00;
}
.position-container .position h3.support {
  color: #fc3;
}
.toast {
  background: #000;
  position: relative;
  padding: 20px;
}
.toast .row {
  margin-left: 20px;
  border: solid 1px #11a5e4;
}
.toast .row col-md-1 {
  padding: 0;
}
.toast .row .thumb img {
  padding: 0;
  margin: 15px 15px 15px 0;
  max-width: 100px;
  max-height: 100px;
}
.toast .desc {
  padding-top: 40px;
  margin-left: 40px;
}
.toast .triangle-left {
  float: inherit;
  margin-top: 70px;
}
.notification-bubble {
  height: 25px;
  width: 25px;
  position: absolute;
  z-index: 1;
  text-align: center;
  font-weight: bold;
  border-radius: 50%;
  background-color: #11a5e4;
}
.notification-bubble p {
  text-align: center;
  padding-top: 3px;
}
.user-games h3 {
  padding: 5px;
}
.user-games .list-group .list-group-item {
  background-color: #000;
  color: #11a5e4;
  text-align: center;
  font-size: 1.2em;
}
.user-games .list-group .list-group-item:hover {
  background-color: #666;
  color: #fff;
}
.user-games .game-id {
  border-radius: 4px;
  padding: 0 20% 0 0;
}
.user-games .game-id .input-group-lg {
  width: 100%;
}
.user-games .game-id .input-group-lg .form-control {
  text-align: center;
  height: 180px;
  border-radius: 5px;
  font-size: 2.25em;
}
.user-games .game-id .attn {
  color: #f00;
  font-weight: bold;
}
.user-games .game-id .qwer {
  text-align: center;
}
.user-games .game-id .qwer .save-btn {
  text-align: center;
  width: 40%;
  margin: 0 auto;
  border-radius: 5px;
  background-color: #11a5e4;
}
.user-games .game-id .qwer .save-btn :hover {
  color: #0f0;
}
.user-games .game-id .qwer .save-btn h3 {
  padding: 5px;
}
/* Fantasy
---------------------------------*/
#fantasy .roster-position.selected {
  border-color: #11a5e4;
}
#fantasy .players-table tr {
  min-height: 74px;
}
#fantasy .players-table td {
  padding: 5px;
  vertical-align: middle;
}
#fantasy .players-table td:first-child {
  padding-left: 0;
}
#fantasy .players-table td:last-child {
  padding-right: 0;
}
#fantasy .players-table td.image {
  width: 74px;
}
#fantasy .players-table td.image img {
  width: 64px;
}
#fantasy .players-table td.info {
  padding-left: 20px;
}
#fantasy .players-table td.buttons {
  width: 64px;
  vertical-align: middle;
}
/* User profile live stream wrapper
---------------------*/
.live-stream-wrapper {
  width: 100%;
  padding-top: 60%;
  position: relative;
}
.live-stream-inner-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.check-icon {
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
}
.selected {
  position: relative;
}
.selected.enter-remark {
  background: #11a5e4;
}
.selected.game {
  background: #11a5e4;
}
.selected.ef-big-select {
  background: #11a5e4;
}
.selected .check-icon {
  display: block;
}
/* css for v-cloak directive to prevent vue.js FOUC */
[v-cloak] {
  display: none;
}
/* Pagination for backend admin panel grids.
-------------------*/
ul.pagination {
  list-style: none;
  padding: 0;
  display: inline-block;
}
ul.pagination li {
  float: left;
  margin: 0;
  padding: 0;
  margin: 0 5px;
}
ul.pagination li a {
  padding: 2px 10px;
  background: #11a5e4;
  color: #fff;
  border-radius: 4px;
}
.nav-pills .nav-link.active,
.nav-pills .nav-link.active:focus,
.nav-pills .nav-link.active:hover,
.nav-pills .nav-item.open .nav-link,
.nav-pills .nav-item.open .nav-link:focus,
.nav-pills .nav-item.open .nav-link:hover {
  background-color: #000;
}
a {
  color: #11a5e4;
}
a:hover,
a:active {
  color: #0d83b5;
}
.btn-primary,
.btn-primary:hover,
.nav a,
.nav-pills .nav-link.active,
.nav-pills .nav-link.active:focus,
.nav-pills .nav-link.active:hover,
.nav-pills .nav-item.open .nav-link,
.nav-pills .nav-item.open .nav-link:focus,
.nav-pills .nav-item.open .nav-link:hover {
  color: #fff !important;
}
.page-item.active .page-link,
.page-item.active .page-link:focus,
.page-item.active .page-link:hover {
  color: #fff !important;
  background-color: #222222 !important;
  border-color: #222222 !important;
}
.card {
  background-color: #0a0a0a;
  border-color: #222222;
}
.text-primary {
  color: #11a5e4 !important;
}
.btn {
  outline: 0 !important;
}
.btn-primary {
  background-color: #000;
  border-color: #11a5e4;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary:active:hover,
.btn-primary:active:focus,
.btn-primary:disabled:hover,
.btn-primary:disabled:focus {
  background-color: #0d83b5;
  border-color: #0d7cab;
}
.btn-primary-outline {
  border-color: #11a5e4;
  color: #11a5e4;
}
.btn-primary-outline:hover,
.btn-primary-outline:focus,
.btn-primary-outline:active,
.btn-primary-outline:active:focus {
  background-color: #000;
  border-color: #11a5e4;
  color: #fff;
}
.label-primary {
  background-color: #11a5e4;
}
.nav-pills .nav-link.active,
.nav-pills .nav-link.active:focus,
.nav-pills .nav-link.active:hover,
.nav-pills .nav-item.open .nav-link,
.nav-pills .nav-item.open .nav-link:focus,
.nav-pills .nav-item.open .nav-link:hover {
  background-color: #000;
}
.nav-pills .nav-link.active:hover,
.nav-pills .nav-link.active:focus:hover,
.nav-pills .nav-link.active:hover:hover,
.nav-pills .nav-item.open .nav-link:hover,
.nav-pills .nav-item.open .nav-link:focus:hover,
.nav-pills .nav-item.open .nav-link:hover:hover {
  background-color: #0d83b5;
}
.page-item.active .page-link,
.page-item.active .page-link:focus,
.page-item.active .page-link:hover {
  background-color: #11a5e4;
  border-color: #11a5e4;
}
.page-link {
  color: #fff !important;
  background-color: #222222;
  border-color: #222222;
}
.page-link:hover,
.page-link:focus {
  background-color: #303030;
  border-color: #303030;
}
.navbar {
  box-shadow: none;
  background-color: rgba(22, 22, 22, 0.75);
  padding: 0.5rem 1rem;
  border-bottom-color: rgba(255, 255, 255, 0.15);
}
.navbar li {
  padding: 0;
}
.nav {
  padding: 0;
  background: transparent;
}
.navbar-light .navbar-brand,
.navbar-light .navbar-nav .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-nav .nav-item + .nav-item {
  margin-left: 0;
}
hr {
  border-color: rgba(255, 255, 255, 0.3);
}
.tab-pane {
  padding: 0;
}
.form-group {
  margin-bottom: 1rem !important;
}
@media (min-width: 544px) {
  .form-inline .form-group {
    margin-bottom: 0 !important;
  }
}
.input-group-addon {
  background-color: #222222;
  color: #fff;
}
.input-group-btn button {
  margin: 0;
}
.form-control,
.input-group-addon {
  border-color: #464545;
}
button {
  margin: 0;
}
.modal {
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-body {
  margin: 0;
  overflow: visible;
  max-height: none;
}
/* Brackets
-------------------*/
.hover {
  background: #11a5e4;
}
.ef-fullscreen-controls {
  display: none;
  cursor: pointer;
}
.ef-brackets-tab.fullscreen {
  position: fixed;
  top: 0;
  left: 60px;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
}
.ef-brackets-tab.fullscreen .card {
  width: 100vw;
  height: 100vh;
}
.card {
  background: #1b1b1b;
  border-radius: 4px;
  box-shadow: inset 0px -9px 23px 2px rgba(0, 0, 0, 0.2);
}
h1.brackets-title {
  font-size: 2em;
  position: absolute;
  bottom: 20px;
  left: 40px;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.53);
}
.brackets-bg-image {
  overflow: hidden;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  opacity: .2;
}
.brackets-bg-image img {
  width: 100%;
  border-radius: 4px;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-mask-image: -webkit-gradient(linear, left 70%, left bottom, from(#000000), to(rgba(0, 0, 0, 0)));
}
.brackets-image {
  overflow: hidden;
  max-height: 500px;
  width: 100%;
  border-radius: 4px;
  box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.75);
}
.brackets-image img {
  width: 100%;
}
.brackets-participants .participants-current {
  font-size: 14px;
  font-family: 'Intro', sans-serif;
  font-weight: 800;
  font-weight: 100;
  display: inline;
}
.brackets-participants .participants-max {
  font-size: 16px;
  font-family: 'Intro', sans-serif;
  font-weight: 800;
  font-weight: 100;
  display: inline;
}
.brackets-participants .participants-slash {
  font-size: 1em;
  color: #11a5e4;
}
@media screen and (max-width: 768px) {
  .brackets-participants {
    margin: 0 0 10px 0;
  }
}
.ef-content .container {
  width: 100%;
  position: relative;
}
.ef-content .ef-loader-bg {
  position: fixed;
  left: 300px;
}
.ef-brackets-dashboard-content > .container.content {
  margin-left: 0;
}
.ef-brackets-dashboard-content {
  /* Adjusting brackets style */
}
.ef-brackets-dashboard-content .ef-brackets-container .match-controls-container .number {
  background-color: #0a0a0a;
}
.ef-brackets-dashboard-content #ef-match-dialog h4 {
  margin-bottom: 10px;
}
.ef-brackets-dashboard-content #ef-match-dialog .btn:not(.btn-primary) {
  background-color: #464545;
}
.modal-backdrop {
  z-index: 9;
}
.logo-container {
  text-align: center;
  padding: 25px 20px 25px 0;
}
.logo-container img {
  max-width: 55%;
}
.ef-brackets-content-box {
  padding: 50px 0;
}
.ef-brackets-content-box .container {
  box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.2);
  padding-top: 40px;
  padding-bottom: 30px;
}
.ef-space {
  clear: both;
}
.ef-space.min {
  height: 1px;
}
.ef-space.xs {
  padding-top: 5px;
}
.ef-space.sm {
  padding-top: 10px;
}
.ef-space.md {
  padding-top: 20px;
}
.ef-space.lg {
  padding-top: 30px;
}
.ef-space.xl {
  padding-top: 50px;
}
.indented {
  padding-left: 30px;
}
.expand-transition {
  transition: max-height 0.3s ease, margin 0.3s ease;
  overflow: hidden;
  max-height: 999px;
}
.expand-enter,
.expand-leave {
  max-height: 0 !important;
  margin: 0 !important;
  border-bottom-width: 0 !important;
}
.ef-loader-bg {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.8);
}
.ef-loader-bg.visible {
  display: block;
}
.ef-loader-bg .ef-loader {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 300px;
  height: 200px;
  line-height: 200px;
  margin-left: -150px;
  margin-top: -100px;
  background-color: #000;
  border: 1px solid #222222;
  border-radius: 2px;
  text-align: center;
}
.ef-panel {
  padding: 25px 20px;
  border-radius: 4px;
  background-color: #f0f0f0;
}
.ef-fullwidth {
  display: block;
  width: 100%;
}
.ef-nowrap {
  white-space: nowrap;
}
.ui-draggable-helper {
  border: 1px dotted #000;
  background: #000;
  color: #fff;
  font-size: 11px;
  z-index: 9999999;
  width: 100px;
  height: 100px;
  overflow: hidden;
}
.ui-draggable-helper img {
  width: 100%;
}
.ui-draggable-helper .name {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100px;
}
.ui-draggable-helper .check-in-icon {
  display: none;
}
.ef-brackets-container {
  position: relative;
  width: 100%;
}
.ef-brackets-container .ef-zoom-slider {
  background: #444;
  border: 0;
}
.ef-brackets-container .ef-zoom-slider .ui-slider-handle {
  outline: 0;
  background: #888;
  border: 0;
  top: -0.2em;
}
.ef-brackets-container .ef-brackets-scroll-wrapper {
  width: 100%;
  overflow-x: auto;
}
.ef-brackets-container .ef-brackets-zoom-wrapper {
  -webkit-transform-origin: left top;
  -moz-transform-origin: left top;
  -ms-transform-origin: left top;
  transform-origin: left top;
}
.ef-brackets-container .player-prototype {
  display: none;
}
.ef-brackets-container .match-controls-container {
  position: absolute;
  width: 315px;
  height: 60px;
}
.ef-brackets-container .match-controls-container.prototype {
  display: none;
}
.ef-brackets-container .match-controls-container.disputed .number {
  background-color: #f00;
}
.ef-brackets-container .match-controls-container .lines {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 11;
}
.ef-brackets-container .match-controls-container .line {
  border-color: #777;
  border-style: solid;
  position: absolute;
}
.ef-brackets-container .match-controls-container .line.top {
  border-width: 2px 2px 0 0;
}
.ef-brackets-container .match-controls-container .line.bottom {
  border-width: 0 2px 2px 0;
}
.ef-brackets-container .match-controls-container .line-into {
  border-width: 2px 0 0;
  width: 50px;
  left: -50px;
  top: 50%;
  margin-top: -1px;
}
.ef-brackets-container .match-controls-container .number {
  position: absolute;
  padding: 0 5px;
  right: 100%;
  top: 32%;
  background: #fff;
  z-index: 12;
}
.ef-brackets-container .match-controls-container .score {
  position: absolute;
  width: 45px;
  height: 30px;
  line-height: 30px;
  left: 175px;
  text-align: center;
  z-index: 30;
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}
.ef-brackets-container .match-controls-container .score.player1 {
  top: 0;
}
.ef-brackets-container .match-controls-container .score.player2 {
  top: 30px;
}
.ef-brackets-container .match-controls-container .score.winner {
  background-color: #11a5e4;
}
.ef-brackets-container .match-controls-container .match-play-button {
  display: block;
  position: absolute;
  left: 175px;
  top: 0;
  width: 45px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 1.2em;
  color: #fff;
  background: #333;
  z-index: 31;
}
.ef-brackets-container .match-controls-container .button-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
}
.ef-brackets-container .match-controls-container .button-container:hover .match-button,
.ef-brackets-container .match-controls-container .button-container:hover .status-button {
  visibility: visible;
}
.ef-brackets-container .match-controls-container .status-button {
  display: block;
  position: absolute;
  right: 59px;
  top: 0;
  width: 35px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 1.2em;
  color: #fff;
  background-color: rgba(51, 51, 51, 0.85);
  visibility: hidden;
}
.ef-brackets-container .match-controls-container .status-button .paused {
  display: none;
}
.ef-brackets-container .match-controls-container .status-button:hover {
  background-color: rgba(51, 51, 51, 0.9);
}
.ef-brackets-container .match-controls-container .status-button.paused .paused {
  display: block;
}
.ef-brackets-container .match-controls-container .status-button.paused .play {
  display: none;
}
.ef-brackets-container .match-controls-container .match-button {
  display: block;
  position: absolute;
  right: -2px;
  top: 0;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 1.4em;
  color: #fff;
  background-color: rgba(51, 51, 51, 0.85);
  visibility: hidden;
}
.ef-brackets-container .match-controls-container .match-button:hover {
  background-color: rgba(51, 51, 51, 0.9);
}
.ef-brackets-container .match-controls-container.played .match-play-button {
  display: none;
}
.ef-brackets-container .ef-brackets-area {
  position: relative;
}
.ef-brackets-container .ef-brackets-area .player {
  width: 220px;
  height: 30px;
  line-height: 28px;
  font-size: 0.9em;
  padding: 0 10px 0 30px;
  position: absolute;
  background: #333;
  color: #fff;
  box-sizing: border-box;
  z-index: 25;
}
.ef-brackets-container .ef-brackets-area .player.first {
  border-bottom: 1px solid #fff;
}
.ef-brackets-container .ef-brackets-area .player:hover.real-player,
.ef-brackets-container .ef-brackets-area .player.hover.real-player {
  background-color: #666;
  cursor: grab;
}
.ef-brackets-container .ef-brackets-area .player.hover-drop {
  background-color: #0a6085;
}
.ef-brackets-container .ef-brackets-area .player:hover + .player + .match-controls-container .status-button,
.ef-brackets-container .ef-brackets-area .player:hover + .match-controls-container .status-button,
.ef-brackets-container .ef-brackets-area .player:hover + .player + .match-controls-container .match-button,
.ef-brackets-container .ef-brackets-area .player:hover + .match-controls-container .match-button {
  visibility: visible;
}
.ef-brackets-container .ef-brackets-area .player.ui-draggable-dragging {
  border-bottom: 0;
}
.ef-brackets-container .ef-brackets-area .player.with-seed-number {
  padding-left: 57px;
}
.ef-brackets-container .ef-brackets-area .player.with-seed-number .avatar {
  left: 27px;
}
.ef-brackets-container .ef-brackets-area .player.real-player .avatar {
  display: block;
}
.ef-brackets-container .ef-brackets-area .player.real-player .check-in-icon {
  display: block;
}
.ef-brackets-container .ef-brackets-area .player.disqualified .name {
  color: #11a5e4;
  text-decoration: line-through;
}
.ef-brackets-container .ef-brackets-area .player.disqualified .name em {
  color: #fff;
}
.ef-brackets-container .ef-brackets-area .player .avatar {
  position: absolute;
  display: none;
  left: 0;
  width: 25px;
  height: 100%;
  overflow: hidden;
}
.ef-brackets-container .ef-brackets-area .player .avatar .avatar-img {
  position: absolute;
  left: 50%;
  top: 0;
  width: auto;
  height: 100%;
  transform: translate3d(-50%, 0, 0);
}
.ef-brackets-container .ef-brackets-area .player .check-in-icon {
  color: #fff;
  position: absolute;
  right: 45px;
  top: 0;
  width: 22px;
  height: 100%;
  text-align: center;
  opacity: 0.5;
  display: none;
}
.ef-brackets-container .ef-brackets-area .player .check-in-icon.enabled {
  opacity: 1;
}
.ef-brackets-container .ef-brackets-area .player .name {
  display: block;
  margin-right: 40px;
  overflow: hidden;
  pointer-events: none;
}
.ef-brackets-container .ef-brackets-area .player .name > em {
  display: block;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 27px;
  height: 30px;
  line-height: 30px;
  background: #4d4d4d;
}
.ef-brackets-container .ef-brackets-sep {
  width: 100%;
  height: 1px;
  border-top: 1px solid #ccc;
}
#ef-match-dialog table td {
  vertical-align: middle;
}
#ef-match-dialog .vs-table {
  width: 100%;
}
#ef-match-dialog .vs-table td {
  text-align: center;
}
#ef-match-dialog .vs-table .vs-cell {
  padding: 0 15px;
}
#ef-match-dialog .match-result-buttons button {
  outline: 0;
}
#ef-match-dialog .match-score-table td.score {
  width: 100px;
}
.hidden {
  display: none !important;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.ef-tournament-signup-banner {
  display: block;
  width: auto;
  max-width: 100%;
  margin-bottom: 30px;
}
.ef-tournaments .tournament {
  margin-bottom: 30px;
}
.ef-tournament-banner,
.ef-block-image {
  display: block;
  max-width: 100%;
}
.ef-reported-scores-table td:not(:last-child) {
  padding-right: 30px;
}
#participants-content .players-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: relative;
  font: inherit;
}
#participants-content .players-list li {
  background: inherit;
  color: inherit;
  font: inherit;
  border: 0;
  margin: 0;
  height: 50px;
}
#participants-content .players-list li.dragged {
  position: absolute;
  z-index: 10;
}
#participants-content .players-list li .card-block {
  padding: 7px 15px;
}
#participants-content .players-list .panel {
  margin-bottom: 10px;
}
#participants-content .players-list .panel .panel-body {
  padding: 8px 15px;
}
#participants-content .players-list .playername {
  display: block;
  margin-right: 100px;
}
#participants-content .players-list .player-edit-ctrls {
  float: right;
  max-width: 90px;
}
#discussion-content #prototype {
  display: none;
}
#discussion-content .ef-comment {
  min-height: 75px;
  position: relative;
}
#discussion-content .ef-comment .ef-comment-avatar {
  position: absolute;
  width: 75px;
  height: 75px;
  left: 0;
  top: 0;
  overflow: hidden;
}
#discussion-content .ef-comment .ef-comment-avatar img {
  width: 100%;
}
#discussion-content .ef-comment .ef-comment-body {
  margin-left: 100px;
}
#discussion-content .ef-comment .ef-comment-titlebar {
  width: 100%;
}
#settings-content .ef-settings-adv-options {
  margin-bottom: 10px;
}
#settings-content [transition] {
  display: none;
}
/*
 * LESS for responsiveness in various parts of the site
 */
.ef-top-title {
  margin-right: 56px;
  position: relative;
}
.ef-top-submenu {
  padding-top: 0;
  padding-right: 0;
  opacity: 0.99;
  z-index: 99;
}
@media screen and (max-width: 870px) {
  .ef-top-submenu {
    position: absolute;
    top: 80px;
    margin-right: 25px;
    visibility: hidden;
    opacity: 0;
    z-index: 9999;
    -webkit-transition: visibility 0.3s, opacity 0.3s;
    -moz-transition: visibility 0.3s, opacity 0.3s;
    transition: visibility 0.3s, opacity 0.3s;
  }
  .ef-top-submenu.open {
    visibility: visible;
    opacity: 1;
  }
  .ef-top-submenu .nav {
    border: 1px solid rgba(255, 255, 255, 0.25);
  }
  .ef-top-submenu li.nav-item {
    display: block;
    float: none;
    margin-left: 0 !important;
  }
  .ef-top-submenu li.nav-item .nav-link {
    min-width: 200px;
    border-radius: 0 !important;
  }
  .ef-top-submenu li.nav-item .nav-link:not(:hover):not(.active) {
    background: transparent !important;
  }
  .ef-top-submenu li.nav-item .dropdown-menu {
    border: 1px solid rgba(255, 255, 255, 0.25);
  }
}
.ef-top-submenu-toggle {
  display: none;
  width: 40px;
  height: 40px;
  margin: 25px 15px 25px 0;
  position: relative;
}
@media screen and (max-width: 870px) {
  .ef-top-submenu-toggle {
    display: block !important;
  }
}
.ef-top-submenu-toggle span {
  display: block;
  position: absolute;
  width: 40px;
  height: 4px;
  background: #11a5e4;
  left: 0;
  border-radius: 3px;
  -webkit-transform-origin: center center;
  -moz-transform-origin: center center;
  transform-origin: center center;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
}
.ef-top-submenu-toggle span:first-child {
  top: 5px;
}
.ef-top-submenu-toggle span:last-child {
  bottom: 5px;
}
.ef-top-submenu-toggle span:not(:first-child):not(:last-child) {
  top: 50%;
  margin-top: -2px;
}
.ef-top-submenu-toggle[aria-expanded="true"] span:first-child {
  transform: rotate(45deg) translate3d(9px, 9px, 0);
}
.ef-top-submenu-toggle[aria-expanded="true"] span:not(:first-child):not(:last-child) {
  opacity: 0;
}
.ef-top-submenu-toggle[aria-expanded="true"] span:last-child {
  transform: rotate(-45deg) translate3d(9px, -10px, 0);
}
.ef-search-bar .row > * {
  margin-bottom: 10px;
}
@media screen and (max-width: 700px) {
  .tournament-url-input-group {
    margin-bottom: 45px;
  }
  .tournament-url-input-group input[type="text"] {
    border-top-right-radius: 4px;
    border-top-bottom-radius: 4px;
  }
  .tournament-url-input-group .input-group-btn {
    display: block;
    position: absolute;
    left: 0;
    top: 110%;
    width: 100%;
    text-align: center;
  }
  .tournament-url-input-group .input-group-btn .btn {
    border-radius: 4px;
    width: 100%;
  }
}
.tour-header h1 {
  font-size: 10rem;
  text-shadow: 0px 0px 2px rgba(255, 255, 255, 0.07);
}
