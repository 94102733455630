.logo-container {
  text-align: center;
  padding: 25px 20px 25px 0;
  img {
    max-width: 55%;
  }
}

.ef-brackets-content-box {
  padding: 50px 0;
  .container {
    box-shadow: 0 1px 4px 1px rgba(0,0,0,0.2);
    padding-top: 40px;
    padding-bottom: 30px;
  }
}

.ef-space {
  clear: both;
  &.min {
    height: 1px;
  }
  &.xs {
    padding-top: 5px;
  }
  &.sm {
    padding-top: 10px;
  }
  &.md {
    padding-top: 20px;
  }
  &.lg {
    padding-top: 30px;
  }
  &.xl {
    padding-top: 50px;
  }
}

.indented {
  padding-left: 30px;
}

.expand {
  &-transition {
    transition: max-height .3s ease, margin .3s ease;
    overflow: hidden;
    max-height: 999px;
  }
  &-enter,
  &-leave {
    max-height: 0 !important;
    margin: 0 !important;
    border-bottom-width: 0 !important;
  }
}

.ef-loader-bg {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background-color: fade(@gray-base, 80%);
  &.visible {
    display: block;
  }
  .ef-loader {
    position: fixed;
    left: 50%;
    top: 50%;
    width: 300px;
    height: 200px;
    line-height: 200px;
    margin-left: -150px;
    margin-top: -100px;
    background-color: @gray-base;
    border: 1px solid @gray-darker;
    border-radius: 2px;
    text-align: center;
  }
}

.ef-panel {
  padding: 25px 20px;
  border-radius: 4px;
  background-color: #f0f0f0;
}

.ef-fullwidth {
  display: block;
  width: 100%;
}

.ef-nowrap {
  white-space: nowrap;
}

.ui-draggable-helper {
 border: 1px dotted #000;
 background: #000;
 color: #fff;
 font-size: 11px;
 z-index: 9999999;
 width: 100px;
 height: 100px;
 overflow: hidden;
 img {
   width: 100%;
 }
 .name {
   position: absolute;
   bottom: 0;
   left: 0;
   width: 100px;
 }
 .check-in-icon {
   display: none;
 }
}

.ef-brackets-container {
  @player-width: 220px;
  @player-height: 30px;
  @player-gap: 0px;
  @match-gap: 30px;
  @round-gap: 100px;
  @bracket-color: #333;
  position: relative;
  width: 100%;
  .ef-zoom-slider {
    background: #444;
    border: 0;
    .ui-slider-handle {
      outline: 0;
      background: #888;
      border: 0;
      top: -.2em;
    }
  }
  .ef-brackets-scroll-wrapper {
    width: 100%;
    overflow-x: auto;
  }
  .ef-brackets-zoom-wrapper {
    -webkit-transform-origin: left top;
    -moz-transform-origin: left top;
    -ms-transform-origin: left top;
    transform-origin: left top;
  }
  .player-prototype {
    display: none;
  }
  .match-controls-container {
    @size: @player-height * 2 + @player-gap;
    @score-width: 45px;
    @status-button-width: 35px;
    position: absolute;
    width: @player-width + @size + @status-button-width;
    height: @size;
    &.prototype {
      display: none;
    }
    &.disputed {
      .number {
        background-color: #f00;
      }
    }
    .lines {
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 11;
    }
    .line {
      border-color: #777;
      border-style: solid;
      position: absolute;
      &.top {
        border-width: 2px 2px 0 0;
      }
      &.bottom {
        border-width: 0 2px 2px 0;
      }
    }
    .line-into {
      border-width: 2px 0 0;
      width: @round-gap / 2;
      left: -@round-gap / 2;
      top: 50%;
      margin-top: -1px;
    }
    .number {
      position: absolute;
      padding: 0 5px;
      right: 100%;
      top: 32%;
      background: #fff;
      z-index: 12;
    }
    .score {
      position: absolute;
      width: @score-width;
      height: @player-height;
      line-height: @player-height;
      left: @player-width - @score-width;
      text-align: center;
      z-index: 30;
      background-color: rgba(255,255,255,0.1);
      color: #fff;
      &.player1 {
        top: 0;
      }
      &.player2 {
        top: @player-height;
      }
      &.winner {
        background-color: @color-user;
      }
    }
    .match-play-button {
      display: block;
      position: absolute;
      left: @player-width - @score-width;
      top: 0;
      width: @score-width;
      height: @size;
      line-height: @player-height * 2;
      text-align: center;
      font-size: 1.2em;
      color: #fff;
      background: @bracket-color;
      z-index: 31;
    }
    .button-container {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 20;
      &:hover {
        .match-button,
        .status-button {
          visibility: visible;
        }
      }
    }
    .status-button {
      display: block;
      position: absolute;
      right: @size - 1px;
      top: 0;
      width: @status-button-width;
      height: @size;
      line-height: @size;
      text-align: center;
      font-size: 1.2em;
      color: #fff;
      background-color: fade(@bracket-color, 85%);
      visibility: hidden;
      .paused {
        display: none;
      }
      &:hover {
        background-color: fade(@bracket-color, 90%);
      }
      &.paused {
        .paused {
          display: block;
        }
        .play {
          display: none;
        }
      }
    }
    .match-button {
      display: block;
      position: absolute;
      right: -2px;
      top: 0;
      width: @size;
      height: @size;
      line-height: @size;
      text-align: center;
      font-size: 1.4em;
      color: #fff;
      background-color: fade(@bracket-color, 85%);
      visibility: hidden;
      &:hover {
        background-color: fade(@bracket-color, 90%);
      }
    }
    &.played {
      .match-play-button {
        display: none;
      }
    }
  }
  .ef-brackets-area {
    @seed-num-size: 27px;
    @check-in-icon-width: 22px;
    @avatar-width: 25px;
    position: relative;
    .player {
      width: @player-width;
      height: @player-height;
      line-height: @player-height - 2px;
      font-size: 0.9em;
      padding: 0 10px 0 @avatar-width + 5px;
      position: absolute;
      background: @bracket-color;
      color: #fff;
      box-sizing: border-box;
      z-index: 25;
      &.first {
        border-bottom: 1px solid #fff;
      }
      &:hover,
      &.hover {
        &.real-player {
          background-color: #666;
          cursor: grab;
        }
      }
      &.hover-drop {
        background-color: darken(@color-user, 20%);
      }
      &:hover {
        & + .player + .match-controls-container,
        & + .match-controls-container {
          .status-button,
          .match-button {
            visibility: visible;
          }
        }
      }
      &.ui-draggable-dragging {
        border-bottom: 0;
      }
      &.with-seed-number {
        padding-left: @seed-num-size + @avatar-width + 5px;
        .avatar {
          left: @seed-num-size;
        }
      }
      &.real-player {
        .avatar {
          display: block;
        }
        .check-in-icon {
          display: block;
        }
      }
      &.disqualified {
        .name {
          color: @color-user;
          text-decoration: line-through;
          em {
            color: #fff;
          }
        }
      }
      .avatar {
        position: absolute;
        display: none;
        left: 0;
        width: @avatar-width;
        height: 100%;
        overflow: hidden;
        .avatar-img {
          position: absolute;
          left: 50%;
          top: 0;
          width: auto;
          height: 100%;
          transform: translate3d(-50%, 0, 0);
        }
      }
      .check-in-icon {
        color: #fff;
        position: absolute;
        right: 45px;
        top: 0;
        width: @check-in-icon-width;
        height: 100%;
        text-align: center;
        opacity: 0.5;
        display: none;
        &.enabled {
          opacity: 1;
        }
      }
      .name {
        display: block;
        margin-right: 40px;
        overflow: hidden;
        pointer-events: none;
        //cursor: grabbing;
        & > em {
          display: block;
          text-align: center;
          position: absolute;
          left: 0;
          top: 0;
          width: @seed-num-size;
          height: @player-height;
          line-height: @player-height;
          background: lighten(@bracket-color, 10%);
        }
      }
    }
  }
  .ef-brackets-sep {
    width: 100%;
    height: 1px;
    border-top: 1px solid #ccc;
  }
}

#ef-match-dialog {
  table {
    td {
      vertical-align: middle;
    }
  }
  .vs-table {
    width: 100%;
    td {
      text-align: center;
    }
    .vs-cell {
      padding: 0 15px;
    }
  }
  .match-result-buttons {
    button {
      outline: 0;
    }
  }
  .match-score-table {
    td.score {
      width: 100px;
    }
  }
}

.hidden {
  display: none !important;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.ef-tournament-signup-banner {
  display: block;
  width: auto;
  max-width: 100%;
  margin-bottom: 30px;
}

.ef-tournaments {
  .tournament {
    margin-bottom: 30px;
  }
}

.ef-tournament-banner,
.ef-block-image {
  display: block;
  max-width: 100%;
}

.ef-reported-scores-table {
  td:not(:last-child) {
    padding-right: 30px;
  }
}
