//@import "../bower/bootstrap/less/bootstrap";
//@import "shared/variables";
//@import url(https://fonts.googleapis.com/css?family=Raleway:900,300);
@import 'https://fonts.googleapis.com/css?family=Righteous';

/* Variables
---------------------------------*/

@sidebar-width:230px;
@sidebar-outer-width:60px;
@chat-width: 230px;
@main-menu-height: 50px;

@content-padding: 25px;

@clg-height: 100px;

@sidemenu-chat-overlay-width: 1199px; // when screen width is equal or less than this width,
                                      // left side menu and chat will overlay main content instead of
                                      // pushing it

/* Redefined
---------------------------------*/
html, body {
  overflow-x: hidden;
}

::selection {
  background: @color-user;
}
::-moz-selection {
  background: @color-user;
}

a:link, a:visited {
  color: @color-user;
}

a:active, a:hover {
  color: fade(@color-user, 70%);
}

.row {
  //padding: 0;
  //margin: 0;
}

[class*="col-"] {
    //border: 1px solid fade(@color-user, 30%);
}

.nav-pills .nav-link.active, .nav-pills .nav-link.active:focus, .nav-pills .nav-link.active:hover, .nav-pills .nav-item.open .nav-link, .nav-pills .nav-item.open .nav-link:focus, .nav-pills .nav-item.open .nav-link:hover {
  //background-color: @color-user;
}

.form-group {
  margin-bottom: @content-padding;
  h5 {
    background: fade(@contrast-dark, 10%);
    border-radius: 4px;
    padding: 10px;
    .base-font;
    font-size: 16px;
    font-weight: bold;
  }
}

.form-section {
  padding: 0 20px 20px 20px;
}

.col-xs-15{
    width:20%;
}

input[type=range] {
  -webkit-appearance: none;
  margin: 18px 0;
  width: 100%;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 1px 1px 1px @dark, 0px 0px 1px #0d0d0d;
  background: @color-user;
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 1px 1px 1px @dark, 0px 0px 1px #0d0d0d;
  border: 1px solid @dark;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: @light;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -17px;
  position: relative;
  cursor: pointer;
  width: 35px;
  height: 35px;
  line-height: 140px;
  background: #25292C;
  text-align: center;
  border-radius: 999px;
  color: rgba(155,155,155,1);
  text-shadow: -1px -1px 0px rgba(255,255,255,0.8), 1px 1px 2px rgba(0,0,0,1);
  font-size: 16px;
  font-family: sans-serif;
  box-shadow: inset 1px 1px 2px rgba(255,255,255,0.3),
              inset 3px 15px 45px rgba(255,255,255,0.1),
              inset -1px -1px 2px rgba(0,0,0,0.5),
              inset -3px -15px 45px rgba(0,0,0,0.2),
              1px 5px 30px -4px rgba(0,0,0,1);
  -webkit-transition: 0.1s ease-out;
  -moz-transition:    0.1s ease-out;
  -o-transition:      0.1s ease-out;
  transition:         0.1s ease-out;
  content:"&times;";
  &:before {
    content: "";
    background: #1A1A1A;
    width: 170px;
    height: 170px;
    position: absolute;
    top: -15px;
    left: -15px;
    z-index: -1;
    border-radius: 999px;
    box-shadow: inset -1px -1px 2px rgba(255,255,255,0.3),
      inset -5px -15px 40px rgba(255,255,255,0.1),
      inset 1px 1px 2px rgba(0,0,0,0.5),
      inset 5px 15px 40px rgba(0,0,0,0.2),
      -2px -40px 50px -20px rgba(255,255,255,0.1),
      2px 35px 50px -10px rgba(0,0,0,0.4),
      0px 0px 25px 8px rgba(60,60,60,1);
  }
}















input[type=range]:focus::-webkit-slider-runnable-track {
  background: @color-user;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 1px 1px 1px @dark, 0px 0px 1px #0d0d0d;
  background: @color-user;
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}
input[type=range]::-moz-range-thumb {
  box-shadow: 1px 1px 1px @dark, 0px 0px 1px #0d0d0d;
  border: 1px solid @dark;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: @light;
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: @color-user;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px @dark, 0px 0px 1px #0d0d0d;
}
input[type=range]::-ms-fill-upper {
  background: @color-user;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px @dark, 0px 0px 1px #0d0d0d;
}
input[type=range]::-ms-thumb {
  box-shadow: 1px 1px 1px @dark, 0px 0px 1px #0d0d0d;
  border: 1px solid @dark;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: @light;
  cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
  background: @color-user;
}
input[type=range]:focus::-ms-fill-upper {
  background: @color-user;
}

.typed-cursor{
    opacity: 1;
    -webkit-animation: blink 0.7s infinite;
    -moz-animation: blink 0.7s infinite;
    animation: blink 0.7s infinite;
}
@keyframes blink{
    0% { opacity:1; }
    50% { opacity:0; }
    100% { opacity:1; }
}
@-webkit-keyframes blink{
    0% { opacity:1; }
    50% { opacity:0; }
    100% { opacity:1; }
}
@-moz-keyframes blink{
    0% { opacity:1; }
    50% { opacity:0; }
    100% { opacity:1; }
}

.tab-content {
  //background-color: @color-user;
  //background: linear-gradient(to bottom,  @color-user 0%,rgba(0,0,0,0) 100%);
  //padding: 5px 0;
  //border-radius: 4px;
  //margin-bottom: 10px;
}

.magictime {
  -webkit-animation-duration: .5s;
  -moz-animation-duration: .5s;
  -o-animation-duration: .5s;
  animation-duration: .5s;
}

.nanobar{
  width:100%;
  height:4px;
  z-index:9999;
  top:0px;
}

.bar{
  width:0;
  height:100%;
  transition:height .3s;
  background:@color-user;
}

/* Break Points
------------- */
@break-xsmall: 480px;
@break-small: 768px;
@break-medium: 992px;
@break-large: 1200px;

@media screen and (max-width : @break-medium){
}

/* Fonts
---------------------------------*/
@font-face {
    font-family: 'Intro';
    src:url('/fonts/Intro.ttf.woff') format('woff'),
        url('/fonts/Intro.ttf.svg#Intro') format('svg'),
        url('/fonts/Intro.ttf.eot'),
        url('/fonts/Intro.ttf.eot?#iefix') format('embedded-opentype');
    font-weight: normal;
    font-style: normal;
}

.deco-font {
  //font-family: 'Intro';
  //font-family: 'Raleway', sans-serif;
  font-family: 'Intro', sans-serif;
  font-weight: 800;
}

.sup-font {
  font-family: 'Press Start 2P', cursive;
}

h1, h2, h3, h4, h5 {
  .deco-font;
}

.base-font {
  font-family: 'Lato', Calibri, Arial, sans-serif;
}

.enormo {
  .deco-font;
  font-size: 10em;
}

/* Scrollbars
---------------------------------*/
&::-webkit-scrollbar {
	height: 1px;
	width: 4px;
}
&::-webkit-scrollbar-button {
	height: 0px;
	width: 0px;
}
&::-webkit-scrollbar-thumb {
	background: lighten(@color-user, 10%);
	border: 0px none @color-user;
}
&::-webkit-scrollbar-thumb:hover {
	background: lighten(@color-user, 10%);
}
&::-webkit-scrollbar-thumb:active {
	background: @dark;
}
&::-webkit-scrollbar-track {
	background: #1a1a1a;
	border: 0px none lighten(@color-user, 10%);
}
&::-webkit-scrollbar-track:hover {
	background: fade(lighten(@color-user, 10%), 5%);
}
&::-webkit-scrollbar-track:active {
	background: lighten(@color-user, 10%);
}
&::-webkit-scrollbar-corner {
	background: transparent;
}


/* Text
---------------------------------*/
body {
  font-family: 'Lato', Calibri, Arial, sans-serif;
  font-weight: 300;
  background: @dark;
  color: @light;
}

/* Animation
---------------------------------*/
.transition-cubic {
  transition: all 400ms cubic-bezier(0.765, 0.225, 0.000, 0.845);
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@keyframes animatedBackground {
	from { background-position: 0 0; }
	to { background-position: 100% 0; }
}


@-webkit-keyframes glow {
  from {
    box-shadow: 0 0 0px rgba(255,255,255,.4);
  }
	to {
    box-shadow: 0 0 30px @color-user;
  }
}

.glow-box {
    animation: glow 1.0s infinite alternate;
}


/* Styles
---------------------------------*/
.inner-shadow {
  box-shadow: inset 0px 5px 15px -6px fade(@dark, 75%);
}

.hexagon {
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}

.visible {
    opacity: 1;
}

/* Vue Transitions
---------------------------------*/

//Friends

.friendRequests-transition {
  .transition-cubic;
  opacity: 1;
  height: 100px;
  overflow: hidden;
}

.friendRequests-enter, .friendRequests-leave {
  opacity: 0;
  height: 0;
}

.friends-transition {
  .transition-cubic;
  opacity: 1;
  min-height: 200px;
  overflow: hidden;
}

.friends-enter, .friends-leave {
  opacity: 0;
  height: 0;
}

// General

.gen-transition {
  .transition-cubic;
  opacity: 1;
  //overflow: hidden;
  //max-height: 1000px;
}

.gen-enter, .gen-leave {
  opacity: 0;
  //max-height: 0;
}

//Preloader
.preloader, .notice-entered {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background: fade(@dark, 70%);
  padding-top: 20vh;
  text-align: center;
  //display: block;
  opacity: 0;
  transition: all .3s ease;
  pointer-events: none;
  img {
    width: 384px;
    height: 216px;
    margin: 0 auto;
  }
}


/* Steam Id
---------------------------------*/
.steam-id {
  text-align: center;
}



/* Joyride Redefined
---------------------------------*/
.joyride-tip-guide .joyride-next-tip {
  background: @color-user;
  border: none;
  &:hover {
    border: none;
  }
}


/* Acticity Alerts
---------------------------------*/
.activity-alert {
  position: fixed;
  top: -500px;
  left: 70px;
  border: 2px solid @color-user;
  padding: 10px;
  background: @contrast-light;
  z-index: 99999;
  -webkit-box-shadow: 0px 0px 29px -1px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 29px -1px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 29px -1px rgba(0,0,0,0.75);
  h3 {
    font-size: 16px;
    .base-font;
    padding: 0;
    margin: 0;
  }
  p {
    padding: 0;
    margin: 0;
  }
  &:before {
    content: ' ';
    position: absolute;
    top: 20px;
    left: -20px;
    width: 20px;
    height: 20px;
    background: @color-user;
    -webkit-clip-path: polygon(100% 0, 0 50%, 100% 100%);
    clip-path: polygon(100% 0, 0 50%, 100% 100%);
  }
}

/* Leaderboards
---------------------------------*/
.leader {
  color: @light;
  //padding: 10px;
  margin: 10px 0;
  box-sizing: border-box;
  //border: 1px dashed rgba(255,255,255,.2);
  //border-radius: 4px;
  background: fade(@dark, 20%);
  h4 {
    font-size: 24px;
    font-weight: 900;
    margin: 0 0 10px 0;
    padding: 0;
    span {
      font-size: 11px;
    }
  }
  .leader_rank {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 50px;
  }
  &:hover {
    background: @dark;
  }
}
.ldr-profile-image {
  border-radius: 4px;
  overflow: hidden;
  width: 75px;
  height: 75px;
  border: 2px solid @contrast-light;
  margin: 10px;
  display: inline-block;
  background: @dark;
  img {
    height: 75px;
  }
}

/* Leaderboard Top
---------------------------------*/
.leaderboard-top {
  clear: both;
  background: @contrast-light;
  border-radius: 0 0 4px 4px;
  border: 1px solid fade(@contrast-light, 40%);
  position: relative;
  h3 {
    .base-font;
    font-weight: normal;
    font-size: 14px;
    opacity: .5;
    padding: 20px @content-padding 0 @content-padding;
  }
  h4 {
    background: @color-user;
    padding: 5px @content-padding;
    span {
      font-size: 12px;
      .base-font;
    }
  }
  h5 {
    text-align: center;
    margin-bottom: 20px;
    .base-font;
  }
  p {
    padding: 5px @content-padding;
  }
  .leaderboard-top-rank {
    position: absolute;
    top: 38px;
    right: 11px;
    width: 40px;
  }
  .leaderboard-top-img {
    width: 100px;
    height: 100px;
    margin: @content-padding;
    float: right;
    img {
      width: 100%;
    }
  }
}


/* Gold Packages
---------------------------------*/
.jp-card .jp-card-front, .jp-card .jp-card-back {
  background: @dark;
}
.gold-package {
  background: @dark;
  border: 1px solid @color-user;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: @content-padding;
  position: relative;
  .price {
    border-radius: 0 0 0 4px;
    background: @color-user;
    padding: 5px;
    position: absolute;
    top: 0;
    right: 0;
  }
  &:hover {
    background: @color-user;
    cursor: pointer;
  }
  &.gold-selected {
    background: @color-user;
  }
}
#coin-total {

}
.total {
  border-bottom: 1px solid @color-user;
  padding: 20px;
}

.modal-dialog {
  margin: 100px auto;
}

/* Promos
---------------------------------*/
.promo {
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  //background: @dark;
  width: 100%;
  min-height: 170px;
  height: 100%;
  .promo-img-main {
    width: 100%;
    height: 100%;
    //position: fixed;
    //top: 0;
    //left: 0;
    z-index: -1;
    @media screen and (max-width : @break-small){
      width: 150%;
      margin-left: -20%;
    }
    img {
      width: 100%;
    }
  }
  .btn-go {
    background: url('/img/go.png') top left;
    background-size: 100%;
    height: 100px;
    width: 100px;
    padding: 51px 14px 11px 59px;
    .deco-font;
    position: absolute;
    z-index: 99;
    bottom: 0;
    right: -100px;
  }
  .subtitle {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 11px;
    font-weight: 100;
    background: @dark;
    padding: 4px 6px;
    border-radius: 4px;
    z-index: 2;
  }
  .normal-item {
    padding: 20px;
    h2 {
      font-size: 16px;
      margin: 0;
    }
    h3 {
      font-size: 12px;
      font-weight: 100;
      .base-font;
      margin: 0;
    }
  }

  .cover {
    display: block;
    margin: 0;
    padding: 30px;
    box-sizing: border-box;
    text-decoration: none;
    color: @contrast-dark;
    text-shadow: 0px 0px 2px @dark;
    background: linear-gradient(to bottom,  fade(@dark, 0%) 0%,fade(@dark, 100%) 100%);
    position: absolute;
    bottom: 0;
    z-index: 2;
    width: 100%;
    .img-game-promo {
      max-width: 200px;
      display: block;
    }
    h2 {
      font-size: 24px;
      background: @color-user;
      padding: 5px;
      margin: 0;
      display: inline-block;
      clear: both;
    }
    h3 {
      font-size: 18px;
      font-weight: 100;
      .base-font;
      background: @dark;
      margin: 0;
      padding: 5px;
      display: inline-block;
      clear: both;
    }
    @media screen and (max-width : @break-small){
      h2 {
        font-size: 14px;
        background: @color-user;
        padding: 5px;
        display: inline-block;
        clear: both;
      }
      h3 {
        font-size: 12px;
        font-weight: 100;
        .base-font;
        background: @dark;
        margin: 0;
        padding: 5px;
        display: inline-block;
        clear: both;
      }
      p {
        font-size: 11px;
        display: none;
      }
      .subtitle {
        display: none;
      }
    }
  }
}

/* Rank
---------------------------------*/
progress[value]::-webkit-progress-bar {
  background-image: linear-gradient(to right, rgba(76,76,76,1) 0%, rgba(76,76,76,1) 100%,);
  border-radius: 2px;
}

progress[value]::-webkit-progress-value {
  background-image:
	   -webkit-linear-gradient(-45deg,
	                           transparent 33%, rgba(0, 0, 0, .1) 33%,
	                           fade(@dark, 10%) 66%, transparent 66%),
	   -webkit-linear-gradient(top,
	                           rgba(0, 0, 0, .25),
	                           rgba(0, 0, 0, .25)),
	   -webkit-linear-gradient(left, @color-user, spin(@color-user, 180));
    border-radius: 2px;
    background-size: 1px 20px, 100% 100%, 100% 100%;
}

progress:not([value]) {
	background: @dark;
}

progress[value] {
	appearance: none;
	border: none;
	width: 100%; height: 10px;
  border-radius: 3px;
  box-shadow: 0 2px 3px fade(@dark, 50%) inset;
  background-image: none;
  background-color: @color-user;
  background: @color-user;
  color: @color-user;
}
























#user_rank {
  img {
    width: 125px;
    display: block;
    margin: 0 auto;
  }
  text-align: center;
}

.rank {
  opacity: 0;
  filter: grayscale(100%);
  .transition-cubic;
  text-align: center;
  display: none;
}

.rank-done {
  display: block;
  opacity: .3;
  filter: grayscale(0);
}

#current-rank {
  .transition-cubic;
  display: block;
  opacity: 1;
  filter: grayscale(0%);
}

.dropdown-rank {
  padding: 10px;
  color: @contrast-dark;
  //width: 300px;
}


/* Challenger
---------------------------------*/
.slidetwo-container {
  //https://codepen.io/bbodine1/pen/novBm
  .slideTwo {
    width: 100%;
    height: 30px;
    background: #333;
    margin: 5px auto;
    position: relative;
    border-radius: 50px;
    box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.5), 0px 1px 0px fade(@light, 20%);
    &:after {
      content: '';
      position: absolute;
      top: 14px;
      left: 14px;
      height: 2px;
      width: 52px;
      background: @color-user;
      border-radius: 50px;
      box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.5), 0px 1px 0px fade(@light, 20%);
    }
    label {
      display: block;
      width: 140px;
      height: 22px;
      cursor: pointer;
      position: absolute;
      top: 6px;
      z-index: 1;
      .deco-font;
      left: 4px;
      font-size: 11px;
      .base-font;
      padding: 2px 0 0 23px;
      background: @color-user;
      border-radius: 50px;
      transition: all 0.4s ease;
      box-shadow: 0px 2px 5px 0px fade(@dark, 30%);
      background: linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
      &:after {
        content: '';
        width: 10px;
        height: 10px;
        position: absolute;
        top: 6px;
        left: 6px;
        background: #333;
        border-radius: 50px;
        box-shadow: inset 0px 1px 1px fade(@dark, 100%), 0px 1px 0px rgba(255,255,255,0.9);
      }
    }
    input[type=checkbox] {
      visibility: hidden;
      &:checked + label {
        left: 54px;
        &:after {
          background: $activeColor; /*activeColor*/
        }
      }
    }
  }
  /* end .slideTwo */
}
.clg-heading {
  h1 {
    font-size: 40px;
    span {
      font-size: 12px;
    }
  }
  @media screen and (max-width: @break-small) {
    padding: 10px;
    margin: 20px 0 0 0;
    text-align: center;
    h1 {
      font-size: 24px;
    }
    .clg-game {
      display: block;
      float: none;
      position: absolute;
      top: -50px;
      left: 0;
    }
    .detail {
      font-size: 12px;
    }
    .addthis_inline_share_toolbox {
      display: none;
    }
    .event-details {
    }
  }
}
.select-platform {
  display: none;
  position: absolute;
  bottom: -10px;
  left: 15px;
  width: ~"calc(100% - 30px)";
  border-radius: 0 0 4px 4px;
  z-index: 1;
  &.form-control {
    border: 1px solid @color-user;
  }
}
.unselected {
  opacity: .3;
}
.clg-info {
  padding: 10px 0 0 0;
  strong {
    font-size: .7em;
    display: block;
    opacity: .8;
  }
  h4 {
    border-bottom: 1px solid fade(@light, 10%);
    font-weight: 300;
    span {
      font-size: .5em;
      margin-left: 10px;
      font-weight: 100;
      color: @color-user;
    }
    @media screen and (max-width: @break-small) {
      border: none;
      margin: 0 0 10px 0;
    }

  }
}
#clg-filter {
    height: auto;
    background: @dark;
    text-align: justify;
    text-justify: distribute-all-lines;
    .filter-item {
        width: auto;
        height: 40px;
        padding: 0 12%;
        vertical-align: top;
        display: inline-block;
        *display: inline;
        zoom: 1;
        color: yellow;
        border: 1px solid @contrast-dark;
    }
    .pusher {
        width: 100%;
        display: inline-block;
        font-size: 0;
        line-height: 0;
    }
}

.clg-promo {
  text-align: center;
  height: 200px;
  margin: -20px 0;
  h2 {
    font-size: 1.5em;
    padding: 50px 0 20px 0;
    .base-font;
  }
}

.prev-step {
  border: 1px solid fade(@contrast-dark, 20%);
  margin-top: 5px;
  cursor: pointer;
  background-color: none;
  border-radius: 20px;
  padding: 2px 14px;
  font-size: 12px;
  .transition-cubic;
  &:hover {
    background-color: @color-user;
  }
}

// Maybe some general select classes here
.ef-big-select {
  border: 1px solid @color-user;
  padding: @content-padding;
  .transition-cubic;
  cursor: pointer;
  background-color: rgba(255,255,255,.25);
  border-radius: 4px;
  img {
    width: 100%;
  }
  text-align: center;
  &:hover {
    background: @color-user;
  }
}


.tourn-list {
  align-items: center;
  justify-content: center;
  border: 1px solid darken(@contrast-light, 10%);
  background: fade(darken(@contrast-light, 50%), 20%);
  border-radius: 4px;
  //height: @clg-height;
  margin: 10px 0 0 0;
  position: relative;
  overflow: hidden;

  //padding: 20px;
  p {
    opacity: .7;
  }
  h4 {
    span {
      font-size: 16px;
      font-weight: 100;
    }
  }
  .thumb {
    padding: 30px 0 0 0;
  }
  .btn-efantz {
    margin: 0;
  }
}

ul.prize-distribution {
  list-style: none;
  padding: 0;
  width: 100%;
  li {
    border-radius: 4px;
    width: 100%;
    margin: 0 10px 10px 0;
    font-size: 12px;
  }
}

.clg-list {
  align-items: center;
  justify-content: center;
  border: 1px solid darken(@contrast-light, 10%);
  background: fade(darken(@contrast-light, 50%), 20%);
  border-radius: 4px;
  //height: @clg-height;
  margin: 10px 0 0 0;
  position: relative;
  overflow: hidden;
  padding: 10px;
  p {
    opacity: .6;
  }
  .thumb {
    padding: 30px 0 0 0;
  }
  .btn-efantz {
    margin: 0;
  }
  &:hover {
    border: 1px solid darken(@contrast-light, 40%);
  }
  @media screen and (max-width: @break-small) {
    text-align: center;
    h4 {
      span {
        display: block;
        margin: 0;
        padding: 0;
      }
    }
    .btn-efantz-list {
      display: block;
      float: none;
    }
  }
}

.clg-username {
  position: absolute;
  z-index: 2;
  display: block;
  bottom: 0px;
  left: 0;
  padding: 5px;
  background: @color-user;
  font-size: 12px;
  &:before {
    content: ' ';
    position: absolute;
    top: 0;
    right: -24px;
    width: 25px;
    height: 24px;
    -webkit-clip-path: polygon(71% 0, 0 0, 0 100%);
    clip-path: polygon(71% 0, 0 0, 0 100%);
    background: @color-user;
  }
}

.clg-profile-image {
  border-radius: 4px;
  overflow: hidden;
  height: @clg-height;
  background: @dark;
  //width: @clg-height;
  &.pos-absolute {
    position: absolute;
  }
  &:not(.pos-absolute) {
    //margin-left: -15px;
  }
  background: @dark;
  left: 0;
  top: 0;
  //-webkit-clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
  //clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
  img {
    height: 100%;
  }
  @media screen and (max-width: @break-small) {
    //height: 400px;
    background: none;
    margin: 0 auto;
    height: 150px;
    width: 150px;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 50%;
    img {
      width: 100%;
      height: auto;
    }
  }
}

.tourn-profile-image {
  border-radius: 4px;
  overflow: hidden;
  max-height: 108px;
  width: 100%;
  background: @dark;
  margin-left: -20px;
  img {
    width: 100%;
  }
  @media screen and (max-width: @break-small) {
    max-height: none;
    margin: 10px 0 0 0;
  }
}


.clg-logo {
  //overflow: hidden;
  img {
    width: 80%;
    margin-top: -20px;
  }
  @media screen and (max-width: @break-small) {
    display: none;
  }
}
.clg-logo-mobile {
  display: none;
  @media screen and (max-width: @break-small) {
    display: block;
    text-align: center;
    img {
      width: 100px;
    }
  }
}





.clg-event {
  .event-details {
    font-weight: normal;
    .detail {
      //color: @color-user;
      font-weight: normal;
      font-size: 1em;
      @media screen and (max-width: @break-small) {
        font-size: 14px;
        font-weight: normal;
      }

    }
  }
  .close-table {
    background-color: #f00;
    border-radius: 5px;
    border: solid 1px #fc3;
    h3 {
      padding: 3px 0;
      text-align: center;
      color: @dark;
    }
  }
  .clg-game {
    img {
      width: 200px;
      @media screen and (max-width: @break-small) {
        width: 100px;
      }
    }
  }
  .clg-players {
    position: relative;
    .vs_vs {
      @media screen and (max-width: @break-small) {
        display: none;
      }
      position: absolute;
      left: calc(~"50% - 37px");
      top: 100px;
      display: block;
      align-self: center;
      background: @light;
      color: @dark;
      width: 75px;
      height: 75px;
      z-index: 999;
      box-shadow: inset 0px 0px 40px -7px fade(@dark, 75%);
      .deco-font;
      font-size: 24px;
      padding: 20px 0 0 0;
      clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
      @media screen and (max-width : @break-small){
        box-shadow: none;
        clip-path: none;
        background: none;
        color: @light;
      }
    }



    .thumb {
      width: 100%;
      height: 290px;
      background: @dark;
      overflow: hidden;
      margin: 0 0 20px 0;
      img {
        width: 100%;
      }
      @media screen and (max-width: @break-small) {
        height: 100px;
      }
    }
  .clg-player {
    border-bottom: 4px solid @color-user;
    background: @contrast-light;
    box-shadow: inset 0px 0px 60px 0px fade(@dark, 75%);
    border-radius: 4px;
    //margin: 30px;
    .clg-rank {
      position: absolute;
      width: 50px;
      top: 10px;
      left: 20px;
      &.rank-opponent {
        left: auto;
        right: 20px;
      }
    }
    img {
      border-radius: 4px;
      overflow: hidden;
    }
    h2 {
      //box-shadow: inset 0px -16px 21px 0px fade(@dark, 75%);
      padding: 0 0 5px 0;
      @media screen and (max-width: @break-small) {
        font-size: 16px;
        .base-font;
      }
    }
    h3 {
      margin-bottom: 15px;
      font-size: 16px;
      @media screen and (max-width: @break-small) {
        font-size: 12px;
        .base-font;
      }
    }
    .player-details {
      text-align: left;
      padding:  0 10px;
      @media screen and (max-width: @break-small) {
        display: none;
      }
    }
    .ready-status {
      width: 50%;
      margin: 0 auto;
      border-radius: 5px;
      h3 {
        padding: 5px;
        text-align: center;
      }
      &.ready {
        background-color: @light;
        h3 {
          color: @dark;
        }
      }
      &.not-ready {
        background-color: #fc3;
      }
      img {
        height: 25%;
        width: 25%;
      }
    }
  }
  .clg-entry {
    .circle-box {
      display: inline-block;
      height: 60px;
      width: 60px;
      padding: 20px;
      margin:auto;
      position: relative;
      z-index: 1;
      text-align: center;
      font-weight: bold;
      border-radius: 50%;
      background-color: @dark;
      h2 {
        margin: 0 -25px;
        font-size: 28px;
      }
    }
    .entry-values {
      .prize-money {
        font-size: 1.9em;
        color: @color-user;
      }
      .entry-cost {
        color: @color-user;
      }
      }
    }
  }
}


.clg-thumb {

}


.game {
  //border: 1px solid @color-user;
  //padding: @content-padding;
  .transition-cubic;
  cursor: pointer;
  background-color: fade(darken(@contrast-light, 10%), 70%);
  border-radius: 4px;
  margin-bottom: 20px;
  font-size: 10px;
  color: #fff;
  display: block;
  padding: 5px;
  p {
    padding: 0 10px;
  }
  img {
    width: 100%;
  }
  text-align: center;
  &:hover {
    background: @color-user;
  }
  .platform {
    border-top: 1px solid @color-user;
    background: @contrast-light;
    padding: 5px;
  }
}

.clg-create {
  text-align: center;
  .progress-indicator {

  }
  .next-btn {
    width: 25%;
    margin: 0 auto;
    border-radius: 5px;
    background-color: @color-user;
    h3 {
      padding: 5px;
      text-align: center;
    }
  }
  .submit-game-id {
    text-align: center;
    h4 {
      color: #f00;
    }
    .id-submit {
      margin: 0 auto;
      width: 50%;

    }
  }
  .entry-fee {
    text-align: center;

    .entry-fee-submit {
      display: inline-block;
      margin: 0 auto;
      width:30%;

    }
  }

  .match-rules {

    h4 {
      font-size: 1.3em;
      margin-bottom: 15px;
    }
      .match-rounds {
        margin-left: 30%;
      }
      .match-minutes{
        margin-left: 30%;
        margin-top: 16px;
        .dropdown-toggle {
          margin-left:25px;
          border-radius: 3px;
        }
      }
      .match-region {
        margin-right: 30%;
        .dropdown-toggle {
          border-radius: 3px;
        }
      }
      .match-map {
        margin-right: 30%;
        .dropdown-toggle{
          border-radius: 3px;
        }
      }
      .table-name {
        width: 100%;
        h2 {
          text-align: center;
        }
        .name-submit {
          margin: 0 auto;
          width: 50%
        }
      }
      .custom-rules {

        .custom-rules-submit {
          margin: 0 auto;
          width: 50%;
        }
      }


  }


  @media screen and (max-width : @break-medium){
    .match-rules {

      .match-rounds {
        margin: 0 auto;
      }
      .match-minutes {
        margin: 0 auto;
      }
      .match-region {
        margin: 15px auto 0;
      }
      .match-map {
        margin: 0 auto;
      }
      .table-name {

        .name-submit {
          width: 50%;
        }
      }
      .custom-rules {
        .custom-rules-submit {
          width: 50%;
        }
      }

    }
  }

  @media screen and (max-width : @break-small){

    .submit-game-id {
      .id-submit {
        width: 250px;
      }
    }

    .match-rules {

      .match-rounds {
        margin: 0 auto;
      }
      .match-minutes {
        margin: 0 auto;
      }
      .match-region {
        margin: 15px auto 0;
      }
      .match-map {
        margin: 0 auto;
      }
      .table-name {

        .name-submit {
          width: 250px;
        }
      }
      .custom-rules {
        .custom-rules-submit {
          width: 250px;
        }
      }

    }
  }

  @media screen and (max-width : @break-xsmall){

    .next-btn {
      width: 50%
    }
  }






}



/* Accessories / Helpers
---------------------------------*/
.img-responsive {
  width: 100%;
  //margin-bottom: 20px;
}

.selected {
  background: @color-user;
  border-radius: 4px;
}

.small {
  font-size: 12px;
  float: left;
}

.vertical-center {
  min-height: 100%;
  min-height: 80vh;
  display: flex;
  align-items: center;
}

.dropdown-divider {
  background-color: fade(@contrast-dark, 10%);
}

.hidden {
    display: none;
}

.visible-o {
  opacity: 1;
}

.visible {
  opacity: 1;
  display: block;
  display: flex;
}
.hr {
  height: 1px;
  clear: both;
  margin: @content-padding 0;
}

.hr-center {
  opacity: .2;
  .hr;
  background: -moz-linear-gradient(left,  rgba(0,0,0,0) 0%, rgba(255,255,255,1) 50%, rgba(0,0,0,0) 100%);
  background: -webkit-linear-gradient(left,  rgba(0,0,0,0) 0%,rgba(255,255,255,1) 50%,rgba(0,0,0,0) 100%);
  background: linear-gradient(to right,  rgba(0,0,0,0) 0%,rgba(255,255,255,1) 50%,rgba(0,0,0,0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='@dark00', endColorstr='@dark00',GradientType=1 );
}

.hr-left {
  .hr;
  margin: 5px 0;
  background: -moz-linear-gradient(left,  @color-user 0%, rgba(0,0,240,0) 100%);
  background: -webkit-linear-gradient(left,  @color-user 0%,rgba(0,0,240,0) 100%);
  background: linear-gradient(to right,  @color-user 0%,rgba(0,0,240,0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='@color-user', endColorstr='@darkf0',GradientType=1 );
}

.videoWrapper {
	position: relative;
  //z-index: 9;
	padding-bottom: 54.1%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}

.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
  z-index: 3;
}

.preload * {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
}

/* Forms
---------------------------------*/
.form-wrap {
  padding: 0 @content-padding;
}
.form-control {
  background: @contrast-light;
  color:  @contrast-dark;
}

label {
  color:  @contrast-dark;
}
.datetimepicker {
  &.dropdown-menu {
    background-color: @dark;
    border: 1px solid @color-user;
    &:after {
      border-bottom: 6px solid @color-user;
    }
  }
}

.form-control[disabled] {
  background-color: @contrast-light;
  color: darken(@contrast-dark, 50%);
}

.form-control {
  background-color: @contrast-light;
}

.hirez,
.arenanet,
.nexon,
.lol,
.origin,
.xblive,
.psn,
.battlenet,
.steam,
.twitch,
.hitbox,
.android,
.ios,
.youtube-gaming,
.azubu {
  background-size: contain !important;
  padding-right: 100px;
}

.ios {
  background: url('/img/logos/services/ios.jpg') top right no-repeat;
}

.android {
  background: url('/img/logos/services/androi.jpg') top right no-repeat;
}

.hirez {
  background: url('/img/logos/services/hires.jpg') top right no-repeat;
}

.arenanet {
  background: url('/img/logos/services/arenanet.jpg') top right no-repeat;
}

.nexon {
  background: url('/img/logos/services/nexon.jpg') top right no-repeat;
}

.lol {
  background: url('/img/logos/services/lol.jpg') top right no-repeat;
}

.origin {
  background: url('/img/logos/services/origin.jpg') top right no-repeat;
}

.xblive {
  background: url('/img/logos/services/xblive.jpg') top right no-repeat;
}

.psn {
  background: url('/img/logos/services/psn.jpg') top right no-repeat;
}

.battlenet {
  background: url('/img/logos/services/battlenet.jpg') top right no-repeat;
}

.steam {
  background: url('/img/logos/services/steam.jpg') top right no-repeat;
}

.twitch {
  background: url('/img/logos/services/twitch.jpg') top right no-repeat;
}

.hitbox {
  background: url('/img/logos/services/hitbox.jpg') top right no-repeat;
}

.youtube-gaming {
  background: url('/img/logos/services/youtube-gaming.jpg') top right no-repeat;
}

.azubu {
  background: url('/img/logos/services/azubu.jpg') top right no-repeat;
}

.rangeslider {
  background: @dark;
  .rangeslider__fill {
    background: @color-user;
  }
}

.wager-box {
  text-align: left;
  background: fade(@contrast-dark, 5%);
  border-radius: 4px;
  text-align: center;
  .your-wager {
    padding: 5px;
    border-radius: 4px;
    background: fade(@contrast-dark, 10%);
    @media screen and (max-width : @break-small){
      h2 {
        font-size: 16px;
      }
    }
  }
  .payout {
    padding: 5px;
    border-radius: 4px;
    background: fade(@contrast-dark, 10%);
    @media screen and (max-width : @break-small){
      h2 {
        font-size: 16px;
      }
    }
  }
  @media screen and (max-width : @break-small){
    background: none;
  }
}

.wager-panel {
  //margin: 100px 0 0 0;
  &:before {
    clip-path: polygon(0 6%, 100% 0, 100% 93%, 0% 100%);
    content: " ";
    width: 30px;
    height: 300px;
    position: absolute;
    top: 99px;
    left: -15px;
    //background: @contrast-light;
    //border-bottom: 1px solid @color-user;
  }
}

/* Contests
---------------------------------*/
.contest {
  .contest-img {
    width: 150px;
    height: 150px;
    border-radius: 4px;
    display: inline-block;
    overflow: hidden;
    img {
      height: 100%;
    }
  }
  .contest-desc {
    h3 {
      margin: 0 0 @content-padding 0;
    }
    h4 {
      font-weight: bold;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        margin: 0;
        padding: 0;
      }
    }
  }
}

/* Friend Requests
---------------------------------*/
.friend-img {
  width: 200px;
  height: 200px;
  border-radius: 4px;
  display: block;
  overflow: hidden;
  text-align: center;
  margin: 0 auto 20px auto;
  background: #000;
  //clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  img {
    width: 100%;
    margin: 0 auto;
  }
}

.friend-request {

  .contest-desc {
    h3 {
      margin: 0 0 @content-padding 0;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        margin: 0;
        padding: 0;
      }
    }
  }
}

/* Friend Requests
---------------------------------*/

.message {
  .message-desc {
    h3 {
      margin: 0 0 @content-padding 0;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        margin: 0;
        padding: 0;
      }
    }
  }
}

/* Tabs
---------------------------------*/
.nav-tabs {
  border-color: @color-user;
  >li.active {
    >a {
      //background: @color-user;
      //color: @contrast-dark;
      //border-color: @color-user;
      &:hover {
        //background: @color-user;
        //color: @contrast-dark;
        //border-color: @color-user;
      }
      &:focus {
        //background: @color-user;
        //color: @contrast-dark;
        //border-color: @color-user;
      }
    }
  }
  >li {
    >a {
      color: @contrast-dark;
      &:hover {
        background: @color-user;
        color: @contrast-dark;
        border-color: @color-user;
      }
      &:focus {
        background: @color-user;
        color: @contrast-dark;
        border-color: @color-user;
      }
    }
  }
}
.tab-pane {
  padding: @content-padding;
}

/* Containers
---------------------------------*/
.ef-body-mm {
  //z-index: 10;
}
.ef-body {
  .transition-cubic;
  //width: 90vw;
  max-width: 1400px;
  margin: 0 auto;
  //margin-top: @main-menu-height;
  padding: 0 10px 0 10px;
  position: relative;
  @media screen and (max-width : @break-large){
    margin-top: 0;
  }
  &.chat-open {
    padding: 0 (@chat-width + 10px) 0 10px;
    @media screen and (max-width: @sidemenu-chat-overlay-width) {
      padding-right: 0;
    }
  }
}

.ef-interior {
  padding: @content-padding * 2;
  background: fade(@contrast-light, 40%);
  border-top: 4px solid fade(@contrast-dark, 10%);
}

.ef-grid {
  min-height: 100vh;
  position: relative;
  //z-index: 10;
  //background-image: url('/img/pattern.png');
  background-image: linear-gradient(to bottom, fade(@dark, 0%) 0%, fade(@dark, 80%) 71%, fade(@dark, 80%) 83%);
  padding: 0 0 20px 0;
}

.ef-grid-inner {
  position: relative;
  z-index: 10;
  //background-image: url('/img/pattern.png');
  //background-image: url('/img/pattern.png'), linear-gradient(to bottom,  rgba(0,0,0,0) 0%, @dark 71%, @dark 83%);
}

#ef-container {
  .transition-cubic;
  position: relative;
  z-index: 10;
  margin-left: @sidebar-outer-width;
  .site_bg;
  @media screen and (max-width : @break-small){
    margin-left: 0;
  }
  min-width: 190px; // ~guestimate here -- prevents bad overflow issues
  min-height: 100vh;
  /* submenu
  ----------------*/
  .navbar {
    position: relative;
    z-index: 99;
  }

  /* Main Menu
  ---------------------------------*/
  .main-menu {
    clear: both;
    padding: 20px;
    position: relative;
    z-index: 9;
    .mm-logo {
      .transition-cubic;
      &:hover {
        -webkit-filter: drop-shadow( 0 0 8px lighten(@color-user, 50%) );
        filter: drop-shadow( 0 0 8px lighten(@color-user, 50%) );
      }
      img {
        width: 65px;
        margin-left: -22px;
        margin-top: -17px;
      }
      @media screen and (max-width : @break-small){
        position: fixed;
        top: 0;
        left: calc(~"50vw - 35px");
        img {
          margin: 0;
        }
        background: @color-user;
        &::before {
          content: ' ';
          position: absolute;
          top: 0;
          right: -40px;
          height: 65px;
          width: 40px;
          background: @color-user;
          -webkit-clip-path: polygon(0 100%, 0 0, 100% 0);
          clip-path: polygon(0 100%, 0 0, 100% 0);
        }
        &::after {
          content: ' ';
          position: absolute;
          top: 0;
          left: -39px;
          height: 65px;
          width: 40px;
          background: @color-user;
          -webkit-clip-path: polygon(100% 0, 0 0, 100% 100%);
          clip-path: polygon(100% 0, 0 0, 100% 100%);
        }
      }
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: space-between;
      @media screen and (max-width : @break-small){
        display: block;
        height: 100vh;
        width: 100vw;
        background: fade(@dark, 90%);
        text-align: center;
        position: fixed;
        left: 0;
        top: -100vh;
        padding: 60px 0 0 0;
        z-index: 999;
      }
      li {
        @media screen and (max-width : @break-small){
          padding: 20px 10px;
          border-bottom: 1px solid rgba(255,255,255,.25);
          float: none;
          display: block;
          ul.nav {
            display: none;
          }
        }
        a:not(.mm-logo) {
          transition: all .2s;
          transition-timing-function: ease-in-out;
          font-size: 14px;
          font-weight: normal;
          padding: 10px 20px;
          .deco-font;
          color: @light;
          &:hover, &:active {
            text-decoration: none;
            background: rgba(0,0,0,.5);
          }
        }
      }
    }
  }




  #nav-item-stacked {
    display: block;
    padding: 4px;
    display: block;
    padding: 12px 20px;
    text-decoration: none;
    font-size: 11px;
    >span {
      display: block;
    }
    .ef-icon.ef-icon-inline {
      height: 12px;
    }
  }
  #menu-main {
    .deco-font;
    position: relative;
    z-index: 100;
    //top: 0;
    //left: 0;
    width: 100%;
    height: @main-menu-height;
    //background: linear-gradient(to bottom, @contrast-light 0%, darken(@contrast-light, 20%) 100%);
    //border-bottom: 1px solid @color-user;menu-main
    //box-shadow: 0px 10px 41px -8px rgba(0,0,0,0.75);
    //border-radius: 0 0 4px 4px;
    //border: 1px solid rgba(255,255,255,.1);
    margin: 0 0 40px 0;
    .nav {
      .nav-item {
        a.nav-link {
          height: @main-menu-height;
          .deco-font;
          font-weight:700;
          font-size: 12px;
          color: @light;
          transition: .2s ease-in-out;
          background: none;
          opacity: 1;
          &:hover {
            border-radius: 0 0 4px 4px;
            background: @color-user;
          }
        }
      }
    }
    #mobile-menu {
      display: none;
      width: 30px;
      height: 18px;
      position: relative;
      float: left;
      margin: 18px 0 0 13px;
      transform: rotate(0deg);
      transition: .5s ease-in-out;
      cursor: pointer;
      span {
        display: block;
        position: absolute;
        height: 4px;
        width: 100%;
        background: @color-user;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;
      }
      span:nth-child(1) {
        top: 0px;
      }
      span:nth-child(2) {
        top: 7px;
      }
      span:nth-child(3) {
        top: 14px;
      }
      &.open span:nth-child(1) {
        top: 7px;
        -webkit-transform: rotate(135deg);
        -moz-transform: rotate(135deg);
        -o-transform: rotate(135deg);
        transform: rotate(135deg);
      }
      &.open span:nth-child(2) {
        opacity: 0;
        left: -60px;
      }
      &.open span:nth-child(3) {
        top: 7px;
        -webkit-transform: rotate(-135deg);
        -moz-transform: rotate(-135deg);
        -o-transform: rotate(-135deg);
        transform: rotate(-135deg);
      }
    }
    @media screen and (max-width : @break-large){
      margin: 0 0 20px 0;
      #mobile-menu {
        display: block;
      }
      .wrap-mobile-menu {
        position: absolute;
        //top: @main-menu-height;
        top: -1200px;
        padding: 5px;
        background: @contrast-light;
        left: 62px;
        z-index: 2;
        .nav {
          margin: 0;
        }
        .nav-inline {
          .nav-item {
            display: block;
            width: 100%;
            float: none;
            .nav-link {
              border: none;
            }
          }
        }
      }
    }
    @media screen and (max-width : @break-medium){
      //background: #f00;
    }
    @media screen and (max-width : @break-small){
      //background: #ff0;
    }
    @media screen and (max-width : @break-xsmall){
      //background: @light;
    }
    .logo {
      float: left;
      background: @color-user;
      //width: @sidebar-outer-width;
      background: linear-gradient(to bottom,  @color-user 0%, darken(@color-user, 20%) 100%);
      overflow: hidden;
      //border-radius: 0 0 0 4px;
      img {
        height: 50px;
        padding: 4px 10px;
        //margin-left: @sidebar-outer-width / 4;
      }
    }
    .dropdown-divider {
      height: 1px;
      background: fade(@contrast-dark, 5%);
    }
    .nav-item {
      border-radius: 4px;
    }
    .wrap-mobile-menu>ul {
      >li {
        padding: 0;
        margin: 0;
        >a {
          height: @main-menu-height + 3px;
          padding: 16px 10px;
          //border-left: 1px solid fade(@contrast-dark, 10%);
          border-radius: 0;
          font-size: 13px;
          color: fade(@contrast-dark, 70%) !important;
          &:hover {
            color: @contrast-dark !important;
          }
        }
      }
    }
  }


  .menu-section {
    span {
      font-size: 14px;
      background: @color-user;
      border-radius: 0;
      margin: 0;
      padding: 10px;
      display: block;
    }
  }
  .mobile-only {
    display: none;
    @media screen and (max-width : @break-large){
      display: block;
    }
  }
  .mobile-only-xs {
    display: none;
    @media screen and (max-width : @break-xsmall){
      display: block;
    }
  }
  .mobile-no {
    display: block;
    @media screen and (max-width : @break-large){
      display: none;
    }
  }




  /* Profile Colors
  ----------------------------------*/
  .user-color {
    width: 20px;
    height: 20px;
    float: left;
    margin: 0 10px 10px 0;
    border: 1px solid @light;
    cursor: pointer;
    &.red {
      background-color: rgba(237, 31, 36, 1);
    }
    &.green {
      background-color: rgba(0, 165, 0, 1);
    }
    &.hunter {
      background-color: #1c7553;
    }
    &.blue {
      background-color: rgba(17, 165, 228, 1);
    }
    &.purple {
      background-color: rgba(85, 35, 146, 1);
    }
    &.orange {
      background-color: #ff5500;
    }
    &.turquoise {
      background-color: #76dfda;
    }
    &.slate {
      background-color: #515151;
    }
    &.pink {
      background-color: #ff5cf7;
    }
    &.military {
      background-color: #373F2E;
    }
    &.badlands {
      background-color: #BF5D38;
    }
    &.corporate {
      background-color: #091541;
    }
    &.salmon {
      background-color: #D54A4C;
    }
    &.notthebees {
      background-color: #ff9900;
    }
    &.crimson {
      background-color: #4F130F;
    }
    &.verde {
      background-color: #43AD16;
    }
    &.moss {
      background-color: #333300;
    }
    &.seafoam {
      background-color: #059e8f;
    }
    &.fusion {
      background-color: #a90a5e;
    }
  }



  /* user Menu
  ----------------
  .user-menu {
    float: right;
    .profile_icon {
      float: left;
      width: 26px;
      height: 26px;
      margin: 0 10px 0 0;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
    >ul {
      >li {
        padding: 0;
        margin: 0;
        >a {
          height: @main-menu-height;
          padding: 16px 10px;
          //border-left: 1px solid fade(@contrast-dark, 10%);
          border-radius: 0;
          font-size: 13px;
          color: fade(@contrast-dark, 70%) !important;
          &:hover {
            color: @contrast-dark !important;
          }
          &:last-child {
            //border-right: 1px solid fade(@contrast-dark, 10%);
          }
        }
      }
    }
  }
*/
  /* Main Menu
  ----------------*/
  .ef-menu-main {
    position: relative;
    z-index: 11;
    width: @sidebar-width;
    height: 100vh;
    position: fixed;
    &.left {
      left: -@sidebar-width;
    }
    top: 0;
    .transition-cubic;
  }

  .ef-menu-outer {
    position: fixed;
    //right: @sidebar-outer-width;
    left: 0;
    //top: @main-menu-height;
    width: @sidebar-outer-width;
    @media screen and (max-width : @break-small){
      width: 100vw;
      bottom: 0;
      top: auto;
      height: 60px;
      ul {
        display: flex;
        width: 100%;
        li {
          float: left;
          flex: 10;
          align-self: center;
        }
      }
    }
    height: 100vh;
    //background: @contrast-light;
    background: @color-user;
    //border-left: 4px solid @color-user;
    z-index: 99;
    .transition-cubic;
    .ef-logo {
      .hexagon;
      .transition-cubic;
      //background: @dark;
      padding: 12px 5px 0 5px;
      width: 50px;
      height: 50px;
      display: block;
      text-align: center;
      margin: 15px auto 15px auto;
      img {
        width: 100%;
        margin-top: -5px;
      }
      &:hover{
        background: darken(@color-user, 20%);
      }
    }
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      li {
        position: relative;
        :nth-child(1) {
          animation: fadein .2s;
        }
        :nth-child(2) {
          animation: fadein .4s;
        }
        :nth-child(3) {
          animation: fadein .6s;
        }
        :nth-child(4) {
          animation: fadein .8s;
        }
        :nth-child(5) {
          animation: fadein 1s;
        }
        a {
          display: block;
          padding: 5px;
          font-size:20px;
          .transition-cubic;
          img {
            width: 100%;
            //-webkit-filter: grayscale(100%);
            //filter: grayscale(100%);
            //opacity: .6;
          }
          @media screen and (max-width : @break-small){
            font-size: auto;
            >img {
              max-height: 57px;
              width: auto;
            }
          }
          text-align: center;
          border-bottom: 1px solid fade(@dark, 10%);
          //color: darken(@color-user,25%);
          color: fade(@contrast-dark, 90%);
          text-shadow: 0px 0px 2px fade(@contrast-dark, 7%);
          .inner-shadow;
          &:hover {
            //color: @color-user;
            color: fade(@contrast-dark, 90%);
            text-shadow: 0 0 10px lighten(@contrast-dark, 20%);
            img {
              -webkit-filter: grayscale(0%);
              filter: grayscale(0%);
              opacity: 1;
            }
          }
          &.ef-menu-active {
            //color: @color-user;
            //color: fade(@contrast-dark, 90%);
            //text-shadow: 0 0 10px lighten(@contrast-dark, 100%);
            .transition-cubic;
            padding: 2px;
            img {
              -webkit-filter: grayscale(0%);
              filter: grayscale(0%);
              opacity: 1;
            }
          }
        }
        border-bottom: 1px solid rgba(0, 0, 0, .5);
      }
    }
  }

  .ef-menu-inner {
    width: @sidebar-width;
    height: 100vh;
    position: absolute;
    display: none;
    //top: @main-menu-height;
    left: 0;
    background: @contrast-light;
    text-align: left;
    overflow-x: hidden;
    overflow-y: scroll;
    border-right: 4px solid @color-user;
    .transition-cubic;
    h2 {
      font-size: 16px;
      //background: @color-user;
      padding: 5px;
      position: absolute;
      top: 0;
      width: @sidebar-width;
      cursor: pointer;
    }
    .nav-inline {
      padding-right: 10px;
    }
    .sidebar-sub-menu {
      margin: 0;
      text-align: right;
      .nav {
        background: none;
        padding: 0;
        margin: 0;
        border-radius: 0;
        border-bottom: 1px solid @color-user;
        .nav-link {
          border-left: 1px solid @color-user;
          padding: 2px 6px;
          margin: 0;
        }
      }
    }
    .logo-lrg {
      img {
        width: 100%;
        padding: @content-padding;
      }
    }
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      li {
        text-align: left;
        a {
          display: block;
          padding: 20px;
          border-bottom: 1px solid fade(@contrast-dark, 10%);
          color: @contrast-dark;

          text-decoration:  none;
          .deco-font;
          &:hover {
            background: @color-user;
          }
        }
      }
    }
  }

  /* Main Menu Open */
  &.ef-toggled {
    @media screen and (min-width: @sidemenu-chat-overlay-width + 1) {
      //margin-left: @sidebar-width + @sidebar-outer-width;
    }
    .ef-menu-main {
      left: @sidebar-outer-width;
      @media screen and (max-width : @break-small){
        left: 0;
      }
    }
    .ef-menu-outer {
      border-width: 0px;
      box-shadow: 0px 0px 60px -16px rgba(0,0,0,0.75);
    }
  }


  /* Chat
  ---------------------------------*/
  #chat-cont {
    .transition-cubic;
    position: fixed;
    right: -@chat-width;
    //top: @main-menu-height;
    top: 0;
    width: @chat-width;
    z-index: 12;
    height: 100vh;
    background: @contrast-light;
    border-left: 2px solid @color-user;
    width: @chat-width;
    .user-block {
      height: 271px;
      overflow: hidden;
      .user-profile {
        .user-logout {
          position: absolute;
          top: 10px;
          right: 10px;
          font-size: 11px;
          color: #fff;
        }
        .user-image {
          text-align: center;
          padding: 40px 40px 20px 40px;
          .user-edit {
            position: absolute;
            top: 211px;
            left: 8px;
            color: #fff;
          }
          .user-pic {
            display: block;
            img {
              width: 100%;
            }
            border-radius: 4px;
            .transition-cubic;
            border: 1px solid @dark;
            height: 148px;
            overflow: hidden;
            &:hover {
              border: 1px solid @light;
            }
          }
          .user-rank {
            width: 50px;
            position: absolute;
            top: 70px;
            right: 20px;
          }
        }
      }
      .user-cashflow {
        text-align: center;
        a {
          color: #fff;
          display: block;
          background: @dark;
          &:hover {
            background: @color-user;
          }
          ul {
            list-style: none;
            padding: 0;
            margin: 0;
            display: inline-block;
            li {
              padding: 5px;
              margin: 0;
              font-size: 12px;
              float: left;
            }
          }
        }
      }
    }

    .chat-close {
      .transition-cubic;
      transform: rotate(0deg);
      position: absolute;
      width: 30px;
      height: 30px;
      overflow: hidden;
      left: -30px;
      top: 0;
      font-size: 16px;
      text-align: center;
      padding-top: 3px;
      background-color: fade(@color-user, 70%);
      cursor: pointer;
      &:hover {
        background-color: @color-user;
      }
    }
    &.chat-open {
      right: 0;
      .chat-close {
        transform: rotate(180deg);
        position: absolute;
        left: 0;
        top: 0;
      }
    }
    .chat-heading {
      position: absolute;
      top: 271px;
      h4 {
        font-size: 14px;
        .deco-font;
        text-align: center;
        width: @chat-width;
        background: @contrast-light;
        box-shadow: 0px 10px 41px -8px rgba(0,0,0,0.75);
        padding:10px;
        position: absolute;
        top: -1px;
      }
    }
    iframe {
      //margin-top: -@main-menu-height;
      height: calc(~"100vh - 276px");
      padding: 5px;
    }

  }
}





/* Marketplace
---------------------------------*/
.market-promo {
  text-align: center;
  height: 300px;
  margin: -20px 0;
  h2 {
    font-size: 1.5em;
    padding: 200px 0 20px 0;
    .base-font;
  }
}

.market-item {
  background: fade(@dark, 50%);
  text-align:center;
  padding: 10px 10px 0 10px;
  border-radius: 4px;
  box-sizing: border-box;
  margin: 0 0 20px 0;
  .btn.btn-efantz {
    margin: 20px 0 0 0;
  }
  .market-amount {
    position: absolute;
    top: 20px;
    right: 30px;
    font-size: 11px;
    padding: 5px 10px;
    background: @color-user;
    color: @light;
    border-radius: 20px;
    .ef-icon-inline {
      height: 10px;
    }
  }
  .market-image {
    border-radius: 4px;
    overflow: hidden;
    >img {
      width: 100%;
    }
  }
  h4 {
    .base-font;
    font-size: 1em;
    font-weight: 100;
    margin: 20px 0 0 0;
  }
  p {
    font-size: .7em;
  }
}


/* Challenger Table
-------------------------------*/

.clg-table {
  margin: 0 10px 5px;;
  position: relative;
  border-top: solid 1px @color-user;
  padding: @content-padding;
  background-color: @contrast-light;
  text-align: center;
  .table-name {
    margin-top: 10px;

  }
  .table-prize {
    h4 {
      span {
        color: #8cff66;
      }
    }
    h5  {
      margin-top: 5px;
      span {
        color: #fc3;
      }
    }
  }
  .table-game{
    img {
      width: 50%;
      margin-top: -25px;
      &.csgo {
        filter: invert(100%);
        width: 60%;
        margin:-30px 0 -10px;
      }
    }

  }
  .table-opponent{
    margin: -6px 0 5px;
    span {
      color: #f00;
    }

  }
  .table-date {

  }
  .btn-efantz {
    margin: 10px 0 0 0;
  }

}

.se {
  padding: 20px;
  margin: 30px 30px 0 30px;
  border: 1px solid rgba(255,255,255,.2);
  border-radius: 4px;
  position: relative;
  background: fade(@dark, 20%);
  .event-live {
    background: @color-user;
    padding: 4px 10px;
    border-radius: 4px;
    font-size: 10px;
    z-index: 999;
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .se_starts {
    background: rgba(0, 0, 0, .5);
    padding: 4px 10px;
    text-align: center;
    border-radius: 4px;
    font-size: 10px;
    margin-bottom: 5px;
  }

  .se_image {
    z-index: 0;
    width:50px;
    float: left;
    border-radius: 4px;
    overflow: hidden;
    margin-right: 10px;
    img {
      width: 100%;
    }
    @media screen and (max-width : @break-small){
      display: none;
    }
  }
  .se_game_image {
    z-index: 0;
    float: left;
    border-radius: 4px;
    overflow: hidden;
    width: 89px;
    margin-right: 10px;
    img {
      width: 100%;
    }
    @media screen and (max-width : @break-small){
      position: absolute;
      left: 50%;
      margin: 0 0 0 -45px;
      top: -133px;
    }
  }
  .se_title {
    position: relative;
    z-index: 2;
    text-align: left;
    @media screen and (max-width : @break-small){
      text-align: center;
    }
    h4 {
      font-size: 24px;
      margin: 0;
    }
    small {
      font-size: 11px;
      opacity: .6;
    }
    @media screen and (max-width : @break-small){
      .btn-efantz {
        &.pull-right {
          float: none;
          display: block;
        }
      }
    }
  }
  .se_game {
    float: left;
    width: 100px;
  }
  h5 {
    text-align: center;
    font-weight: normal;
    display: none;
  }
  .starts {
    text-align: center;
    position: relative;
    z-index: 3;
  }
}

/* VS Lockup New
---------------------------------*/
.vs {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  border-radius: 4px;
  padding: 10px;
  //border-left: 4px solid @color-user;
  //background: @dark;
  margin: 0 27px 10px 27px;
  .vs_team {
    flex: 10;
    align-self: center;
  }
  .vs_logo {
    height: 80px;
    flex: 1;
    align-self: center;
  }
  .vs_icon {
    img {
      width: 100px;
    }
  }
  .vs_vs {
    margin: 20px;
    display: block;
    align-self: center;
    background: @light;
    color: @dark;
    width: 40px;
    height: 50px;
    box-shadow: inset 0px 0px 40px -7px fade(@dark, 75%);
    .deco-font;
    padding: 13px 0 0 0;
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    @media screen and (max-width : @break-small){
      box-shadow: none;
      clip-path: none;
      background: none;
      color: @light;
    }
  }
  h5 {
    display: inline;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 900;
    //border-bottom: 2px solid @color-user;
    .deco-font;
    text-transform: uppercase;
    @media screen and (max-width : @break-small){
      display: none;
    }
    //overflow: hidden;
    //border-radius: 4px;
    //background: @contrast-light;
    //box-shadow: inset 0px 0px 21px -5px rgba(0,0,0,0.75);
  }
}


/* Trek Admin
---------------------------------*/

.trek {
  margin: 10px 0 0 0;
  padding: 0 0 @content-padding 0;
  border: 1px solid fade(@contrast-dark, 10%);
}

.free-answer {
  margin: 10px 0 0 0;
  padding: @content-padding;
  border: 1px solid fade(@contrast-dark, 10%);
}

.wager-heading {
  background: fade(@contrast-light, 50%);
  border-bottom: 1px solid fade(@contrast-dark, 10%);
  padding: @content-padding;
  margin: 0 -12px @content-padding -13px;
  strong {
    font-size: 18px;
  }
}

.delete {
  cursor: pointer;
  background: #f00;
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 2px 10px;
  border-radius: 4px;
}

/* Events
---------------------------------*/

.contests-caro {
  border-radius: 4px;
  //box-shadow: inset 0px -9px 39px -13px rgba(0,0,0,0.75);
  .prev {
    position: absolute;
    display: block;
    top: 45%;
    left: 0;
    font-size: 40px;
    color: @color-user;
    z-index: 999;
    cursor: pointer;
    box-shadow: 0px -9px 39px -13px fade(@dark, 75%);
  }
  .next {
    position: absolute;
    display: block;
    top: 45%;
    right: 0;
    font-size: 40px;
    color: @color-user;
    z-index: 999;
    cursor: pointer;
    box-shadow: 0px -9px 39px -13px fade(@dark, 75%);
  }
  .slick-disabled {
    opacity: .2;
    color: @contrast-dark;
    cursor: default;
  }

}

.event-listing {
  //background: fade(@contrast-light, 90%);
  background: @contrast-light;
  border-radius: 4px;
  text-align: center;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  min-height: 320px;
  padding: 10px;
  margin: 0 @content-padding/2 @content-padding @content-padding/2;
  .btn-efantz {
    position: absolute;
    bottom: -15px;
    left: 0;
    border-radius: 0 0 4px 4px;
  }
  .thumb {
    border-radius: 4px 4px 0 0;
    overflow: hidden;
    height: 150px;
    background: @dark;
    img {
      height: 100%;
      margin: 0 auto 20px auto;
    }
  }
  .btn-bot {
    position: absolute;
    bottom: 0;
  }

  .desc {
    margin-top: 20px;
    h4 {
      .base-font;
      font-size: 1.5em;
      font-weight: 100;
    }
  }
}


.opensSoon {
  padding: 60px;
  background: @dark;
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 1);
  @media screen and (max-width : @break-small){
    padding: 30px;
  }
  h1 {
    font-size: 1.6rem;
    font-weight: 100;
    @media screen and (max-width : @break-small){
      font-size: 16px;
    }
  }
  h1, h2, p {
    position: relative;
    z-index: 2;
  }
  h2 {
    @media screen and (max-width : @break-small){
      font-size: 20px;
    }
  }
}

.current-event-video-bg {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: .5;
}

.event {
  position: relative;
  border-bottom: 1px solid @color-user;
  overflow: hidden;
  .event-features {
    font-size: 10px;
  }
  h4 {
    span {
      .base-font;
      font-size: 14px;
    }
  }
  .team-desc {
    margin-top: 20px;
    @media screen and (max-width : @break-small){
      max-width: 150px;
      h5 {
        font-size: 14px;
      }
    }
  }
  .event-cost {
    border: 2px solid rgba(255, 0, 0, .5);
    border-radius: 10px;
    padding: 2px;
    .base-font;
    font-size: 12px;
    display: inline-block;
    margin: -10px 0 0 10px;
  }
  .event-type {
    border: 2px solid rgba(255, 255, 0, .5);
    border-radius: 10px;
    padding: 2px;
    .base-font;
    font-size: 12px;
    display: inline-block;
    margin: 5px 0 0 10px;
  }
  .event-heading {
    border-bottom: 1px solid @color-user;
    box-shadow: inset 0px -10px 40px -17px fade(@dark, 100%);
    padding: @content-padding @content-padding 0 @content-padding;
    background: @contrast-light;
    h4 {
      margin: 0;
      @media screen and (max-width : @break-small){
        font-size: 16px;
      }
    }
    p {
      margin: 0;
      padding: 0 0 @content-padding 0;
    }
    .small-timer {
      font-size: 11px;
      opacity: .7;
      margin-bottom: 10px;
    }
  }
  .event-game {
    position: absolute;
    right: 0;
    top: -20px;
    img {
      max-width: 150px;
    }
  }
  .thumb {
    img {
      width: 100%;
    }
    border-radius: 4px;
    overflow: hidden;
    padding: 0 0 @content-padding 0;
  }
  .desc {
    padding: @content-padding 0 @content-padding 0;
  }
  .quick-play {
    background: #f00;
  }

  .gold-event {
    //border-top: 1px solid fade(@contrast-dark, 10%);
    box-shadow: inset -1px 12px 22px -14px @dark;
    background: @contrast-light;
    //padding: @content-padding;
    //margin: 10px;
    //border-radius: 4px;
    padding: 20px;
    //background: linear-gradient(45deg,  fade(@dark, 100%) 0%, fade(@dark, 100%) 49%, fade(@dark, 100%) 100%);
    @media screen and (max-width : @break-small){
      text-align: center;
    }

    .payouts {
      font-size: 12px;
      margin-bottom: 2px;
      color: fade(@contrast-dark, 90%);
    }
    .result {
      border-radius: 4px;
      background: #FF0000;
      font-weight: bold;
      color: @light;
      padding: 8px;
      padding: 10px;
      display: inline-block;
    }
    .entered {
      margin-top: @content-padding;
      padding: 10px;
      text-shadow: -1px 0px 2px rgba(0, 0, 0, 0.26);
      box-shadow: inset -5px -15px 22px -14px @contrast-light;
      border-radius: 4px;
      .transition-cubic;
      background: #00FF00;
      color: @contrast-light;
    }
    .container-fluid {
      width: 100%;
      margin-top: 10px;
    }
    .subtrek {
      border: 1px solid fade(@contrast-dark, 10%);
      border-radius: 4px;
      margin-bottom: 10px;
      padding: 10px 0 0 0;
      font-size: 16px;
    }
    .sub-trek {
      min-height: 50px;
    }
    .flyout {
      display: block;
    }
    &.toggled {

    }
    h5 {
      font-size: 26px;
      font-weight: 300;
      padding: 0;
      margin: 10px 0 0 0;
      .base-font;
      @media screen and (max-width : @break-small){
        font-size: 20px;
        padding: 10px;
        text-align: center;
      }
    }
    .question {
      padding: 0;
      border-top: 1px solid fade(@contrast-dark, 50%);
      p {
        padding: @content-padding;
        margin: 0;
      }
    }
    .trek-result {
      padding: @content-padding;
      border: 1px solid lighten(@contrast-light, 10%);;
      text-align: center;
      border-radius: 4px;
      margin: @content-padding;
    }
    .btn-efantz {
      margin: 0;
    }
  }
  .match {
    background: @contrast-light;
    //background-size: 100%;
    border-bottom: 2px solid fade(@contrast-dark, 10%);
    .lockup {
      position: relative;
      z-index: 10;
      padding: 10px;
      background: url('/img/vs.png') center center no-repeat @contrast-light;
      @media screen and (max-width : @break-small){
        //background-size: cover;
      }
    }
    .toggle-match {
      border-top: 1px solid fade(@contrast-dark, 10%);
      color: @contrast-dark;
      font-size: 22px;
      text-align: center;
      i {
        .transition-cubic;
        transform: rotate(0deg);
      }
      &.match-toggled {
        i {
          transform: rotate(180deg);
        }
      }
    }
    .vs-lockup {
      height: 100%;
    }
    .vs {
      background: @color-user;
      text-align: center;
      padding: 20px 20px 15px 20px;
      border-radius: 50%;
      display: inline-block;
      margin-top: 52px;
      .deco-font;
    }
    .thumb {
      position: absolute;
      z-index: 0;
      pointer-events: none;
      top: 0;
      right: 0;
      width: 100%;
      opacity: .2;
      -webkit-mask-image: linear-gradient(to bottom,  fade(@dark, 100%) 39%,fade(@dark, 0%) 100%);
      img {
        width: 100%;
      }
    }
    .thumb-left {
      .thumb;
      display: none;
      right: auto;
      left: 0;
    }
    .team {
      margin: 0;
      vertical-align: middle;
      .team-logo {
        overflow: hidden;
        display: inline-block;
        width: 100px;
        min-width: 100px;
        padding: 10px;
        margin: 10px auto;
        //background: @dark;
        border-radius: 4px;
        //box-shadow: 0px 144px 162px -117px rgba(0,0,0,0.75);
        img {
          //margin: 0 0 0 -20px;
          width: 100%;
        }
      }
    }
  } // match
} // event

.remark-lockup {
  //height: 100px;
  img {
    height: 100px;
  }
  font-size: 14px;
  margin-bottom: 20px;
}

.remkark-team-img {
  width: 100%;
}

.player-remark-frame {
  padding: @content-padding;
  background: @dark;
  border-radius: 10px;
}

.enter-remark {
  border: 1px solid lighten(@contrast-light, 5%);
  cursor: pointer;
  background: lighten(@contrast-light, 10%);
  border-radius: 4px;
  overflow: hidden;
  .transition-cubic;
  h3 {
    font-size: 18px;
    padding:10px;
    background: @color-user;
    padding: @content-padding;
    margin: 0;
    @media screen and (max-width : @break-small){
      font-size: 14px;
      padding: 10px;
    }
  }
  margin: 0 0 30px 0;
  &:hover {
    background: @color-user;
  }
  .remark-image {
    padding: @content-padding;
    border-radius: 4px;
    overflow: hidden;
    width: 100%;
    height: 180px;
    img {
      height: 100%;
    }
    @media screen and (max-width : @break-small){
      padding: 10px;
      height: auto;
      img {
        height: 50px;
      }
    }
  }
}

.triangle {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  width: 20px;
  height: 20px;
  /* replacing with more cross-browser compatible solution
  clip-path: polygon(0 0, 0 100%, 100% 0);
  background: @color-user; */
  border-style: solid;
  border-width: 10px;
  border-color: @color-user transparent transparent @color-user;
}
.triangle-left {
  position: absolute;
  top: 0;
  left: -20;
  z-index: 99;
  width: 20px;
  height: 20px;
  /* replacing with more cross-browser compatible solution
  clip-path: polygon(100% 0, 22% 50%, 100% 100%);
  background: @color-user; */
  border-style: solid;
  border-width: 10px 16px 10px 4px;
  border-color: transparent @color-user transparent transparent;
}

/* Video
---------------------------------*/



/* Video
---------------------------------*/
.video-bg {
  position: fixed;
  z-index: -1;
  top: 0;
  opacity: .8;
  left: 0;
  width: 100vw;
  //height: 30vh;
  overflow: hidden;
  &.video-bw {
    opacity: .1;
    video {
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
      -webkit-mask-image: -webkit-gradient(linear, left 70%, left bottom, from(fade(@dark, 100%)), to(fade(@dark, 0%)));
    }
  }
}

.video-accent {
  position: fixed;
  z-index: -1;
  top: 0;
  opacity: .8;
  right: 0;
  height: 200px;
}


/* Buttons / general
---------------------------------*/
button {
	border: none;
	padding: 0.6em 1.2em;
	background: @color-user;
	color: @light;
	font-size: 1em;
	text-transform: uppercase;
	cursor: pointer;
	display: inline-block;
	margin: 3px 2px;
	border-radius: 2px;
  &:hover {
  	background: darken(@color-user, 10%);
  }
}

.alert {
  &.alert-efantz {
    border: 1px solid fade(@contrast-dark, 10%);
    color: fade(@contrast-dark, 30%);
    box-shadow: inset 0px -9px 39px -13px fade(@dark, 75%);
    background: @contrast-light;
    h6 {
      color: fade(@contrast-dark, 60%);
    }
    strong {
      font-size: 16px;
      color: @color-user;
    }
  }
  &.alert-efantz-loud {
    border: 2px solid fade(#f00, 10%);
    color: @light;
    background: #f00;
    box-shadow: inset 0px -9px 39px -13px fade(@dark, 75%);
    h6 {
      color: fade(@contrast-dark, 60%);
      color: @light;
    }
    strong {
      font-size: 16px;
      color: @color-user;
    }
  }
  &.alert-efantz-good {
    border: 2px solid fade(#0f0, 10%);
    color: @dark;
    background: #0f0;
    box-shadow: inset 0px -9px 39px -13px fade(@dark, 75%);
    h6 {
      color: fade(@contrast-dark, 60%);
      color: @dark;
    }
    strong {
      font-size: 16px;
      color: @color-user;
    }
  }
}

.img-rounded {
  overflow: hidden;
  height: 80px;
  padding: 5px 20px;
  background: @light;
  border-radius: 4px;
  border: 1px solid @color-user;
  margin-bottom: 10px;
  margin-top: -30px;
  img {
    height: 100%;
  }
}

.btn {
  border-radius: none;
  &.btn-default {
    font-size: 10px;
    padding: 0;
    margin: 0;
    color: #ccc;
  }
  &.btn-canel {
    .deco-font;
    text-shadow: -1px 0px 2px rgba(0, 0, 0, 0.26);
    border-radius: 4px;
    .transition-cubic;
    background: rgba(0, 0, 0, .8);
    &.muted {
      background: fade(@dark, 10%);
      //color: @color-user;
    }
    color: @contrast-dark;
    &:hover{
      color: @contrast-dark;
      background: darken(@color-user, 10%);
    }
  }
  &.btn-efantz {
    .deco-font;
    text-shadow: -1px 0px 2px rgba(0, 0, 0, 0.26);
    border-radius: 4px;
    .transition-cubic;
    background: @color-user;
    &.muted {
      background: fade(@dark, 10%);
      //color: @color-user;
    }
    color: @contrast-dark;
    &:hover{
      color: @contrast-dark;
      box-shadow: inset -5px -15px 22px -14px @contrast-light;
      background: darken(@color-user, 10%);
    }
  }
  &.btn-efantz-lrg {
    font-size: 1.3em;
    .deco-font;
  }
  &.btn-tiny {
    position: absolute;
    background: @color-user;
    color: @light;
    border-radius: 20px;
    top: 20px;
    right: 15px;
    font-size: 10px;
    padding: 5px 10px;
    z-index: 10;
  }
  &.btn-efantz-list {
    margin: 33px 8px 10px 10px;
    background: @color-user;
    text-shadow: -1px 0px 2px rgba(0, 0, 0, 0.26);
    color: @contrast-dark;
    padding: 4px 20px;
    .transition-cubic;
    .deco-font;
    font-size: 1em;
    &:hover{
      background: darken(@color-user, 20%);
    }
  }
}

/* Main Content
---------------------------------*/
.ef-main-content {
  position: relative;
}

#loader {
  //display: none;
}

/* Panels
---------------------------------*/
.panel {
  background: none;
  box-sizing: border-box;
}

.panel-ef {
  //margin: 0 0 @content-padding 0;
  position: relative;
  z-index: 0;
  box-sizing: border-box;
  .panel-heading {
    padding: @content-padding / 2;
    background: fade(@contrast-light, 90%);
    border-bottom: 1px solid fade(@contrast-light, 10%);
    .panel-logo {
      width: 55px;
      float: left;
      margin-top: -10px;
      margin-bottom: -10px;
      padding: 0 10px 0 0;
    }
    h3 {
      margin: 0;
      padding: 0;
      font-size: 20px;
    }
    h4 {
      font-size: 1em;
      padding: 0;
      margin: 0;
      font-weight: 100;
    }
    p {
      padding: 0;
      margin: 10px 0 0 7px;
    }
  }
  .panel-body {
    background: fade(@contrast-light, 100%);
    padding: 20px;
    border-radius: 2px;
    box-sizing: border-box;
    border: 1px solid rgba(255,255,255,.1);
    &.ef-search-bar {
      padding: 10px 10px 0 10px;
    }
    h3 {
      font-size: 1.2em;
      margin: 0;
      padding: 0 0 15px 0;
      color: fade(@contrast-dark, 90%);
      @media screen and (max-width : @break-small){
        font-size: 16px;
      }
    }
    h4 {
      font-size: 1.1em;
      margin: 0 0 0 0;
      padding: 0;
      //font-weight: 100;
      color: fade(@contrast-dark, 90%);
    }
  }
}

/* subPanels
----------------*/
.subpanel {
  .subpanel-heading {
    padding: @content-padding;
    background: fade(@contrast-light, 10%);
    h4  {
      float:left;
      font-size: 1.6em;
    }
    p {
      float: left;
      padding: 0;
      margin: 6px 0 0 7px;
    }
    position: relative;
    z-index: 1;
  }
  .subpanel-body {
    position: relative;
    padding: 40px 0 0 0;
    z-index: 1;
    overflow: hidden;
    box-shadow: inset 0px 0px 10px 0px fade(@dark, 75%);
    background: @dark;
  }
}

/* Modals
---------------------------------*/
// Bootstrap

.modal-header, .modal-body, .modal-footer {
  background: @contrast-light;
}
.modal-header {
  border-bottom: 1px solid fade(@contrast-dark, 10%);
}
.modal-footer {
  border-top: 1px solid fade(@contrast-dark, 10%);
}
.modal-header {
  padding: 0;
}

//Vue
.modal-mask {
  position: fixed;
  z-index: 995;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .9);
  display: table;
  transition: opacity .3s ease;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  max-width: 75vw;
  margin: 0px auto;
  padding: 20px;
  background-color: @contrast-light;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  font-family: Helvetica, Arial, sans-serif;
  @media screen and (max-width : @break-small){
    text-align: center;
    max-width: 100vw;
    height: 100vh;
  }
}

.modal-header h3 {
  margin-top: 0;
  color: @color-user;
}

.modal-ef-body {
  height: 40vh;
  overflow: hidden;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 10px;
  @media screen and (max-width : @break-small){
    height: 50vh;
  }
}

.modal-default-button {
  float: right;
}

/*
 * the following styles are auto-applied to elements with
 * v-transition="modal" when their visiblity is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter, .modal-leave {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}



/* Secondary Nav
---------------------------------*/
.navbar {
  background: fade(@dark, 70%);
  box-shadow: inset 0px 20px 14px -10px fade(@dark, 75%);
  border-bottom: 1px solid rgba(255,255,255,.3);
  padding: 5px 10px 1px;
  .deco-font;
  li {
    padding: 0 5px;
  }
  .active {
    border-bottom: 4px solid @color-user;
  }
}

/* Footer
---------------------------------*/
footer {
  //background: url('/img/footer_background.jpg') top right no-repeat @dark;
  background: darken(@contrast-light, 5%);
  border-top: 2px solid @contrast-light;
  padding: @content-padding;
  z-index: 100;
  min-height: 140px;
  box-sizing: border-box;
  margin: 0 0 -20px 0;
  color: fade(@light, 50%);
  width: 100%;
  h3 {
    font-size: 20px;
    border-bottom: 1px solid #666;
  }
  .nav-stacked {
    margin: 0;
    padding: 0;
    li {
      padding: 5px 0 5px 0;
      a {
        text-decoration: none;
      }
    }
  }
  a {
    color: fade(@light, 15%);
    font-size: .80em;
  }
}




.nav-pills {
  background: fade(@dark, 80%);
  //border-bottom: 4px solid @color-user;
}

.nav-pills .nav-link {
  .transition-cubic;
  border-radius: 0;
}






/* Sectional Menus / Headings
---------------------------------*/
.section-menu {
  //padding: @content-padding;
  width: 100%;
  .deco-font;
  .nav-pills {
    background: @contrast-light;
    box-shadow: inset 0px -8px 17px -6px fade(@dark, 44%);
    border-radius: 0;
    margin: 0;
    .nav-item {
      a {
        border-radius: 0;
        img {
          max-width: 55px;
        }
      }
    }
  }
  .dropdown-divider {
    height: 1px;
    background: fade(@contrast-dark, 5%);
  }
  .search {
    position: relative;
    margin-right: 20px;
    input{
      background: fade(@contrast-light, 50%);
      border-radius: 4px;
      .base-font;
      color: @contrast-dark;
      border: none;
      padding: 4px 20px 4px 4px;
    }
    .search-button{
      display: block;
      text-decoration: none;
      >span {
        color: @contrast-dark;
      }
    }
  }
  .section-heading {
    position: relative;
    //z-index: 2;
    @media screen and (max-width: @break-small) {
      display: none;
    }
    h1 {
      font-size: 2.4em;
      text-shadow: 0px -1px 3px rgba(0, 0, 0, 0.44);
      padding-bottom: 0 0 @content-padding 0;
    }
    p {
      .base-font
    }
  }
}

.text-right {
  text-align: right;
}

.nav-link {
  &.link-efantz {
    .deco-font;
    text-shadow: -1px 0px 2px rgba(0, 0, 0, 0.26);
    box-shadow: inset -5px -15px 22px -14px @contrast-light;
    border-radius:0;
    .transition-cubic;
    &.active {
      background: fade(@dark, 50%);
    }
    color: @contrast-dark;
    &:hover{
      color: @contrast-dark;
      background: darken(@color-user, 10%);
    }
  }

}


/* Navs
---------------------------------*/
.nav {
  position: relative;
  z-index: 2;
  //margin: -3px 0 0 5px;
  padding: 5px 5px;
  background: fade(@contrast-light, 75%);
  border-radius: 4px;
  .open {
    >a {
      background: @color-user;
      &:focus {
        background: @color-user;
      }
    }
  }
  li {
    a {
      .base-font;
      padding: 5px 15px;
      &:hover {
        background: @color-user;
      }
      &:active {
        background: @color-user;
      }
      &:focus {
        background: @color-user;
      }
    }
    .dropdown-menu {
      background: @contrast-light;
      a {
        display: block;
        text-decoration: none;
      }
    }
  }
  .btn {
    padding: 5px;
    letter-spacing: auto;
  }
  a {
    color: @contrast-dark;
  }
}

/* Activities
---------------------------------*/
.activity-list {
    padding: 0 0 100px 0;
    .no-activities {
      padding: 20px;
      text-align: center;
    }
}

.activity-recent {
  //margin: 0 0 @content-padding;
  overflow: hidden;
  border-bottom: 1px solid fade(@contrast-dark, 5%);
  h3 {
    font-size: 1em;
  }
  span{
    color: darken(@contrast-dark, 50%);
  }
}

.activity {
  border-top: 1px solid fade(@contrast-dark, 10%);
  border-bottom: 1px solid @contrast-light;
  padding: 2px;
  font-size: 14px;
  text-align: left;
  position: relative;
  overflow: hidden;
  .activity-action {
    position: relative;
    z-index: 10;
    box-shadow: inset 0px -9px 39px -13px fade(@dark, 75%);
    padding: @content-padding;
    color: @contrast-dark;
    display: block;
    text-align: center;
    &:hover {
      text-decoration: none;
      background: fade(@color-user, 70%);
    }
    .activity-icon {
      border-radius: 4px;
      overflow: hidden;
      border: 1px solid #ccc;
      width: 64px;
      margin: 0 auto 10px auto;
      //float: left;
    }
  }
  h5 {
    font-size: 11px;
    .base-font;
    color: fade(@contrast-dark, 50%);
  }
  p {
    margin: 0;
    padding: 0;
  }
  .activity-close {
    position: absolute;
    z-index: 12;
    top: 0;
    right: 0;
    color: @contrast-dark;
    padding: 0 6px;
    //border-radius: 0 0 0 4px;
    //background: @color-user;
    cursor: pointer;
  }
}

.activity-count {
  color: @color-user;
  background: @contrast-dark;
  font-weight: bold;
  text-align: center;
  position: absolute;
  animation: fadein 2s;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  top: 4px;
  right: 4px;
  padding: 1px 0;
  font-size: 8px;
}


/* Dropzone
---------------------------------*/
.dropzone {
  border: 2px solid @color-user;
  background: @contrast-light;
}

.dropzone .dz-preview.dz-image-preview {
  background: @contrast-light;
}

/* Profile Headings
---------------------------------*/
.profile-games {
  display: block;
  padding-bottom: @content-padding;
  img {
    width: 100%;
  }
}

.profile-image {
  border: 1px solid @contrast-light;
  margin-right: @content-padding;
  border-radius: 4px;
  display: inline-block;
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: relative;
  background: @dark;
  .btn-efantz {
    position: absolute;
    bottom: 0;
    font-weight: 100;
    font-size: 12px;
    left: 0;
    z-index: 1;
    border-radius: 0;
    padding: 0;
    margin: 0;
  }
  img {
    width: 100%;
  }
}

.nav  {
  text-shadow: 1px 1px 1px rgba(0, 0,  0, 1);
  padding: @content-padding;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      float: left;
      padding: 0 @content-padding 0 0;
    }

  }
  h1 {
    font-size: 4em;
  }
  h2 {
    .base-font;
  }
  p {
    font-size: 1.2em;
  }
}

.gamertag {
  padding: @content-padding;
  text-align: center;
  h5 {
    font-weight: 400;
    font-size: 12px;
  }
  img {
    overflow: hidden;
    width: 100%;
    border-radius: 4px;
    margin-bottom: 10px;
  }
}

#loader {
  img {
    max-width: 384px;
    width: 100%;
  }
}

/* Page Headings / Promos
---------------------------------*/
.page-img {
  height: 70px;
  float: left;
  margin: 0 10px 0 0;
}
.page-img-sm {
  height: 60px;
  float: left;
  margin: -10px 10px 0 0;
}

.page-heading {

  .col-md-12 {
    margin: 0;
    padding: 0;
  }

  .now-showing {
    position: absolute;
    bottom: 0;
    right: 10px;
    font-size: 11px;
    opacity: .5;
  }
  .page-heading-interior {
    box-sizing: border-box;
    padding: 20px 0;
    //min-height: 230px;
    h1 {
      font-size: 2.5em;
      margin: 0;
      padding: 0;
    }
    p {
      margin: 0;
      padding: 0;
    }
    &.home-interior {
      padding: @content-padding @content-padding 0 @content-padding;
    }
    @media screen and (max-width : @break-large){
      padding: 40px 0 20px 0;
    }
    @media screen and (max-width : @break-small){
      text-align: center;
      h1 {
        display: block;
        font-size: 1.2em;
        padding: 0;
      }
      p {
        margin: 0;
        padding: 0;
        font-size: .8em;
        max-width: 100%;
      }
      .page-img {
        margin: 10px auto;
        float: none;
        display: block;
      }
    }

  }

  .page-heading-interior-full {
    box-sizing: border-box;
    padding-top: 40px;
    //padding: 30px 0;
  }

  margin: 0 0 0 0;
  z-index: 0;
  position: relative;
  //height: 250px;
  overflow: hidden;
  h1 {
    font-size: 3em;
  }
  h2 {
    .base-font;
  }
  p {
    font-size: 1em;
    padding: 0;
    margin: 0;
  }
  .video-bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: auto;
    z-index: -1;
  }
}

.profile-heading {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
  padding: 200px @content-padding @content-padding @content-padding;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      float: left;
      padding: 0 @content-padding 0 0;
    }
  }
  h1 {
    font-size: 4em;
  }
  h2 {
    .base-font;
  }
  p {
    font-size: 1.2em;
  }
}

.promo-img {
  padding-top: @content-padding;
  text-align: center;
  img {
    width: 100%;
  }
}

/* Breadcrumbs
---------------------------------*/
.breadcrumbs {
  padding:5px 0 5px 0;
  margin-bottom: 1px solid fade(@contrast-dark, 10%);
  .breadcrumb {
    padding: 0;
    margin: 0;
    background: none;
    a {
      color: fade(@contrast-dark, 50%);
      font-size: .7em;
    }
  }
}


/* EFANTZ SVG Icons
---------------------------------*/
.ef-icon {
  &.ef-icon-small {
    width: 50px;
    margin: -10px 5px -10px -25px;
    //height: 100%;
  }
  &.ef-icon-content {
    width: 50px;
    margin-right: 5px;
  }
  &.ef-icon-inline {
    height: 100%;
    height: 20px;
    //height: 100%;
  }
  &.ef-icon-activity {
    height: 100%;
    height: 20px;
    position: absolute;
    top: 15px;
    left: 97px;
    //height: 100%;
  }
  &.ef-icon-bg {
    height: 300px;
    position: absolute;
    bottom: -100px;
    right: -100px;
    z-index: 0;
    //height: 100%;
  }
  &.ef-icon-big {
    height: 40px;
    //height: 100%;
  }
  &.ef-icon-huge {
    width: 100%;
    //height: 100%;
  }
}

/* Home v2
---------------------------------*/
.ef-show {
  .ef-slide {
    //-webkit-clip-path: polygon(0 0, 100% 0, 100% 55%, 0% 100%);
    //clip-path: polygon(0 0, 100% 0, 100% 55%, 0% 100%);
    background: @contrast-light;
    position: relative;
    box-shadow: inset 0px 0px 42px -6px fade(@dark, 75%);
    padding: 20px 20px 40px 20px;
    &.no-base {
      padding: 20px 20px 0 20px;
    }
    margin: 15px 0;
    //border-bottom: 1px solid rgba(255,255,255,.3);
    //background: @contrast-light;
    //height: 200px;
    border-radius: 4px;
    overflow: hidden;
    .see_all {
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 10px 20px 10px 40px;
      color: @light;
      font-size: 12px;
      //background: @dark;
      -webkit-clip-path: polygon(39% 0, 100% 0, 100% 100%, 0% 100%);
      clip-path: polygon(39% 0, 100% 0, 100% 100%, 0% 100%);
      text-decoration: none;
      display: block;
      box-shadow: inset 0px 0px 42px -6px fade(@dark, 75%);
      .transition-cubic;
      &:hover {
        background: @color-user;
      }
    }
    .ef-slide-desc {
      box-sizing: border-box;
      .ef-slide-section {
        position: absolute;
        top: 10px;
        right: 10px;
        opacity: .2;
        width: 120px;
        -webkit-filter: grayscale(100%); /* Chrome, Safari, Opera */
        filter: grayscale(100%);
        img {
          width: 100%;
        }
      }
      h1 {
        font-size: 3rem;
        text-transform: uppercase;
        padding: 0;
        margin: 0;
        line-height: 3rem;
      }
      h2 {
        font-weight: 400;
        font-size: 22px;
        //background:lighten(@dark, 12%);
        //border-bottom: 1px solid @dark;
        //padding: 15px 10px 10px 10px;
        padding: 0;
        display: block;
        //margin: -25px -20px 20px -20px;
        span {
          font-size: 14px;
          margin-left: 10px;
          font-weight: 100;
          display: inline-block;
          .base-font;
        }
      }
      h3 {
        text-transform: uppercase;
      }
      p {
        padding: 0;
        margin: 0 0 20px 0;
        max-width: 700px;
        font-size: 14px;
        color: @light;
      }
      .ef-slide-item {
        box-sizing: border-box;
        background: rgba(0, 0, 0, .7);
        border-radius: 4px;
        //border: 1px solid rgba(255,255,255,.2);
        position: relative;
        overflow: hidden;
        color: @light;
        margin: 0 0 20px 0;
        .transition-cubic;
        h4 {
          font-weight: 400;
          font-size: 16px;
          padding: 0;
          margin: 0;
          span {
            font-size: 12px;
            margin-left: 10px;
          }
        }
        .ef-slide-item-img {
          height: 190px;
          overflow: hidden;
          img {
            width: 100%;
          }
        }
        .top-right {
          position: absolute;
          top: 10px;
          right: 10px;
          z-index: 2;
          font-size: 12px;
          padding: 5px 10px;
          border-radius: 4px;
          background: fade(@dark, 80%);
        }
        .top-left {
          position: absolute;
          top: 10px;
          left: 10px;
          z-index: 2;
          img {
            width: 50px;
          }
        }
        .bottom-left {
          position: absolute;
          bottom: 40px;
          left: 0;
          z-index: 2;
          img {
            width: 75px;
          }
        }
        .item-bottom {
          position: absolute;
          padding: 10px;
          bottom: 0px;
          left: 0px;
          width: 100%;
          h4 {
            .base-font;
          }
          background: rgb(48,46,46);
          background: -moz-linear-gradient(top,  fade(@dark, 40%) 0%, fade(@dark, 100%) 100%);
          background: -webkit-linear-gradient(top,  fade(@dark, 40%) 0%,fade(@dark, 100%) 100%);
          background: linear-gradient(to bottom,  fade(@dark, 40%) 0%,fade(@dark, 100%) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#302e2e', endColorstr='@dark',GradientType=0 );
        }
        &:hover {
          .item-bottom {
            background: rgb(0,0,0);
            background: -moz-linear-gradient(top,  fade(@dark, 100%) 0%, fade(@dark, 40%) 100%);
            background: -webkit-linear-gradient(top,  fade(@dark, 100%) 0%,fade(@dark, 40%) 100%);
            background: linear-gradient(to bottom,  fade(@dark, 100%) 0%,fade(@dark, 40%) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='@dark', endColorstr='#302e2e',GradientType=0 );
          }
        }
      }
    }
  }
}


/* Live Page
---------------------------------*/

.ef-page-live {
  .row {
    padding: 0;
    margin: 0;
    [class*="col-"] {
        padding: 0;
    }
    iframe#twitch-chat {
      width: 100%;
      height: 100vh;
    }
  }
}


/* EFANTZ SVG Icons
---------------------------------*/
.news-item {
  border: 1px solid fade(@contrast-dark, 5%);
  border-radius: 4px 4px 0 0;
  background: @contrast-light;
  .posted-on {
    font-size: 14px;
    font-weight: normal;
    .base-font;
  }
  .news-image {
    height: 230px;
    overflow: hidden;
    img {
      width: 100%;
      border-radius: 4px 4px 0 0;
    }
  }

  .news-item-content {
    padding: @content-padding;
  }
}


/* Mini-Profile
---------------------------------*/
.mini-profile {
  padding: 5px 0 0 0;
    h5 {
      font-size: 14px;
      font-weight: bold;
      font-family: 'Lato', Calibri, Arial, sans-serif;
      border-bottom: 1px solid fade(@contrast-dark, 30%);
    }
    p {
      font-size: 12px;
    }
    .icon-users {
      height: 32px;
      margin: 5px 5px 0 0;
    }
}


/* Data Tables
---------------------------------*/
table.dataTable thead .sorting_desc:after {
  display: none;
  content: '';
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: fade(@contrast-dark, 5%);
}

.table th, .table td {
  border-top: 1px solid fade(@contrast-dark, 5%);
}

.ef-data-table {
  padding: 0;
  border-radius: 4px;
  background: @contrast-light;
  border: none;

	.table{
	  padding: 0;
	  margin: 0;
    background: linear-gradient(to right, #1A1A1A , black 100%);
	  tr {
      &:hover {
        background: @contrast-light;
      }
      color: @contrast-dark;
		  th {
    		border: none;
    		background: @contrast-light;
		  }
		  td {
  			background: none;
  			border-bottom: 1px solid @contrast-light;
  			color: @contrast-dark;
		  }
	  }
	}
}
.dataTables_wrapper {
  .dataTables_paginate {
    .paginate_button {
      padding: 0;
      margin: 0;
    }
    a {
      background: @contrast-light;
      color: @color-user;
      border: none;
    }
    li.active a {
      color: @contrast-dark;
      //background: @color-user;
    }
  }
  select, input {
    background: @contrast-light;
    border: 1px solid @color-user;
    border-radius: 4px;
  }
}
div.dataTables_processing {
  background: url('/img/ajax-loader.gif') top center no-repeat fade(@contrast-light, 90%) !important;
}


/* Responsive
---------------------------------*/
@media only screen and (max-width: 991px) {

}


/* Players
---------------------*/
.player {
  height: 100px;
  position: relative;
  overflow: hidden;
  clear: both;
  margin-bottom: 1px;
  background-color: @dark;
  .thumb {
    img {
      width: 100%;
      margin: 15px;
      border: 2px solid @contrast-dark;
    }
  }
  .team-logo {
    img {
      margin: 15px;
      padding: 5px;
      width: 50%;
    }
  }
  .add-btn {
    width: -200px;
    height: 100px;
  	background: @color-user;
    margin-left: 30px;
    h4 {
      display: inline-block;
      margin: 44px 0 0 14px ;
      font-size: 20px;
      position: relative;
      z-index: 1;
    }
    &:before {
      width: 200px;
      height: 100px;
      display: block;
      position: absolute;
      top: 0px;
      left: 30px;
      background-color: @color-user;
      content: '';
      -webkit-clip-path: polygon(20% 0%, 100% 0, 100% 100%, 0 100%);
      clip-path: polygon(20% 0%, 100% 0, 100% 100%, 0 100%);
      margin: 0 0 0 -30px;
    }
  }
  .desc {
    padding-top: 15px;
    h4 {
      //display: inline-block;
      padding: 0;
      margin: 0;
      float: left;
    }
    .position {
      margin: 0 0 0 10px;
      //display: inline-block;
      float: left;
      font-size: .5em;
      padding: 3px 10px;
      &.adc {
        border: 1px solid #F00;
      }
    }

  }
}

dl.stats {
  float: left;
  margin: 0 @content-padding @content-padding 0;
  padding: 0;
  dt {
    margin: 0;
    padding: 0;
    font-weight: normal;
    text-align: center;
    .wins {
      color: #0f0;
    }
    .assist {
      color: #ff0;
    }
    .loss {
      color: #f00;
    }
    &:first-child {
      font-weight: bold;
    }
  }
}
.position-container {
  background: @dark;
  padding: @content-padding;

  .position {
    height: 100px;
    padding: @content-padding;
    margin: 5px;
    display: table;
    text-align: center;
    width: 100%;
    &.top {
      border: dotted 1px #c0f;
    }
    &.jungle {
      border: dotted 1px #3c3;
    }
    &.mid {
    border: dotted 1px #00f;
    }
    &.adc {
    border: dotted 1px #f00;
    }
    &.support {
      border: dotted 1px #fc3;
    }
    h3 {
      text-align: center;
      display: table-cell;
      vertical-align: middle;
      &.top {
        color: #c0f;
      }
      &.jungle {
        color: #3c3;
      }
      &.mid {
        color: #00f;
      }
      &.adc {
        color: #f00;
      }
      &.support {
        color: #fc3;
      }
    }
  }
}
.toast {
  background: @dark;
  position: relative;
  padding: 20px;
  .row {
    margin-left: 20px;
    border: solid 1px @color-user;
    col-md-1 {
      padding: 0;
  }
  .thumb {
    img {
      padding: 0;
      margin: 15px 15px 15px 0;
      max-width: 100px;
      max-height: 100px;
    }
  }
}
  .desc {
    padding-top: 40px;
    margin-left: 40px;
 }
 .triangle-left {
   float: inherit;
   margin-top: 70px;

 }
}

.notification-bubble {
  height: 25px;
  width: 25px;
  position: absolute;
  z-index: 1;

  text-align: center;
  font-weight: bold;
  border-radius: 50%;
  background-color: @color-user;
  p {
    text-align: center;
    padding-top: 3px;
  }
}
.user-games {
  h3 {
    padding: 5px;
    }
  .list-group {
    .list-group-item {
      background-color: @dark;
      color: @color-user;
      text-align: center;
      font-size: 1.2em;
      &:hover {
        background-color: #666;
        color: @light;
      }
    }
  }


  .game-id {
    border-radius: 4px;
    padding: 0 20% 0 0;
    .input-group-lg {
      width: 100%;
      .form-control {
        text-align: center;
        height: 180px;
        border-radius: 5px;
        font-size: 2.25em;
      }
    }
    .attn {
      color: #f00;
      font-weight: bold;
    }
    .qwer {
      text-align: center;
      .save-btn {
        text-align: center;
        width: 40%;
        margin: 0 auto;
        border-radius: 5px;
        background-color: @color-user;
        :hover {
          color: #0f0;
        }
        h3 {
          padding: 5px;
        }
      }
    }
  }

}

/* Fantasy
---------------------------------*/
#fantasy {
  .roster-position {
    &.selected {
      border-color: @color-user;
    }
  }
  .players-table {
    @image-size: 64px;
    @padding: 5px;
    tr {
      min-height: @image-size + @padding * 2;
    }
    td {
      padding: @padding;
      vertical-align: middle;
    }
    td:first-child {
      padding-left: 0;
    }
    td:last-child {
      padding-right: 0;
    }
    td.image {
      width: @image-size + @padding * 2;
      img {
        width: @image-size;
      }
    }
    td.info {
      padding-left: 20px;
    }
    td.buttons {
      width: 64px;
      vertical-align: middle;
    }
  }
}

/* User profile live stream wrapper
---------------------*/
.live-stream-wrapper {
  width: 100%;
  padding-top: 60%;
  position: relative;
}
.live-stream-inner-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}



















.check-icon {
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
}

.selected {
  position: relative;
  &.enter-remark {
    background: @color-user;
  }
  &.game {
    background: @color-user;
  }
  &.ef-big-select {
    background: @color-user;
  }
  .check-icon {
    display: block;
  }
}

/* css for v-cloak directive to prevent vue.js FOUC */
[v-cloak] {
  display: none;
}

/* Pagination for backend admin panel grids.
-------------------*/

ul.pagination {
  list-style: none;
  padding: 0;
  display: inline-block;
  .disabled {

  }
  .active {

  }
  li {
    float: left;
    margin: 0;
    padding: 0;
    margin: 0 5px;
    a {
      padding: 2px 10px;
      background: @color-user;
      color: @contrast-dark;
      border-radius: 4px;
    }
  }
}



@import "shared/bootstrap-components.less";

/* Brackets
-------------------*/
.hover {
  background: @color-user;
}
.ef-fullscreen-controls {
  display: none;
  cursor: pointer;
}
.ef-brackets-tab {
  &.fullscreen {
    position: fixed;
    top: 0;
    left: @sidebar-outer-width;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    .card {
      width: 100vw;
      height: 100vh;
    }
  }
}

.card {
  background: darken(@contrast-light, 1%);
  border-radius: 4px;
  box-shadow: inset 0px -9px 23px 2px fade(@dark, 20%);
}
h1.brackets-title {
  font-size: 2em;
  //font-size: 5vw; // 4em will be used as fallback in case browser fails to recognize vw unit
  position: absolute;
  bottom: 20px;
  left: 40px;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.53);
}
.brackets-bg-image {
  overflow: hidden;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  opacity: .2;
  img {
    width: 100%;
    border-radius: 4px;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    -webkit-mask-image: -webkit-gradient(linear, left 70%, left bottom, from(fade(@dark, 100%)), to(fade(@dark, 0%)));
  }
}
.brackets-image {
  overflow: hidden;
  max-height: 500px;
  width: 100%;
  border-radius: 4px;
  img {
    width: 100%;
  }
  box-shadow: 0px 2px 13px 0px fade(@dark, 75%);
}
.brackets-participants {
  .participants-current {
    font-size: 14px;
    .deco-font;
    font-weight: 100;
    display: inline;
  }
  .participants-max {
    font-size: 16px;
    .deco-font;
    font-weight: 100;
    display: inline;
  }
  .participants-slash {
    font-size: 1em;
    color: @color-user;
  }
  @media screen and (max-width: @break-small) {
    margin: 0 0 10px 0;
  }
}
@import "brackets.less";
@import "media.less";

.tour-header {
  h1 {
    font-size: 10rem;
    text-shadow: 0px 0px 2px fade(@contrast-dark, 7%);
  }
}
